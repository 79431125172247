import React, { useState, useContext, useEffect } from "react";
import "../../assets/style/contracttabmanager.css";
import { Tabs, Divider, Layout } from "antd";
import { ContractTabContext } from "../Contract/ContractTabContext";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ContractRouter from "./ContractRouter";
import TopHeader from "../Header";
import { HeaderChangerAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import { getNodeIcon } from "../../utils/common";

const TabManager = () => {
  const {
    tabs,
    setTabs,
    activeKey,
    setActiveKey,
    defaultBreadcrumbs,
    handleAddTab,
    handleRemoveTab,
  } = useContext(ContractTabContext);
  const [headerChanger] = useRecoilState(HeaderChangerAtom);
  const [isRearranging, setIsRearranging] = useState(false);
  let header = headerChanger.header;

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  );

  useEffect(() => {
    const adjustLastTabMargin = () => {
      const navOperations = document.querySelector(".ant-tabs-nav-operations");
      const tabsContainer = document.querySelector(".ant-tabs-nav-list");
      const allTabs = tabsContainer.querySelectorAll(".ant-tabs-tab");

      allTabs.forEach((tab) => {
        tab.style.setProperty("margin-right", "0", "important");
      });

      if (allTabs.length === 0) return;

      const lastTab = allTabs[allTabs.length - 1];
      const tabsContainerWidth = tabsContainer.offsetWidth;
      const totalTabsWidth = Array.from(allTabs).reduce((acc, tab) => {
        return acc + tab.offsetWidth;
      }, 0);
      const isNavOperationsHidden = navOperations.classList.contains(
        "ant-tabs-nav-operations-hidden"
      );

      if (isNavOperationsHidden) {
        lastTab.style.setProperty("margin-right", "0", "important");
      } else if (tabsContainerWidth > totalTabsWidth) {
        lastTab.style.setProperty("margin-right", "40px", "important");
      } else {
        lastTab.style.setProperty("margin-right", "0", "important");
      }
    };

    const timeoutId = setTimeout(adjustLastTabMargin, 100);

    window.addEventListener("resize", adjustLastTabMargin);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", adjustLastTabMargin);
    };
  }, [tabs]);

  const onChangeTab = (clickedTab) => {
    setActiveKey(clickedTab);
  };

  const onEditTab = (targetKey, action) => {
    if (action === "add") {
      handleAddTab({
        id: `newTab_${Date.now()}`,
        title: "Contracts",
        breadcrumbs: defaultBreadcrumbs,
        children: <ContractRouter breadcrumbs={defaultBreadcrumbs} />,
        type: "Supplier",
      });
    } else if (action === "remove") {
      handleRemoveTab(targetKey);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setIsRearranging(false);

    // Prevent reordering if the over target is the first tab
    if (over && active.id !== over.id) {
      const newIndex = tabs.findIndex((tab) => tab.id === over.id);
      if (newIndex === 0) {
        // Don't allow moving any tab into the first position
        return;
      }

      const oldIndex = tabs.findIndex((tab) => tab.id === active.id);
      const reorderedTabs = arrayMove(tabs, oldIndex, newIndex);

      setTabs(reorderedTabs); // Update tabs state with new order

      // Set the active tab to the tab that was dragged
      setActiveKey(active.id);
    }
  };

  const handleDragStart = () => {
    setIsRearranging(true);
  };

  const tabItems = [
    ...tabs.reduce((acc, tab, index) => {
      acc.push({
        key: tab.id,
        label: (
          <SortableTab
            tab={tab}
            activeKey={activeKey}
            isRearranging={isRearranging}
            isFirstTab={index === 0}
          />
        ),
        children: tab.children || (
          <ContractRouter breadcrumbs={tab.breadcrumbs} />
        ),
        type: tab.type,
        closable: isRearranging ? false : tab.id !== "contract-tree",
      });
      // Add a divider after each tab
      acc.push({
        key: "divider" + tab.id,
        label: <Divider className="contract-tab-divider" type="vertical" />,
        disabled: true,
      });
      return acc;
    }, []),
  ];

  useEffect(() => {
    document.title =
      `${
        tabs.find((tab) => tab.id === activeKey)?.title
      } | Contract Tree | Judo` || `Contracts | Contract Tree | Judo`;
  }, []);

  return (
    <Layout className="contract-tab-layout">
      <TopHeader
        headerChange={header}
        menuKey={"5"}
        logo={true}
        feedRow={false}
        searchRow={false}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setIsRearranging(false)}
      >
        <SortableContext
          items={tabs.map((tab) => tab.id)}
          strategy={horizontalListSortingStrategy}
        >
          <Tabs
            id={
              !isRearranging
                ? "contract-refactor-tab"
                : "contract-tab-rearrange"
            }
            className={`fade-in-left`}
            activeKey={activeKey}
            onChange={onChangeTab}
            type="editable-card"
            onEdit={onEditTab}
            items={tabItems}
          />
        </SortableContext>
      </DndContext>
    </Layout>
  );
};

const SortableTab = ({ tab, activeKey, isRearranging, isFirstTab }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: tab.id,
      disabled: isFirstTab, // Disable sorting for the first tab
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isRearranging ? "grabbing" : "pointer",
    userSelect: "none",
    width: isRearranging ? 133 : 120,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 13,
    textShadow: "0 0 0.8px currentColor",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <span className="sortable-contract-tab">
        {getNodeIcon(tab.type, activeKey === tab.id ? "active" : "inactive")}
        <span
          title={isRearranging || tab.title === "Contracts" ? "" : tab.title}
          className="sortable-contract-tab-title"
        >
          {tab.title}
        </span>
      </span>
    </div>
  );
};

export default TabManager;
