import React, { useState, useEffect } from "react";
import {
  Image,
  Card,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Layout,
  Table,
  Popconfirm,
  Tabs,
  DatePicker,
  Divider,
  message,
  Upload,
  Modal,
  Empty,
} from "antd"; //antd components
import dayjs from "dayjs";
import {
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import TopHeader from "../../Header";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import add from "../../../assets/images/icons/Add_round.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import { suppliersOnlyApi } from "../../../api/suppliersApi";
import { listLegalEntity } from "../../../api/legalEntityApi";
import {
  createInvoicingProfileAPi,
  editInvoicingProfileAPi,
} from "../../../api/invoicingProfileApi";
import { createActivityLogApi } from "../../../api/logApi";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import invoicingProfileTemplate from "../../../template/Invoicing_Profile_Template.xlsx";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  ExcelButton,
  TableButton,
} from "../../GlobalButton";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  HeaderChangerAtom,
  RoleAccessAtom,
  userDataAtom,
  SupplierTreeDataAtom,
} from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ModalMessage from "../../../ModalMessage";
import NotAuthorized from "../../NotAuthorized";
import { listCostCenter } from "../../../api/costCenterApi";
import HeaderCard from "../../HeaderCard";
import ContractFile from "../../Contract/ContractFile";
import { getContractByIP } from "../../../api/contractsApi";
import { dataTable, setupDataTableForm } from "../../../utils/common";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

function InvoicingProfileController() {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to use navigate function
  const history = useNavigate();
  // Variable to hold manu key value
  const menuKey = "5";
  // Variable to hold particular invoice profile data
  let { state } = useLocation();
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  console.log("state", state);
  const [invoicingProfileFieldValue, setInvoicingProfileFieldValue] = useState({
    invoice_profile_name: state.data && state.data.invoice_profile_name,
    version_no: null,
    version_name: null,
    version_comments: null,
    effective_date:
      state.data && state.data.status === "In Draft"
        ? state.data.version_details &&
          state.data.version_details[0].effective_date
          ? state.data.version_details[0].effective_date
          : null
        : null,
    supplier_id: state.data && state.data.supplier_id,
  });
  // Variable to hold button value 1 & 2
  const stateBtn = {
    button: 1,
  };
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [selectedContract, setSelectedContract] = useState();
  const [versionComments, setVersionComments] = useState("");

  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // Variable to hold supplier tree data
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);
  // variable to hold button disable CSS
  let disableVal = "disable-btn";
  // State variable to hold button visibility status
  const [disable, setDisable] = useState(disableVal);
  // State variable to hold supplier data
  const [supplierData, setSupplierData] = useState([]);
  // State variable to hold from entity data
  const [fromEntityData, setFromEntityData] = useState([]);
  const [associationData, setAssociationData] = useState([]);
  // State variable to hold to entity data
  const [leData, setLeData] = useState([]);
  //state variable hold the cost center api data
  const [ccData, setCcData] = useState([]);
  const [ipId, setIpId] = useState("");
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // State variable to hold table loading visibility status
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  // State variable to hold invoiceing profile file
  const [profileFile, setProfileFile] = useState([]);
  // State variable to hold way of add data
  const [profileField, setProfileField] = useState("upload");
  // State variable to hold upload validation required true or false
  const [uploadRule, setUploadRule] = useState(false);
  // State variable to hold total allocation value of all rows
  const [totalAllocation, settotalAllocation] = useState(
    state.data &&
      state.data.status == "In Draft" &&
      state.data.version_details &&
      state.data.version_details[0] &&
      state.data.version_details[0].profiles
      ? state.data.version_details[0].profiles.reduce((total, curr) => {
          return total + Number(curr.allocation_p);
        }, 0)
      : state.data &&
        state.data.version_details &&
        state.data.version_details.filter(
          (data) => data.version_status === "Active"
        ).length > 0
      ? state.data.version_details
          .filter((data) => data.version_status === "Active")[0]
          .profiles.reduce((total, curr) => {
            return total + Number(curr.allocation_p);
          }, 0)
      : 0
  );
  const [uploadRuleText, setUploadRuleText] = useState();
  // State variable to store the clone the profile name for editing or deleting purpose
  const [dataSourceClone, setDataSourceClone] = useState(
    state.data &&
      state.data.status == "In Draft" &&
      state.data.version_details &&
      state.data.version_details[0] &&
      state.data.version_details[0].profiles
      ? state.data.version_details[0].profiles.map((data, index) => ({
          id: index,
          from_entity: data.from_entity,
          to_entity: data.to_entity,
          cost_center: data.cost_center,
          allocation_p: data.allocation_p,
        }))
      : state.data &&
        state.data.version_details &&
        state.data.version_details.filter(
          (data) => data.version_status === "Active"
        ).length > 0
      ? state.data.version_details
          .filter((data) => data.version_status === "Active")[0]
          .profiles.map((data, index) => ({
            id: index,
            from_entity: data.from_entity,
            to_entity: data.to_entity,
            cost_center: data.cost_center,
            allocation_p: data.allocation_p,
          }))
      : [
          {
            id: 0,
            from_entity: "",
            to_entity: "",
            le_id: "",
            cost_center: "",
            allocation_p: "",
          },
        ]
  );
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [ipFile, setIpFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  // State variable to hold row count
  const [count, setCount] = useState(
    state.data &&
      state.data.status == "In Draft" &&
      state.data.version_details &&
      state.data.version_details[0] &&
      state.data.version_details[0].profiles
      ? state.data.version_details[0].profiles.length
      : state.data &&
        state.data.version_details &&
        state.data.version_details.filter(
          (data) => data.version_status === "Active"
        ).length > 0
      ? state.data.version_details.filter(
          (data) => data.version_status === "Active"
        )[0].profiles.length
      : 1
  );

  // Function to handle to upload the excel file and validate
  const props = {
    name: "file",
    listType: "picture",
    accept: ["xlsx", "xls"],
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          setProfileFile(info.fileList);
        } else {
          setProfileFile([]);
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setProfileFile(info.fileList);
        } else {
          setProfileFile([]);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = ["xls", "xlsx"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload the excel files.");
        popupModalInstance.errorModal(ModalMessage.Invoice_Excel_Upload_Error);
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  /* Override for the default xhr behavior allowing for additional customization
   * @Param onSuccess -  a  callback function that will be executed when the request succeeds.
   */
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // Function to handle to get all supplier data to set useState
  useEffect(() => {
    suppliersOnlyApi().then((res) => {
      console.log("ress supplier", res);
      setSupplierData(res);
      if (state.data) {
        let filteredFromEntity = res.filter(
          (data) => data.id === state.data.supplier_id
        );
        console.log("filtered en", filteredFromEntity);
        setFromEntityData(filteredFromEntity);
      }
    });
    listLegalEntity().then((res) => {
      setLeData(res);
    });
    listCostCenter().then((res) => {
      console.log("cc data", res);
      setCcData(res);
    });
  }, []);

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    if (state.flag === "edit" || state.flag === "view") {
      getContractByIP(state.data && state.data._id).then((res) => {
        console.log("contract res", res);
        let association = res.map((data) => {
          let tableRow = {
            associated_item: "Contract",
            name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                  ) {
                    setHeaderChanger({
                      header: "contract",
                      headerItem: "2",
                      headerItemName: "contract",
                      stateSetter: data,
                    });
                    getTopPath(data);
                    // navigate(`/contract-setup/view/invoicing-profile`, {
                    //   state: data,
                    // });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.contract_name}
              </span>
            ),
            association_date: moment(data.createdAt).format("DD-MMM-YY"),
          };
          return tableRow;
        });
        setAssociationData(association);
      });
    }
  }, [state]);

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    const currentDate = moment().startOf("day");
    return dateValue.isBefore(currentDate, "day");
  };

  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString) => {
    setInvoicingProfileFieldValue({
      ...invoicingProfileFieldValue,
      effective_date: dateString,
    });
  };
  /* Function to handle Invoice profile name
   * @Param e - event from input
   */
  const handleInvoiceProfileName = (e) => {
    setInvoicingProfileFieldValue({
      ...invoicingProfileFieldValue,
      invoice_profile_name: e.target.value,
    });
    e.target.value !== "" &&
    state.data &&
    state.data.invoice_profile_name !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };
  /* Function to handle change the supplier name
   * @Param key - a change the supplier name key
   * @Param value - a change the supplier name value
   */
  const onChangeSupplier = (key, value) => {
    console.log("keyyyyyyyyyy sup", key);
    setInvoicingProfileFieldValue({
      ...invoicingProfileFieldValue,
      supplier_id: key,
    });
    let filteredFromEntity = supplierData.filter((data) => data.id === key);
    setFromEntityData(filteredFromEntity);
    key !== undefined && state.data && state.data.supplier_id !== key
      ? setDisable("")
      : setDisable(disableVal);
  };
  /* Function to handle change the from entity value
   * @Param value - a select option value
   * @Param key - a particular row id
   */
  const onChangeFromEntity = (value, id) => {
    const filteredProfile =
      state.data &&
      state.data.version_details &&
      state.data.version_details.filter(
        (data) => data.version_status === "Active"
      );
    if (filteredProfile && filteredProfile.length > 0) {
      filteredProfile[0] &&
      filteredProfile[0].profiles[id] &&
      filteredProfile[0].profiles[id].to_entity !== value
        ? setDisable("")
        : setDisable(disableVal);
    } else {
      setDisable("");
    }
    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.id === id) {
        return { ...obj, from_entity: value };
      }
      return obj;
    });
    setDataSourceClone(updatedArray);
  };
  useEffect(() => {
    console.log("dataSourceClone", dataSourceClone);
  }, [dataSourceClone]);
  /* Function to handle change the to entity value
   * @Param value - a select option value
   * @Param id - a particular row id
   */
  const onChangeToEntity = (id, value) => {
    console.log("id ", id, "value", value);
    const filteredProfile =
      state.data &&
      state.data.version_details &&
      state.data.version_details.filter(
        (data) => data.version_status === "Active"
      );
    if (filteredProfile && filteredProfile.length > 0) {
      filteredProfile[0] &&
      filteredProfile[0].profiles[id] &&
      filteredProfile[0].profiles[id].to_entity !== value
        ? setDisable("")
        : setDisable(disableVal);
    } else {
      setDisable("");
    }
    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.id === id) {
        return { ...obj, to_entity: value };
      }
      return obj;
    });
    setDataSourceClone(updatedArray);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const onChangeTocostcenter = (key, value) => {
    console.log("key", key, "value", value);
    const filteredProfile =
      state.data &&
      state.data.version_details &&
      state.data.version_details.filter(
        (data) => data.version_status === "Active"
      );
    if (filteredProfile && filteredProfile.length > 0) {
      filteredProfile[0] &&
      filteredProfile[0].profiles[key] &&
      filteredProfile[0].profiles[key].to_entity !== value
        ? setDisable("")
        : setDisable(disableVal);
    } else {
      setDisable("");
    }
    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.id === key) {
        return { ...obj, cost_center: value };
      }
      return obj;
    });
    console.log("updatedArray", updatedArray);
    setDataSourceClone(updatedArray);
  };
  /* Function to handle change the allocation value
   * @Param e - a event object which contains target value
   * @Param record_id - a particular row id
   */
  const onCreateInvoicing = (e, record_id) => {
    const filteredProfile =
      state.data &&
      state.data.version_details &&
      state.data.version_details.filter(
        (data) => data.version_status === "Active"
      );
    if (filteredProfile && filteredProfile.length > 0) {
      filteredProfile[0] &&
      filteredProfile[0].profiles[record_id] &&
      filteredProfile[0].profiles[record_id].to_entity !== e.target.value
        ? setDisable("")
        : setDisable(disableVal);
    } else {
      setDisable("");
    }
    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.id === record_id) {
        return { ...obj, allocation_p: Number(e.target.value) };
      }
      return obj;
    });
    const totalAllocationP = updatedArray.reduce(
      (total, entry) => total + Number(entry.allocation_p),
      0
    );
    settotalAllocation(totalAllocationP);
    setDataSourceClone(updatedArray);
  };

  // Function to handle button disable for row add or delete
  useEffect(() => {
    const filteredProfile =
      state.data &&
      state.data.version_details &&
      state.data.version_details.filter(
        (data) => data.version_status === "Active"
      );
    if (filteredProfile && filteredProfile.length > 0) {
      filteredProfile[0] &&
      filteredProfile[0].profiles &&
      filteredProfile[0].profiles.length !== count
        ? setDisable("")
        : setDisable(disableVal);
    } else {
      setDisable("");
    }
  }, [count]);
  /* Function to handle delete contratualfx table row
   * @Param id -  particular row id
   */
  const handleDelete = (id) => {
    const newData = dataSourceClone.filter((item) => item.id !== id);
    const totalAllocationP = newData.reduce(
      (total, entry) => total + Number(entry.allocation_p),
      0
    );

    settotalAllocation(totalAllocationP);
    setDataSourceClone(newData);
    setCount(count + 1);
  };

  //Variable that holds the description of the columns in the Invoicing Profile Table
  const defaultColumns = [
    {
      title: "From Entity",
      dataIndex: "from_entity",
      align: "left",
      show: true,
      render: (index, record) => (
        <Form.Item
          initialValue={
            dataSourceClone.find((obj) => obj.id === record.id).from_entity
          }
          name={[record.id, "from_entity"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={state.flag === "view"}
            allowClear
            onChange={(value) => onChangeFromEntity(value, record.id)}
            showSearch={true}
            placeholder="Choose From Entity"
            options={
              fromEntityData &&
              fromEntityData.length !== 0 &&
              fromEntityData[0].supplier_entities.map((data) => ({
                label: data.supplier_entity,
                value: data.supplier_entity,
              }))
            }
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "To Entity",
      dataIndex: "to_entity",
      align: "left",
      show: true,
      render: (index, record) => (
        <Form.Item
          name={[record.id, "to_entity"]}
          initialValue={
            dataSourceClone.find((obj) => obj.id === record.id).to_entity
          }
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={state.flag === "view"}
            allowClear
            onChange={(value) => onChangeToEntity(record.id, value)}
            showSearch={true}
            placeholder="Choose To Entity"
            options={
              leData &&
              leData.map((data) => ({
                key: data.id ? data.id : data._id,
                label: data.le_name,
                value: data.le_name,
              }))
            }
            style={{ width: "100%" }}
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      align: "left",
      show: true,
      render: (index, record) => (
        <Form.Item
          initialValue={
            dataSourceClone.find((obj) => obj.id === record.id).cost_center
          }
          name={[record.id, "cost_center"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={state.flag === "view"}
            allowClear
            onChange={(value) => onChangeTocostcenter(record.id, value)}
            showSearch={true}
            placeholder="Choose To Entity"
            options={
              ccData &&
              ccData.map((data) => ({
                key: data.id ? data.id : data._id,
                label: data.cc_name,
                value: data.cc_name,
              }))
            }
            style={{ width: "100%" }}
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "Allocation",
      dataIndex: "allocation_p",
      align: "left",
      show: true,
      render: (index, record) => (
        <Form.Item
          initialValue={
            dataSourceClone.find((obj) => obj.id === record.id).allocation_p
          }
          name={[record.id, "allocation_p"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            readOnly={state.flag === "view"}
            style={{ padding: "8px 11px" }}
            placeholder="Enter Allocation in %"
            onChange={(e) => onCreateInvoicing(e, record.id)}
          />
        </Form.Item>
      ),
    },
    {
      title: <Image src={add} className="hide-add" preview={false} />,
      dataIndex: "action",
      align: "middle",
      fixed: "right",
      show: state.flag !== "view",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          <Popconfirm
            disabled={dataSourceClone.length === 1}
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.id)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
          <a>
            <Image
              title="Clone"
              src={duplicate}
              style={{ width: "20px" }}
              preview={false}
              className="cursor-pointer"
              onClick={() => {
                handleAddClone(record.id);
              }}
            />
          </a>
        </div>
      ),
    },
  ].filter((col) => col.show);

  const versionColumn = [
    {
      title: "Version No",
      dataIndex: "version_no",
      key: "version_no",
    },
    {
      title: "Version Name",
      dataIndex: "version_name",
      key: "version_name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      key: "created_on",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const versionData =
    state.data &&
    state.data.version_details &&
    state.data.version_details.length > 0 &&
    state.data.version_details.map((detail) => {
      const nextVersion = state.data.version_details.find(
        (d) => d.version_no === detail.version_no + 1
      );
      console.log("nextVersion", nextVersion);
      const endDate = nextVersion
        ? moment(nextVersion.effective_date)
            .subtract(1, "day")
            .format("DD-MMM-YY")
        : "-";
      return {
        //key: detail._id ? detail._id : detail.id,
        version_no: detail.version_no ? detail.version_no : "N/A",
        version_name: detail.version_name,
        start_date: detail.effective_date
          ? moment(detail.effective_date).format("DD-MMM-YY")
          : "-",
        end_date: endDate,
        created_on: moment(detail.created_on).format("DD-MMM-YY"),
        status: (
          <span
            style={{
              color:
                detail.version_status === "Active"
                  ? "var(--color-solid-green)"
                  : "var(--color-shade-red)",
            }}
          >
            {detail.version_status}
          </span>
        ),
      };
    });
  // Function to handle add the new row
  const handleAdd = () => {
    const newData = {
      id: count,
      from_entity: "",
      to_entity: "",
      allocation_p: "",
    };
    setDataSourceClone([...dataSourceClone, newData]);
    setCount(count + 1);
  };
  /* Function to handle clone the particular record
   * @Param key - a unique identifier of a list item
   */
  const handleAddClone = (key) => {
    const findNewData = dataSourceClone.find((data) => data.id === key);
    let cloneNewId;
    if (dataSourceClone.length > 0) {
      cloneNewId = dataSourceClone[dataSourceClone.length - 1].id + 1;
    }
    let newData = {
      id: cloneNewId,
      from_entity: findNewData.from_entity ? findNewData.from_entity : "",
      to_entity: findNewData.to_entity ? findNewData.to_entity : "",
      cost_center: findNewData.cost_center ? findNewData.cost_center : "",
      allocation_p: findNewData.allocation_p ? findNewData.allocation_p : "",
    };
    let finaldataSource = [...dataSourceClone, newData];
    const totalAllocationP = finaldataSource.reduce(
      (total, entry) => total + Number(entry.allocation_p),
      0
    );
    settotalAllocation(totalAllocationP);
    setDataSourceClone([...dataSourceClone, newData]);
    setCount(count + 1);
  };
  /* Function to handle save the local data into use state
   * @Param row - a all row of data
   */
  const handleSave = (row) => {
    const newData = [...dataSourceClone];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSourceClone(newData);
  };
  // Variable to hold filter the default column name
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    console.log("profileField: ", profileField);
  }, [profileField]);

  /* Function to handle the create Invoice form data stored
   * @param values - a user create form values
   */
  const onFinish = (values) => {
    values["invoice_table"] = profileFile ? profileFile.originFileObj : {};
    values["updated_by"] = userData[0].id;
    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];

    const uploadForm = new FormData();
    if (ipFile && ipFile.length > 0) {
      for (let i = 0; i < ipFile.length; i++) {
        uploadForm.append("notesCommentsFiles", ipFile[i].originFileObj);
      }
    }
    if (profileFile && profileFile.length > 0) {
      for (let i = 0; i < profileFile.length; i++) {
        uploadForm.append("profileFile", profileFile[i].originFileObj);
      }
    }

    const newVersionDetail = {
      version_no: Number(values.version_no),
      version_name: values.version_name,
      version_status:
        moment(invoicingProfileFieldValue.effective_date).format(
          "DD MMM YYYY"
        ) === moment().format("DD MMM YYYY")
          ? "Active"
          : "Inactive",
      effective_date: values.effective_date,
      version_comments: values.version_comments || "",
      created_on: new Date(),
      profiles: dataSourceClone,
    };

    delete values.profiles;
    if (state.flag === "edit") {
      let updatedVersionDetails;
      if (state.data.status === "Active") {
        // Create a new version detail
        updatedVersionDetails = [
          ...(state.data && state.data.version_details),
          newVersionDetail,
        ];
      } else {
        // Modify the existing version detail
        console.log("Modified version");
        updatedVersionDetails = [newVersionDetail];
      }
      values.version_details = updatedVersionDetails;
    } else {
      values.version_details = [newVersionDetail];
    }

    if (stateBtn.button === 1) {
      setSaveTableLoader(true);
    } else {
      setLoader(true);
    }

    // Api integartion
    if (stateBtn.button === 1) {
      values["status"] = "In Draft";
      if (fileKeysToDelete.length !== 0) {
        fileKeysToDelete.map(async (fileKey) => {
          await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
        });
      }
      if (state.flag === "edit") {
        values["updated_by"] = userData[0].id;
        setSaveTableLoader(true);
        uploadForm.append("bodyData", JSON.stringify(values));
        editInvoicingProfileAPi(state.data._id, uploadForm).then((res) => {
          let ssLog = {
            ss_id: state.data.id ? state.data.id : state.data._id,
            ss_name: values["invoice_profile_name"],
            code: "invoicing_profile",
            author: userData[0].id,
            action: "edit",
            notes: "Edited Invoicing Profile",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog).then((res) => {});
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.invoice_profile_name,
            ModalMessage.Invoice_Create_Save,
            ""
          );
        });
      } else {
        if (ipId === "") {
          values["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(values));
          createInvoicingProfileAPi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(
                ModalMessage.Invoicing_Profile_Create_Error
              );
              setSaveTableLoader(false);
            } else {
              if (res.status === 201) {
                setIpId(res.data.id ? res.data.id : res.data._id);
                let ssLog = {
                  ss_id: res.data.id ? res.data.id : res.data._id,
                  ss_name: values["invoice_profile_name"],
                  code: "invoicing_profile",
                  author: userData[0].id,
                  action: "view",
                  notes: "Saved Invoicing Profile",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {});
              }
              setSaveTableLoader(false);
              if (res.status === 201) {
                setSaveTableLoader(false);
                popupModalInstance.successModal(
                  values.invoice_profile_name,
                  ModalMessage.Invoice_Create_Save,
                  ``
                );
              } else {
                setSaveTableLoader(false);
                Modal.error({
                  title: "Error on create",
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: (
                    <>
                      <div style={{ fontWeight: 600 }} className="mb-10">
                        Invoice creation failed for following reasons:
                      </div>
                      {res.message.map((err, index) => {
                        return (
                          <div>
                            {index + 1}.&nbsp; {err}
                          </div>
                        );
                      })}
                    </>
                  ),
                });
              }
            }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          editInvoicingProfileAPi(ipId, uploadForm).then((res) => {
            console.log("res", res);
            if (res.status === 201) {
              let ssLog = {
                ss_id: res.data.id ? res.data.id : res.data._id,
                ss_name: values["invoice_profile_name"],
                code: "invoicing_profile",
                author: userData[0].id,
                action: "edit",
                notes: "Saved Invoicing Profile",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {});
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                values.invoice_profile_name,
                ModalMessage.Invoice_Create_Save,
                ``
              );
            } else {
              setSaveTableLoader(false);
              Modal.error({
                title: "Error on create",
                className: "popup-model-success",
                centered: true,
                icon: false,
                content: (
                  <>
                    <div style={{ fontWeight: 600 }} className="mb-10">
                      Invoice creation failed for following reasons:
                    </div>
                    {res.message.map((err, index) => {
                      return (
                        <div>
                          {index + 1}.&nbsp; {err}
                        </div>
                      );
                    })}
                  </>
                ),
              });
            }
          });
        }
      }
    } else if (stateBtn.button === 2) {
      if (profileField === "table") {
        let percentage = null;
        dataSourceClone &&
          dataSourceClone.map(
            (data) => (percentage = percentage + parseInt(data.allocation_p))
          );
        if (
          stateBtn.button === 2 &&
          percentage !== 100 &&
          percentage !== null
        ) {
          setLoader(false);
          popupModalInstance.errorModal(
            ModalMessage.Invoice_Percentage_Mismatched
          );
        }
      }
      values["status"] = "Active";
      if (state.flag === "edit") {
        values["updated_by"] = userData[0].id;
        setLoader(true);
        uploadForm.append("bodyData", JSON.stringify(values));
        popupModalInstance
          .infoModal(ModalMessage.Invoice_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              editInvoicingProfileAPi(state.data._id, uploadForm).then(
                (res) => {
                  let ssLog = {
                    ss_id: state.data.id ? state.data.id : state.data._id,
                    ss_name: values["invoice_profile_name"],
                    code: "invoicing_profile",
                    author: userData[0].id,
                    action: "edit",
                    notes: "Edited Invoicing Profile",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {});
                  setLoader(false);
                  history(`/system-setup/?back=con-ip`);
                }
              );
            }
            if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      } else {
        if (ipId === "") {
          values["created_by"] = userData[0].id;
          const modalMessage =
            state.flag === "clone"
              ? ModalMessage.Invoice_Clone_Submit
              : ModalMessage.Invoice_Create_Submit;
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(modalMessage)
            // popupModalInstance
            //   .infoModal(ModalMessage.Invoice_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({
                      fileKey: fileKey,
                      flag: "setup",
                    });
                  });
                }
                createInvoicingProfileAPi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    popupModalInstance.errorModal(
                      ModalMessage.Invoicing_Profile_Create_Error
                    );
                    setLoader(false);
                  } else {
                    console.log("ressssssssss ", res);
                    if (res.status === 201) {
                      setIpId(res.data.id ? res.data.id : res.data._id);
                      let ssLog = {
                        ss_id: res.data.id ? res.data.id : res.data._id,
                        ss_name: values["invoice_profile_name"],
                        code: "invoicing_profile",
                        author: userData[0].id,
                        action: "view",
                        notes: "Created Invoicing Profile",
                        updated_on: new Date(),
                      };
                      createActivityLogApi(ssLog).then((res) => {});
                    }
                    setLoader(false);
                    if (res.status === 201) {
                      setLoader(false);
                      history(`/system-setup/?back=con-fx`);
                    } else {
                      setLoader(false);
                      Modal.error({
                        title: "Error on create",
                        className: "popup-model-success",
                        centered: true,
                        icon: false,
                        content: (
                          <>
                            <div style={{ fontWeight: 600 }} className="mb-10">
                              Invoice creation failed for following reasons:
                            </div>
                            {res.message.map((err, index) => {
                              return (
                                <div>
                                  {index + 1}.&nbsp; {err}
                                </div>
                              );
                            })}
                          </>
                        ),
                      });
                    }
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(ModalMessage.Invoice_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({
                      fileKey: fileKey,
                      flag: "setup",
                    });
                  });
                }
                editInvoicingProfileAPi(ipId, uploadForm).then((res) => {
                  if (res.status === 201) {
                    let ssLog = {
                      ss_id: res.data.id ? res.data.id : res.data._id,
                      ss_name: values["invoice_profile_name"],
                      code: "invoicing_profile",
                      author: userData[0].id,
                      action: "view",
                      notes: "Created Invoicing Profile",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {});
                  }
                  setLoader(false);
                  if (res.status === 201) {
                    setLoader(false);
                    history(`/system-setup/?back=con-fx`);
                  } else {
                    setLoader(false);
                    Modal.error({
                      title: "Error on create",
                      className: "popup-model-success",
                      centered: true,
                      icon: false,
                      content: (
                        <>
                          <div style={{ fontWeight: 600 }} className="mb-10">
                            Invoice creation failed for following reasons:
                          </div>
                          {res.message.map((err, index) => {
                            return (
                              <div>
                                {index + 1}.&nbsp; {err}
                              </div>
                            );
                          })}
                        </>
                      ),
                    });
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        }
      }
    }
  };
  /* Function to handle the create invoice get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeVersionName = (e) => {
    setInvoicingProfileFieldValue({
      ...invoicingProfileFieldValue,
      version_name: e.target.value,
    });
  };

  const onChangeVersionComment = (content) => {
    setVersionComments(content); // Update the editor content state
    setInvoicingProfileFieldValue({
      ...invoicingProfileFieldValue,
      version_comments: content,
    });
  };

  // Function to handle stored contract cover screen column and data
  const PricingCover = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-20">
                {state.flag !== "view" && (
                  <div className="edit-invoice-entity-div">
                    <h4 className="access-title">Version Details</h4>
                    <div className="invoicing-form-item">
                      <Row align="middle" type="flex">
                        <Col span={8}>
                          <Form.Item
                            {...formItemLayout}
                            className="org-supplier-label"
                            name="version_no"
                            label="Version No"
                            colon={false}
                            initialValue={
                              state.flag === "edit"
                                ? state.data && state.data.status === "In Draft"
                                  ? 1
                                  : state.data.version_details.length + 1
                                : 1
                            }
                          >
                            <Input
                              showSearch={true}
                              defaultValue={
                                state.flag === "edit"
                                  ? state.data &&
                                    state.data.status === "In Draft"
                                    ? 1
                                    : state.data.version_details.length + 1
                                  : 1
                              }
                              // initialValue={version_no}
                              readOnly
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...formItemLayout}
                            className="org-supplier-label"
                            name="version_name"
                            label="Version Name"
                            colon={false}
                          >
                            <Input
                              onChange={onChangeVersionName}
                              allowClear
                              showSearch={true}
                              placeholder="Version Name"
                            ></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row align="middle" type="flex">
                        <Col span={8}>
                          <Form.Item
                            {...formItemLayout}
                            className="org-supplier-label"
                            name="effective_date"
                            label="Effective Date"
                            colon={false}
                            initialValue={
                              state.data &&
                              state.data.status === "In Draft" &&
                              state.data.version_details &&
                              state.data.version_details[0].effective_date
                                ? dayjs().isAfter(
                                    dayjs(
                                      state.data.version_details[0]
                                        .effective_date
                                    )
                                  )
                                  ? undefined
                                  : dayjs(
                                      state.data.version_details[0]
                                        .effective_date
                                    )
                                : undefined
                            }
                          >
                            <DatePicker
                              disabledDate={(dateValue) =>
                                disabledDate(dateValue)
                              }
                              onChange={(date, dateString) =>
                                onDateChange(date, dateString)
                              }
                              format={"DD MMM YYYY"}
                              className="report-input"
                              placeholder="Select effective Start Date"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={16}>
                          {/* <Form.Item
                            {...formItemLayout}
                            className="org-supplier-label"
                            name="version_comments"
                            label="Comments"
                            colon={false}
                          >
                            <Input.TextArea
                              onChange={onChangeVersionComment}
                              placeholder="Please Enter Comments"
                            />
                          </Form.Item> */}
                          <Form.Item
                            {...formItemLayout}
                            className="org-supplier-label"
                            name="version_comments"
                            label="Comments"
                            colon={false}
                          >
                            <ReactQuill
                              theme="snow"
                              value={versionComments} // Set the content of the editor
                              onChange={onChangeVersionComment} // Use the updated onChange handler
                              modules={{
                                toolbar: toolbarOptions,
                              }}
                              placeholder="Please Enter Comments"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <div className="edit-invoice-entity-div">
                  <h4 className="access-title">Invoicing Details</h4>
                  <div className="invoicing-form-item">
                    <Row align="middle" type="flex">
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="invoice_profile_name"
                          label="Invoicing Profile Name"
                          colon={false}
                          rules={[
                            {
                              message: "Please enter name",
                              pattern: /^(?!\s*$).+/,
                            },
                          ]}
                        >
                          <Input
                            disabled={state.flag === "view"}
                            placeholder="Invoicing Profile Name"
                            onChange={handleInvoiceProfileName}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="supplier_id"
                          label="Supplier"
                          colon={false}
                        >
                          {state.flag === "view" ? (
                            <Input
                              disabled={state.flag === "view"}
                              placeholder="Choose Supplier"
                              options={
                                supplierData &&
                                supplierData.map((data) => ({
                                  key: data.id,
                                  label: data.supplier_name,
                                  value: data.id,
                                }))
                              }
                            ></Input>
                          ) : (
                            <Select
                              disabled={state.flag === "view"}
                              allowClear
                              onChange={(key, value) =>
                                onChangeSupplier(key, value)
                              }
                              showSearch={true}
                              placeholder="Choose Supplier"
                              options={
                                supplierData &&
                                supplierData.map((data) => ({
                                  key: data.id,
                                  label: data.supplier_name,
                                  value: data.id,
                                }))
                              }
                            ></Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                {state.flag === "create" && (
                  <div className="edit-invoice-entity-div">
                    <h4 className="access-title">Profile Details</h4>
                    <div
                      className="access-content"
                      style={{ margin: "30px 20px 0px" }}
                    >
                      <Row
                        align="middle"
                        type="flex"
                        gutter={[4, 4]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          {profileField === "upload" && (
                            <>
                              <div className="create-upload-entity-div">
                                <Row
                                  align="middle"
                                  gutter={[32, 8]}
                                  justify="center"
                                  className="mt-25"
                                >
                                  <Col
                                    span={8}
                                    align="right"
                                    className="vertical-align"
                                  >
                                    <div className="upolad-input-div">
                                      <Form.Item
                                        rules={[
                                          {
                                            required: uploadRule,
                                            message: uploadRuleText,
                                          },
                                        ]}
                                      >
                                        <Row type="flex" gutter={[64, 56]}>
                                          <Col
                                            span={24}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <span className="upload-note">
                                              Click below to enter Invoicing
                                              Profile through Excel
                                            </span>
                                          </Col>
                                          <Col span={24} align="middle">
                                            <Upload
                                              className={
                                                invoicingProfileFieldValue.supplier_id
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed"
                                              }
                                              disabled={
                                                invoicingProfileFieldValue.supplier_id
                                                  ? false
                                                  : true
                                              }
                                              {...props}
                                              customRequest={dummyRequest}
                                            >
                                              <Col
                                                span={10}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <ExcelButton />
                                              </Col>
                                            </Upload>
                                          </Col>
                                          <Col
                                            span={24}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <div>
                                              <span className="upload-note">
                                                Download &nbsp;&nbsp;
                                                <Link
                                                  to={invoicingProfileTemplate}
                                                  download="invoicing_Profile_Template"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  Invoicing Profile Template
                                                </Link>
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col
                                    span={8}
                                    align="left"
                                    className="vertical-align"
                                  >
                                    <div className="upolad-input-div">
                                      <Row
                                        type="flex"
                                        gutter={
                                          profileFile.length > 0
                                            ? [64, 92]
                                            : [64, 56]
                                        }
                                      >
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <span className="upload-note">
                                            Click below to enter Invoicing
                                            Profile through Online Mode
                                          </span>
                                        </Col>
                                        <Col span={24} align="middle">
                                          <Col
                                            span={10}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <TableButton
                                              onClick={() => {
                                                setProfileFile([]);
                                                setProfileField("table");
                                              }}
                                            />
                                          </Col>
                                        </Col>
                                        <Col span={24}>
                                          <div style={{ height: 46 }}></div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          )}
                          {profileField === "table" && (
                            <>
                              {/* <div className="create-entity-div">
                              <Row
                                align="middle"
                                type="flex"
                                style={{ padding: "13px 30px 3px" }}
                              >
                                <Col span={19} className="vertical-align">
                                  <h4 className="entity-text">
                                    Invoicing Profile Details
                                  </h4>
                                </Col>
                              </Row>
                              <hr className="mt-10 view-supplier-hr" />
                              <Row style={{ padding: "19px 0px 29px 15px" }}>
                                <p className="upload-note">
                                  If you want to enter Invoicing Profile details
                                  by uploading your file, then&nbsp;&nbsp;
                                  <a onClick={() => setProfileField("upload")}>
                                    Click here
                                  </a>
                                </p>
                              </Row>
                            </div> */}
                              <div className="mt-10">
                                <Row
                                  align="middle"
                                  type="flex"
                                  gutter={[8, 16]}
                                  className="m-0"
                                >
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[8, 16]}
                                    >
                                      <Col span={24} className="vertical-align">
                                        <div>
                                          <Form.List name="profiles">
                                            {() => (
                                              <Table
                                                // loading={tableLoader}
                                                dataSource={dataSourceClone}
                                                columns={columns}
                                                bordered
                                                size="large "
                                                scroll={{ y: 800 }}
                                                pagination={false}
                                                id="supplier-create-table"
                                                className="supplier-create-table"
                                              />
                                            )}
                                          </Form.List>
                                          <div className="text-center">
                                            <Button
                                              onClick={handleAdd}
                                              shape="round"
                                              className="add-btn mb-10"
                                              size="medium"
                                              style={{
                                                width: 90,
                                              }}
                                            >
                                              <PlusOutlined
                                                style={{
                                                  color: "#9b9b9b",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {state.flag === "view" && (
                  <div className="edit-invoice-entity-div">
                    <h4 className="access-title">Version Details</h4>
                    <div className="p-20">
                      <Row>
                        <Col span={24} align="right" className="mt-20">
                          <DataTable
                            type="versionDetail"
                            colData={versionColumn}
                            dataTableData={versionData}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  // Function to handle stored details screen column and data
  const Details = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-10">
                <Form.List name="profiles" initialValue={dataSourceClone}>
                  {() => (
                    <Row>
                      <Col span={14} offset={5}>
                        <Table
                          locale={{
                            emptyText: (
                              <Empty
                                description={
                                  "No active version for this Invoicing Profile"
                                }
                              />
                            ),
                          }}
                          dataSource={dataSourceClone}
                          columns={columns}
                          scroll={{ x: "calc(300px + 50%)", y: 360 }}
                          pagination={false}
                          id={`${setupDataTableForm}`}
                          className={`${dataTable} mt-10`}
                        />
                      </Col>
                    </Row>
                  )}
                </Form.List>
                {state.flag !== "view" && (
                  <div className="text-center mt-10" style={{ width: "100%" }}>
                    <Button
                      onClick={handleAdd}
                      shape="round"
                      className="add-btn mb-10"
                      size="medium"
                      style={{
                        width: 90,
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </div>
                )}
              </div>
              <div className="contract-allocation-div">
                <span>TOTAL CONTRACT ALLOCATION = {totalAllocation}</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  // Variable to hold multible screen name and key
  const items = [
    {
      key: "1",
      label: `Invoicing Cover`,
      children: PricingCover(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag !== "create",
    },
    {
      key: "2",
      label: `Profile Details`,
      children: Details(),
      show: state.flag !== "create",
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "3",
      label: `Associations`,
      children: Associations(),
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "4",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider4",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "5",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setIpFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    document.title = "Invoicing Profile | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (ipFile && ipFile.length > 0) {
      for (let i = 0; i < ipFile.length; i++) {
        uploadForm.append("file", ipFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "ip"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <>
          {!selectedContract && (
            <div className="system-setup1">
              <Form
                name="create-invoicing-profile"
                onFinish={onFinish}
                onKeyDown={handleKeyDown}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  ["invoice_profile_name"]:
                    state.data && state.data.invoice_profile_name,
                  ["supplier_id"]:
                    state.data && state.data.supplier && state.data.supplier[0]
                      ? state.data.supplier[0]._id ||
                        (state.data &&
                          state.data.supplier &&
                          state.data.supplier[0].id)
                      : undefined,
                  ["profiles"]: dataSourceClone,
                }}
              >
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-10"
                >
                  <Col
                    className="vertical-align"
                    xs={24}
                    sm={state.flag === "view" ? 24 : 20}
                    md={state.flag === "view" ? 24 : 21}
                    lg={state.flag === "view" ? 24 : 21}
                    xl={state.flag === "view" ? 24 : 18}
                  >
                    <HeaderCard
                      mainMenu="Contract Setup"
                      subMenu="Invoicing Profile"
                      itemName={
                        state.flag === "create"
                          ? "New Invoicing Profile"
                          : state.flag === "clone"
                          ? "Clone Invoicing Profile"
                          : state.data?.invoice_profile_name
                      }
                      itemStatus={state.data?.status}
                      goBackLink="/system-setup/?back=con-ip"
                      state={state.data}
                      showDetails={
                        state.flag === "view" || state.flag === "edit"
                      }
                    />
                  </Col>
                  {state.flag !== "view" && (
                    <>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <CancelButton
                          onClick={() => history(`/system-setup/?back=con-ip`)}
                        />
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <SaveButton
                          onClick={() => (stateBtn.button = 1)}
                          type="primary"
                          htmlType="submit"
                          loading={saveTableLoader}
                          disabled={
                            state.flag === "create"
                              ? invoicingProfileFieldValue.invoice_profile_name
                                ? false
                                : true
                              : state.flag === "edit"
                              ? state.data && state.data.status !== "In Draft"
                                ? true
                                : invoicingProfileFieldValue.invoice_profile_name
                                ? false
                                : true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <SubmitButton
                          onClick={() => (stateBtn.button = 2)}
                          type="primary"
                          htmlType="submit"
                          loading={loader}
                          disabled={
                            // (state.data && state.data.status === "In Draft") ||
                            // state.flag === "create" ||
                            // state.flag === "clone"
                            //   ?
                            invoicingProfileFieldValue.invoice_profile_name &&
                            invoicingProfileFieldValue.supplier_id &&
                            invoicingProfileFieldValue.version_name &&
                            invoicingProfileFieldValue.version_comments &&
                            invoicingProfileFieldValue.effective_date &&
                            (profileFile.length > 0 ||
                              (dataSourceClone.length > 0 &&
                                dataSourceClone[0].from_entity !== "" &&
                                dataSourceClone[0].to_entity !== "" &&
                                dataSourceClone[0].cost_center !== "" &&
                                dataSourceClone[0].allocation_p !== ""))
                              ? false
                              : true
                            // :
                            // disable
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <div className="price-profile-content m-10">
                  <Row type="flex" gutter={[14, 0]} className="m-0">
                    <Col span={24}>
                      <Tabs
                        items={items}
                        onChange={handleTabChange}
                        tabBarExtraContent={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: -6,
                            }}
                          >
                            {state.flag === "view" && leTabKey === "5" && (
                              <SaveButton
                                loading={notesSaveLoader}
                                onClick={() => {
                                  saveNotesCommentsData();
                                }}
                                style={{ width: 140 }}
                              />
                            )}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          )}
          {selectedContract && (
            <div className="pipeline-click">
              <Row
                align="top"
                type="flex"
                gutter={[8, 0]}
                className="m-10"
                style={{ display: "none" }}
              >
                <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
                  <div className="pipeline-breadcrumb mt-10">
                    <span>
                      <Image
                        src={arrow_left}
                        style={{ width: 25 }}
                        preview={false}
                        onClick={() => {
                          setHeaderChanger({
                            header: "systemSetup",
                            headerItem: "1",
                            headerItemName: "systemSetup",
                          });
                          setSelectedContract();
                        }}
                        className="cursor-pointer"
                      />
                      <span className="breadcrumb-path">
                        {finalPath &&
                          finalPath.map((item, i) => {
                            return (
                              <>
                                <span
                                  key={item.id}
                                  className="path-text-pipeline"
                                >
                                  {item.name === "home"
                                    ? "Suppliers"
                                    : item.name}
                                </span>
                                <span
                                  style={{
                                    display:
                                      i === finalPath.length - 1
                                        ? "none"
                                        : "inline-block",
                                  }}
                                  className="path-icon"
                                >
                                  <RightOutlined />
                                </span>
                              </>
                            );
                          })}
                      </span>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" gutter={[8, 0]}>
                <Col span={24}>
                  <ContractFile
                    pipelinePath={finalPath}
                    backButton={backButton}
                    selected={selectedContract}
                    changeHomeTab={changeHomeTab}
                  />
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-ip`} />
      )}
    </Layout>
  );
}

export default InvoicingProfileController;
