import React, { useState, useEffect } from "react";
import { Image, Layout, Card, Button, Spin } from "antd"; //antd components
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/contract.css";
import { getSingleContractApi } from "../api/contractsApi";
import { checkApprovalApi } from "../api/workflowApi";
import ContractFile from "../components/Contract/ContractFile";
import link_off from "../assets/images/icons/link_off.svg";
import { userDataAtom, HeaderChangerAtom } from "../store/store";
import { useRecoilState } from "recoil";
import CustomLoader from "../components/CustomLoader";

function ApprovalPage() {
  // Variable to hold search param value
  const [searchParams] = useSearchParams();
  // Variable to hold manu key
  const menuKey = "2";
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold the select contract data
  const [selectedContract, setSelectedContract] = useState([]);
  // Variable to hold the contract id and expiry time
  const { contractId, expiry_time } = useParams();
  // State variable to hold expired mes visibility status
  const [expiredMsg, setExpiredMsg] = useState(false);
  // State variable to hold button loader visibility status
  const [loader, setLoader] = useState(true);
  // State variable to hold approval access status
  const [approvalAccess, setApprovalAccess] = useState(false);
  // Variable to hold logged user data
  const userData = useRecoilState(userDataAtom); //global state
  // Variable to hold todat date object
  let myDate = new Date();
  // Convert the Date object (myDate) to Unix timestamp by dividing its time in milliseconds by 1000
  const unixTimestamp = Math.floor(myDate.getTime() / 1000);
  //Get top header details
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);

  //Setting top header to show "Contract Pipeline" menu
  useEffect(() => {
    setHeaderChanger({
      header: "contract",
      headerItem: "2",
      headerItemName: headerChanger.headerItemName,
    });
  }, []);

  //Function called when change the contractid then get the selected contract information
  useEffect(() => {
    if (expiry_time <= unixTimestamp) {
      setExpiredMsg(true);
      setLoader(false);
    }
    getSingleContractApi(contractId).then((res) => {
      console.log("res", res);
      setSelectedContract(res.docs);
      setLoader(false);
    });
  }, [contractId]);
  // Function called when change the contractid then get the selected contract approval data
  useEffect(() => {
    checkApprovalApi(
      contractId,
      userData && userData[0] && userData[0].email
    ).then((res) => {
      setLoader(false);
      console.log("check approval res", res);
      setApprovalAccess(res);
    });
  }, [contractId]);

  return (
    <div className="contract">
      <Layout className="header-layout">
        <TopHeader
          headerChange={"contract"}
          feedRow={false}
          searchRow={false}
          menuKey={menuKey}
          sticky=""
        />

        {!expiredMsg && !loader && selectedContract.length !== 0 && (
          <div className="approval-link-page">
            <ContractFile
              selected={selectedContract[0]}
              screenSize={screenSize}
              approvalAccess={approvalAccess}
              pipelinePath={"approval"}
            />
          </div>
        )}

        {loader && <CustomLoader />}

        {expiredMsg && !loader && (
          <div className="auth-bg auth-page-bg">
            <Card className="auth-card p-30">
              <div>
                <Image src={link_off} preview={false} />
              </div>
              <div className="regenerate-msg mt-10">Link Expired</div>
              <div className="mt-20">
                The link is valid for only 24 hours. Please contact admin for
                further details.
              </div>
            </Card>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default ApprovalPage;
