import React, { useEffect, useState } from "react";
import TopHeader from "../Header";
import { Layout, Tabs, Divider } from "antd"; //antd components
import "../../assets/style/setup.css";
import "../../assets/style/contractpipeline.css";
import buildReportActive from "../../assets/images/icons/build_report_active.svg";
import buildReportInactive from "../../assets/images/icons/build_report_inactive.svg";
import scheduledReportActive from "../../assets/images/icons/scheduled_report_active.svg";
import scheduledReportInactive from "../../assets/images/icons/scheduled_report_inactive.svg";
import BuildReport from "./BuildReport";
import ScheduledReport from "./ScheduleReport";

import { useRecoilState } from "recoil";
import { HeaderChangerAtom, RoleAccessAtom } from "../../store/store";

const Reports = () => {
  const [headerChanger] = useRecoilState(HeaderChangerAtom); // Holds the top header global atom
  let header = headerChanger.header; // Holds the top header state extracted from the header atom
  // State variable to hold logged user role
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  const reportAccess =
    roleUser && roleUser[0] && roleUser[0].page_access.reports;
  const [reportKey, setReportKey] = useState(
    sessionStorage.getItem("reportKey")
      ? sessionStorage.getItem("reportKey")
      : reportAccess.build_report.view || reportAccess.build_report.create
      ? "1"
      : "2" // Stores which tab key is active at the moment
  );

  useEffect(() => {
    console.log("reportKey: ", reportKey);
  }, [reportKey]);

  // Handles the change in Report tab key when the user switches between tabs
  const onReportKeyChange = (key) => {
    setReportKey(key);
    sessionStorage.setItem("reportKey", key);
  };

  // Function which puts together the tab title & icon according to which tab is active
  const TabsIcon = (tab) => {
    function TabsIconHelper(title) {
      return (
        <div className="tabs-ss-title">
          <img src={title.state.icon} /> <p>{title.state.text}</p>
        </div>
      );
    }
    return (
      <>
        <div className="tabs-title-main">
          {tab.state.selected === "1" ? (
            tab.state.reportKey === "1" ? (
              <TabsIconHelper
                state={{
                  icon: buildReportActive,
                  text: "Build Report",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: buildReportInactive,
                  text: "Build Report",
                }}
              />
            )
          ) : tab.state.selected === "2" ? (
            tab.state.reportKey === "2" ? (
              <TabsIconHelper
                state={{
                  icon: scheduledReportActive,
                  text: "Scheduled Report",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: scheduledReportInactive,
                  text: "Scheduled Report",
                }}
              />
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  // Variable which holds the tab descriptions and components it needs to display
  const items =
    reportAccess.build_report.view && reportAccess.scheduled_report.view
      ? [
          {
            key: "1",
            label: <TabsIcon state={{ reportKey, selected: "1" }} />,
            children: <BuildReport />,
            access: reportAccess.build_report.view,
          },
          {
            key: "divider",
            label: <Divider className="tab-divider" type="vertical" />,
            disabled: true,
            access: reportAccess.build_report.view,
          },
          {
            key: "2",
            label: <TabsIcon state={{ reportKey, selected: "2" }} />,
            children: <ScheduledReport />,
            access: reportAccess.scheduled_report.view,
          },
        ]
      : !reportAccess.build_report.view && reportAccess.scheduled_report.view
      ? [
          {
            key: "2",
            label: <TabsIcon state={{ reportKey, selected: "2" }} />,
            children: <ScheduledReport />,
            access: reportAccess.scheduled_report.view,
          },
        ]
      : reportAccess.build_report.view && !reportAccess.scheduled_report.view
      ? [
          {
            key: "1",
            label: <TabsIcon state={{ reportKey, selected: "1" }} />,
            children: <BuildReport />,
            access: reportAccess.build_report.view,
          },
        ]
      : [];

  const { TabPane } = Tabs;

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      <div className="org-setup-div">
        <Tabs
          defaultActiveKey={reportKey}
          // items={items}
          type="card"
          className="org-setup-tabs"
          onChange={onReportKeyChange}
        >
          {items.map((item) => (
            <TabPane key={item.key} tab={item.label} disabled={item.disabled}>
              {reportKey === item.key && item.children}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </Layout>
  );
};

export default Reports;
