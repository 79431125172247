import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Space,
  Image,
  Form,
  Select,
  Badge,
  Table,
  DatePicker,
  Pagination,
  message,
  Checkbox,
  Tabs,
  Input,
  Tag,
  Button,
  Spin,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  FilterOutlined,
  EyeOutlined,
  RightOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "../../assets/style/setup.css";
import "../../assets/style/contractpipeline.css";
import UpArrow from "../../assets/images/icons/arrow_up_icon.svg";
import DownArrow from "../../assets/images/icons/arrow_down_icon.svg";
import save_filter from "../../assets/images/icons/save_filter.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import searchIcon from "../../assets/images/icons/search.svg";
import ContractFile from "./ContractFile";
import { suppliersTreeApi } from "../../api/suppliersApi";
import {
  getAcvTcvApi,
  getContractBySupplier,
  getContractListFiltersApi,
  getContractParentData,
  searchContractListApi,
} from "../../api/contractsApi";
import { createFiltersApi, getFiltersApi } from "../../api/usersApi";
import { OkButton } from "../GlobalButton";
import { userDataAtom, HeaderChangerAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import moment from "moment";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useLocation } from "react-router";
dayjs.extend(duration);

const ContractList = () => {
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  let { state } = useLocation();
  let listSpend;
  let listExpiry;
  console.log("state: ", state);
  if (state !== null && state !== undefined) {
    if (state.listSpend) ({ listSpend } = state);
    if (state.listExpiry) ({ listExpiry } = state);
  }
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [contentSize, setContentSize] = useState("maximize");
  const [supplierData, setSupplierData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [msaChildren, setMsaChildren] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); //Contract List table pagination
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // State variable to hold all filter conditions
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold selected saved filter conditions
  const [selectedSavedFilter, setSelectedSavedFilter] = useState();
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold column data visibility status
  const [colFilterShow, setColFilterShow] = useState(false);
  // State variable to hold inner filter data
  const [innerFilterShow, setInnerFilterShow] = useState();
  // State variable to hold save dialog visibility status
  const [saveDialog, setSaveDialog] = useState(false);
  // State variable to hold save filter visibility status
  const [savedFilterShow, setSavedFilterShow] = useState(false);
  // State variable to hold filter category for public & private
  const [pubPvt, setPubPvt] = useState("public");
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  // State variable to hold search filter data
  const [filterSearchVal, setFilterSearchVal] = useState("null");
  // State variable to hold save icon visibility status
  const [showSaveIcon, setShowSaveIcon] = useState(false);
  // State variable to hold selected filter condition count
  const [filterCount, setFilterCount] = useState(0);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();
  // Variable to hold group check box
  const CheckboxGroup = Checkbox.Group;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [contractDates, setContractDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedMsa, setSelectedMsa] = useState([]);
  const [selectedContractName, setSelectedContractName] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [supplierLoader, setSupplierLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [msaLoader, setMsaLoader] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);
  const [listSpendValues, setListSpendValues] = useState(
    state && state.listSpend
      ? {
          supplier_name: "All",
          msa: "All",
          start_date: undefined,
          end_date: undefined,
          status: ["Contract-in-Execution"],
        }
      : state &&
          state.listExpiry && {
            supplier_name: "All",
            msa: "All",
            start_date: undefined,
            end_date: undefined,
            status: ["Contract-in-Execution"],
          }
  );
  const [formLoader, setFormLoader] = useState(
    listSpend || listExpiry ? true : false
  );

  useEffect(() => {
    suppliersTreeApi(userData[0].id).then((res) => {
      let supplierMaster = res.map((data) => {
        delete data.children;
        return data;
      });
      supplierMaster = [
        {
          _id: "All",
          supplier_name: "All",
        },
        ...supplierMaster,
      ];
      setSupplierLoader(true);
      setSupplierData(supplierMaster);
    });
  }, []);

  useEffect(() => {
    const initializeForm = (statusValues) => {
      setTableLoader(true);
      handleSupplierChange(["All"]);
      handleMsaChange(["All"]);
      setSelectedContractName(["All"]);
      form.setFieldsValue({
        contract_name: ["All"],
        status: statusValues,
      });
    };

    const fetchMsaChilds = async (statusValues) => {
      const res = await getContractParentData(["All"], "MSA");
      const msaChilds = [
        {
          _id: "All",
          contract_name: "All",
        },
        ...res,
      ];

      setListSpendValues((prevValues) => ({
        ...prevValues,
        contract_name: msaChilds
          .filter((contract) => contract._id !== "All")
          .map((contract) => contract._id),
      }));

      initializeForm(statusValues);
      getContractList(listSpendValues, 1, 20);
    };

    if (state && (state.listSpend || state.listExpiry)) {
      fetchMsaChilds(["Contract-in-Execution"]);
    }
  }, [state]);

  useEffect(() => {
    if (listSpend || listExpiry) {
      if (listSpendValues.hasOwnProperty("contract_name")) {
        setFormLoader(false);
      }
    }
  }, [listSpendValues]);

  const onChangeContentSize = (size) => {
    setContentSize(size);
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const isColumnSorted = (column) => sortedColumn === column;

  const columns =
    !listSpend && !listExpiry
      ? [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Status", "status"),
            dataIndex: "status",
            align: "left",
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortOrder: isColumnSorted("status") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Created On", "created_on"),
            dataIndex: "created_on",
            align: "left",
            sorter: (a, b) => a.created_on.localeCompare(b.created_on),
            sortOrder: isColumnSorted("created_on") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Created By", "created_by"),
            dataIndex: "created_by_username",
            align: "left",
            sorter: (a, b) => a.created_by.localeCompare(b.created_by),
            sortOrder: isColumnSorted("created_by_username")
              ? sortOrder
              : false,
          },
          {
            title: "Action",
            dataIndex: "action",
            align: "center",
            width: 120,
          },
        ]
      : listExpiry
      ? [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
            width: "30%",
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
            width: 200,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("End Date", "end_date"),
            dataIndex: "end_date",
            align: "left",
            // sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("end_date") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Expiry In", "expiry"),
            dataIndex: "expiry",
            align: "left",
            // sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("expiry") ? sortOrder : false,
          },
        ]
      : listSpend && [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
            width: "30%",
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
            width: 200,
          },
          {
            title: renderColumnTitle("Contract Status", "status"),
            dataIndex: "status",
            align: "left",
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortOrder: isColumnSorted("status") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Spend", "spend"),
            dataIndex: "spend",
            align: "left",
            sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("spend") ? sortOrder : false,
          },
        ];

  const getIndividualAcvTcv = async (contractId) => {
    const acvTcv = await getAcvTcvApi(contractId);
    return acvTcv;
  };

  const getContractList = async (values, page, limit) => {
    if (Object.keys(values).length !== 0) setTableLoader(true);
    const res = await searchContractListApi(
      values,
      page,
      limit,
      allFilterVal,
      listSpend ? "listSpend" : listExpiry ? "listExpiry" : undefined
    );
    setPaginateData({
      ...paginateData,
      pageNo: page,
      limit: limit,
      total: res ? res.totalDocs : 0,
    });

    console.log("searchRes: ", res);

    if (res && res.docs) {
      const contractListDataPromises = res.docs.map(async (item) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          contract_name: (
            <span
              onClick={() => setSelectedContract(item)}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {item.contract_name}
            </span>
          ),
          contract_type: item.contract_type,
          supplier_id: item.supplier_id,
          supplier_name: item.supplier_name,
          status: item.contract_status.split("-").join(" "),
          created_on: moment(item.created_on).format("DD/MM/YYYY"),
          created_by_username: item.created_user_name,
          created_by: item.created_by,
          end_date: dayjs(item.end_date).format("DD/MM/YYYY"),
          action: (
            <div
              style={{
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <EyeOutlined
                title="View"
                style={{
                  fontSize: "20px",
                  color: "var(--color-solid-darkgrey)",
                }}
                className="cursor-pointer"
                onClick={() => setSelectedContract(item)}
              />
            </div>
          ),
        };

        if (listSpend) {
          // Adding spend value when trying to view Spend estimates for each contract
          const { tcv } = await getIndividualAcvTcv(
            item.id ? item.id : item._id
          );

          tableRow.spend = (
            <span style={{ color: "#4cbb17" }}>
              {parseFloat(tcv).toFixed(2)}
            </span>
          );
        } else if (listExpiry) {
          const endDate = dayjs(item.end_date, "DD/MM/YYYY");

          const sixMonthsFromNow = dayjs().add(6, "month");

          const daysUntilEndDate = endDate.diff(dayjs(), "day");

          let expiry;

          if (endDate.isBefore(dayjs())) {
            expiry = "Expired";
          } else if (endDate.isBefore(sixMonthsFromNow)) {
            expiry = `${daysUntilEndDate} days`;
          }
          tableRow.expiry =
            expiry !== "Expired" && !isNaN(daysUntilEndDate)
              ? expiry
              : "Expired";
        }

        return tableRow;
      });

      const contractListData = await Promise.all(contractListDataPromises);

      if (contractListData.length > 0) {
        if (listExpiry) {
          const filteredContracts = contractListData.filter(
            (contract) => contract.expiry !== "Expired"
          );
          console.log("filteredContracts: ", filteredContracts);
          setTableData(filteredContracts);
        } else {
          setTableData(contractListData);
        }
        setTableLoader(false);
        setContentSize("minimize");
      } else {
        setTableLoader(false);
        setTableData([]);
      }
    } else {
      setTableData([]);
    }
  };

  const onFinish = (values) => {
    if (values.contract_name && values.contract_name.includes("All")) {
      values.contract_name = msaChildren
        .filter((contract) => contract._id !== "All")
        .map((contract) => contract._id);
    }
    setFormValues(values);
    getContractListFiltersApi(values).then((res) => {
      setFilterDynamic(res);
    });
    console.log(
      "list1: ",
      (values.contract_name = msaChildren
        .filter((contract) => contract._id !== "All")
        .map((contract) => contract._id))
    );
    getContractList(values, paginateData.pageNo, paginateData.limit);
  };

  // Contract List table pagination handler
  const onPageChange = (page, limit) => {
    if (!listSpend && !listExpiry) {
      getContractList(formValues, page, limit);
    } else {
      getContractList(listSpendValues, page, limit);
    }
  };

  const handleSupplierChange = (value) => {
    setSelectedSupplier(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        supplier_name: ["All"],
      });
    }
    if (value.length !== 0) {
      setMsaLoader(true);
      getContractBySupplier(value, "MSA").then((res) => {
        let msa = [
          {
            _id: "All",
            contract_name: "All",
          },
          ...res,
        ];
        setMsaLoader(false);
        setMsaData(msa);
      });
    } else {
      setMsaData([]);
      form.setFieldsValue({
        msa: undefined,
        contract_name: undefined,
        status: undefined,
        start_date: undefined,
        end_date: undefined,
      });
      setMsaChildren([]);
      setMsaData([]);
      setSelectedMsa([]);
      setSelectedContractName([]);
      setSelectedStatus([]);
      setTableData([]);
    }
  };

  const handleMsaChange = (value) => {
    setSelectedMsa(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        msa: ["All"],
      });
    }
    if (value.length !== 0) {
      setContractLoader(true);
      getContractParentData(value, "MSA").then((res) => {
        let msaChilds = [];
        if (value.includes("All")) {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res,
          ];
        } else {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res.map((contract) => contract.children).flat(),
          ];
        }
        setContractLoader(false);
        setMsaChildren(msaChilds);
      });
    } else {
      setMsaChildren([]);
    }
  };

  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!contractDates.end_date) {
        return dateValue && dateValue < moment().startOf("day");
      }
      const isAfterEndDate = dateValue.isAfter(contractDates.end_date, "day");
      const isPastDate = dateValue && dateValue < moment().startOf("day");
      if (isAfterEndDate || isPastDate) {
        // Clear end date if start date is set beyond end date
        setContractDates({
          ...contractDates,
          end_date: null,
        });
      }
      return isAfterEndDate || isPastDate;
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!contractDates.start_date) {
        return false;
      }
      return dateValue.isBefore(contractDates.start_date, "day");
    }
  };

  useEffect(() => {
    console.log("tableData: ", tableData);
  }, [tableData]);

  const colFilterVal =
    filterDynamic &&
    filterDynamic.length !== 0 &&
    filterDynamic.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  useEffect(() => {
    console.log("filterDynamic", filterDynamic);
  }, [filterDynamic]);

  const onSearchFilterVal = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log("searchValue", searchValue);
    if (searchValue === "") {
      setFilterSearchVal("null");
    } else {
      setFilterSearchVal(searchValue);
    }
  };

  /* Function to handle select filter
   * @param key - Contains label and key of the selected option
   * @param value - Contains value of the selected option
   */
  const onCloseTag = (key, value) => {
    // console.log("onclose key", key);
    // console.log("onclose value", value);
    let emptyFilVal = {
      ...allFilterVal,
      [`${key}`]: allFilterVal[`${key}`].filter((val) => val.key !== value),
    };
    Object.keys(emptyFilVal).forEach((key) => {
      if (emptyFilVal[key].length === 0) {
        delete emptyFilVal[key];
      }
    });
    setAllFilterVal(emptyFilVal);
  };

  // Function to handle the filter save visibility
  const onClickSave = () => {
    setInnerFilterShow(false);
    setColFilterShow(false);
    setSaveDialog(!saveDialog);
  };

  /* Function to handle the create suppiler data form data stored
   * @param values - a user create form values
   */
  const onSave = async (values) => {
    setSaveDialog(false);
    const data = {
      filter_type: values.filter_type,
      filter_name: values.filter_name,
      filter_values: allFilterVal,
    };

    await createFiltersApi({
      created_by: userData[0].id,
      contract_list_filter_data: { contract_list_filter: [data] },
      created_on: new Date(),
      updated_on: new Date(),
    }).then((res) => {
      messageApi.open({
        type: "success",
        content: (
          <span>
            <span style={{ fontWeight: 600 }}>{values.filter_name} </span>
            {values.filter_type} filter has been saved successfully
          </span>
        ),
      });
      console.log("filter saved");
      getAllFilters();
    });
  };

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) => item.contract_list_filter_data?.contract_list_filter
            )
            .filter((n) => n);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
       allFilterVal state
     */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      setShowSaveIcon(false);
      getContractList(formValues, 1, 20);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        setShowSaveIcon(false);
        getContractList(formValues, 1, 20);
      } else {
        setShowSaveIcon(true);
      }
    }

    setFilterCount(
      Object.keys(allFilterVal || {}).filter(
        (a) => allFilterVal[`${a}`].length !== 0
      ).length
    );

    getContractList(formValues, 1, 20);
  }, [allFilterVal]);

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    setSelectedContract();
  }, [headerChanger]);

  useEffect(() => {
    document.title = "Contract List | Judo";
  }, []);

  return (
    <Layout>
      <div
        className={selectedContract ? "pipeline-click" : "contract-pipeline"}
      >
        {saveMessageContextHolder}
        {!selectedContract && (
          <>
            <div
              className={`action-body ${
                contentSize === "maximize"
                  ? "transition-height maximize"
                  : "transition-height minimize"
              }`}
              style={{ textAlign: "left", color: "black" }}
            >
              <div className="action-new-div">
                <Spin spinning={formLoader} size="medium">
                  <div className="create-entity-div">
                    <Row
                      align="middle"
                      type="flex"
                      style={{
                        padding:
                          contentSize === "maximize"
                            ? "12px 30px 0px"
                            : "5px 30px",
                      }}
                    >
                      <Col span={20} className="vertical-align">
                        <h4 className="entity-text">Search Contract</h4>
                      </Col>
                      <Col span={4} className="text-end">
                        {contentSize !== "maximize" && (
                          <Space>
                            <Image
                              className="cursor-pointer expand-contract-icon"
                              onClick={() => onChangeContentSize("maximize")}
                              src={DownArrow}
                              preview={false}
                            />
                          </Space>
                        )}
                      </Col>
                    </Row>
                    {contentSize === "maximize" && (
                      <>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <div className="create-supplier-div action-body-head">
                          <Form
                            form={form}
                            onFinish={onFinish}
                            initialValues={{ ...formValues }}
                          >
                            <Row align="middle" type="flex" gutter={[64, 4]}>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="supplier_name"
                                  label="SUPPLIER NAME"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder="Choose Supplier"
                                    mode="multiple"
                                    maxTagCount={2}
                                    notFoundContent={
                                      supplierLoader && <LoadingOutlined />
                                    }
                                    onChange={handleSupplierChange}
                                    options={
                                      supplierData &&
                                      supplierData.map((data) => ({
                                        key: data.id ? data.id : data._id,
                                        label: data.supplier_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedSupplier.length > 0 &&
                                          selectedSupplier.includes("All") &&
                                          data._id !== "All",
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="msa"
                                  label="MSA"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder="Choose MSA"
                                    mode="multiple"
                                    maxTagCount={2}
                                    onChange={handleMsaChange}
                                    notFoundContent={
                                      msaLoader && <LoadingOutlined />
                                    }
                                    options={
                                      msaData &&
                                      msaData.map((data) => ({
                                        key: data.id ? data.id : data._id,
                                        label: data.contract_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedMsa.length > 0 &&
                                          selectedMsa.includes("All") &&
                                          data._id !== "All",
                                      }))
                                    }
                                    onClear={() => setMsaChildren([])}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="contract_name"
                                  label="CONTRACT"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch
                                    placeholder="Choose Contract"
                                    mode="multiple"
                                    maxTagCount={2}
                                    notFoundContent={
                                      contractLoader && <LoadingOutlined />
                                    }
                                    onChange={(value) => {
                                      setSelectedContractName(value);
                                      if (value.includes("All")) {
                                        form.setFieldsValue({
                                          contract_name: ["All"],
                                        });
                                      }
                                    }}
                                    options={
                                      msaChildren &&
                                      msaChildren.map((data) => ({
                                        key: data.id
                                          ? `${data.id} - ${data.contract_name}`
                                          : `${data._id} - ${data.contract_name}`,
                                        label: data.contract_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedContractName.length > 0 &&
                                          selectedContractName.includes(
                                            "All"
                                          ) &&
                                          data._id !== "All",
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="status"
                                  label="STATUS"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    showSearch={true}
                                    placeholder="Choose Contract Status"
                                    maxTagCount={2}
                                    onChange={(value) => {
                                      setSelectedStatus(value);
                                      if (value.includes("All")) {
                                        form.setFieldsValue({
                                          status: ["All"],
                                        });
                                      }
                                    }}
                                    options={[
                                      {
                                        value: "All",
                                        label: "All",
                                      },
                                      {
                                        value: "Contract-in-Draft",
                                        label: "Contract In Draft",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-Submitted",
                                        label: "Contract Submitted",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Submitted-for-Approval",
                                        label: "Submitted For Approval",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-Returned",
                                        label: "Contract Returned",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-in-Execution",
                                        label: "Contract In Execution",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract Terminated",
                                        label: "Contract Terminated",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="start_date"
                                  label="START DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) => {
                                      if (
                                        contractDates.start_date !== null ||
                                        contractDates.start_date !== undefined
                                      ) {
                                        disabledDate(dateValue, "start_date");
                                      }
                                    }}
                                    format={"DD MMMM YYYY"}
                                    className="report-input"
                                    placeholder="Select Start Date"
                                    onChange={(date) =>
                                      setContractDates({
                                        ...contractDates,
                                        start_date: date,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="end_date"
                                  label="END DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) =>
                                      disabledDate(dateValue, "end_date")
                                    }
                                    format={"DD MMMM YYYY"}
                                    className="report-input"
                                    placeholder="Select End Date"
                                    onChange={(date) =>
                                      setContractDates({
                                        ...contractDates,
                                        end_date: date,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row align="middle" type="flex" className="mt-20">
                              <Col span={13} align="right" className="mb-15">
                                <Col span={4}>
                                  <OkButton
                                    htmlType="submit"
                                    disabled={selectedSupplier.length === 0}
                                  />
                                </Col>
                              </Col>
                              <Col span={11} align="right" className="mb-15">
                                <Image
                                  className="cursor-pointer expand-contract-icon"
                                  onClick={() =>
                                    onChangeContentSize("minimize")
                                  }
                                  src={UpArrow}
                                  style={{
                                    pointerEvents:
                                      contentSize === "minimize" && "none",
                                  }}
                                  preview={false}
                                />
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </>
                    )}
                  </div>
                </Spin>
              </div>
            </div>
            <div className="filter-white-space-head mt-10">
              <Col
                span={24}
                align="center"
                className="p-0 vertical-align"
                xs={24}
                sm={24}
                md={1}
                lg={1}
                xl={1}
                style={{ background: "white" }}
              >
                <Image
                  className="cursor-pointer saved-filter-image"
                  src={save_filter}
                  preview={false}
                  onClick={() => {
                    setColFilterShow(false);
                    setInnerFilterShow(null);
                    setSaveDialog(false);
                    setSavedFilterShow(!savedFilterShow);
                  }}
                />
                {savedFilterShow && tableData.length !== 0 && (
                  <div className="filter-div">
                    <Tabs
                      id="ss-filter-tabs"
                      className="ss-saved-filter-tabs"
                      defaultActiveKey={pubPvt}
                      items={[
                        {
                          key: "public",
                          label: `Public`,
                        },
                        {
                          key: "private",
                          label: `Private`,
                        },
                      ]}
                      onChange={(key) => setPubPvt(key)}
                    />
                    <Select
                      className="filter-select"
                      showSearch
                      placeholder="Search"
                      optionFilterProp="children"
                      open={true}
                      dropdownStyle={{
                        padding: "0",
                        boxShadow: "none",
                      }}
                      listItemHeight={0}
                      listHeight={195}
                      onChange={(val, key) => {
                        console.log("val ret***", val);
                        console.log("key ret***", key);
                        setColFilterShow(false);
                        setSaveDialog(false);
                        setSavedFilterShow(!savedFilterShow);
                        setSelectedSavedFilter(key.label);
                        setAllFilterVal(
                          savedFilters
                            .filter((data) => data.filter_name === key.label)
                            .map((v) => v.filter_values)[0]
                        );
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        savedFilters &&
                        savedFilters
                          .filter((x) => x.filter_type === pubPvt)
                          .map((item, i) => {
                            console.log("saved fil ret****", item);
                            console.log("pubPvt ret****", pubPvt);
                            return { label: item.filter_name, value: i };
                          })
                      }
                    />
                  </div>
                )}
              </Col>
              <Col
                className="p-0 vertical-align"
                align="center"
                xs={24}
                sm={24}
                md={1}
                lg={1}
                xl={1}
                style={{ background: "white" }}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setColFilterShow(!colFilterShow);
                    setInnerFilterShow(null);
                    setSaveDialog(false);
                    setSavedFilterShow(false);
                  }}
                >
                  <Badge count={filterCount}>
                    <FilterOutlined className="cursor-pointer filter-image" />
                  </Badge>
                </div>
                {colFilterShow && tableData.length !== 0 && (
                  <div className="filter-div">
                    <Select
                      className="filter-select"
                      showSearch
                      placeholder="Search"
                      optionFilterProp="children"
                      open={true}
                      dropdownStyle={{ padding: "0", boxShadow: "none" }}
                      onChange={(val) => {
                        console.log("SelectVal: ", val);
                        setColFilterShow(!colFilterShow);
                        setInnerFilterShow(val);
                        setSaveDialog(false);
                        setSavedFilterShow(false);
                      }}
                      filterOption={(input, option) =>
                        (option?.value ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={colFilterVal}
                    />
                  </div>
                )}

                {filterDynamic &&
                  filterDynamic.length !== 0 &&
                  filterDynamic.map((item) => {
                    if (innerFilterShow === "Created On") {
                      return (
                        <div className="filter-inner-div-1">
                          <div className="filter-title">
                            <span style={{ textTransform: "capitalize" }}>
                              {innerFilterShow}
                            </span>
                            <CloseCircleOutlined
                              style={{
                                fontSize: 17,
                                color: "var(--color-border)",
                              }}
                              onClick={() => {
                                setInnerFilterShow(null);
                                setColFilterShow(true);
                              }}
                            />
                          </div>
                          <RangePicker
                            className="range-picker"
                            format="DD MMM YYYY"
                            value={
                              Object.keys(allFilterVal || {}).includes(
                                innerFilterShow
                              ) &&
                              allFilterVal[innerFilterShow] &&
                              allFilterVal[innerFilterShow].length !== 0 &&
                              allFilterVal[innerFilterShow][0].key.map((date) =>
                                dayjs(date, "DD MMM YYYY")
                              )
                            }
                            onChange={(moment, val) => {
                              console.log("moment", moment);
                              console.log("val", val);
                              if (moment != null) {
                                const updatedFilterVal = {
                                  ...allFilterVal,
                                  [innerFilterShow]: [
                                    {
                                      key: val,
                                      value: val,
                                    },
                                  ],
                                };
                                setAllFilterVal(updatedFilterVal);
                              } else if (moment === null) {
                                setAllFilterVal((prevState) => ({
                                  ...prevState,
                                  [innerFilterShow]: [],
                                }));
                              }
                            }}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <>
                          {innerFilterShow === item.name && (
                            <div className="filter-inner-div">
                              <div className="filter-title">
                                <span style={{ textTransform: "capitalize" }}>
                                  {item.name}
                                </span>{" "}
                                <CloseCircleOutlined
                                  style={{
                                    fontSize: 17,
                                    color: "var(--color-border)",
                                  }}
                                  onClick={() => {
                                    setInnerFilterShow(null);
                                    setColFilterShow(true);
                                  }}
                                />
                              </div>
                              <div>
                                <Input
                                  onChange={onSearchFilterVal}
                                  placeholder="Search"
                                  suffix={
                                    <Image
                                      preview={false}
                                      className="search-icon"
                                      src={searchIcon}
                                      width={30}
                                    />
                                  }
                                  className="pp-search-input mt-5"
                                />
                              </div>
                              <CheckboxGroup
                                value={
                                  Object.keys(allFilterVal || {}).includes(
                                    item.name
                                  ) &&
                                  allFilterVal[item.name] &&
                                  allFilterVal[item.name].length !== 0 &&
                                  allFilterVal[item.name].map(
                                    (data) => data.key
                                  )
                                }
                                options={
                                  filterSearchVal === "null"
                                    ? item.filterValues.map((val) => ({
                                        label: val.label
                                          ? val.label
                                          : val.value,
                                        value: val.key,
                                        key: val.key,
                                      }))
                                    : item.filterValues
                                        .filter(
                                          (data) =>
                                            JSON.stringify(data)
                                              .toLowerCase()
                                              .indexOf(
                                                filterSearchVal.toLowerCase()
                                              ) !== -1
                                        )
                                        .map((val) => ({
                                          label: val.label
                                            ? val.label
                                            : val.value,
                                          value: val.key,
                                          key: val.key,
                                        }))
                                }
                                onChange={(val) => {
                                  let data = [];
                                  val.map((key) => {
                                    item.filterValues
                                      .filter((option) => key === option.key)
                                      .map((obj) => {
                                        data.push(obj);
                                      });
                                  });
                                  const updatedFilterVal = {
                                    ...allFilterVal,
                                    [item.name]: data,
                                  };
                                  setAllFilterVal(updatedFilterVal);
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
              </Col>
              <Space
                className="filter-white-space"
                style={{ width: "90%" }}
                size={[0, 8]}
              >
                {Object.keys(allFilterVal || {}).length !== 0 ? (
                  Object.keys(allFilterVal || {}).map((filkey) => {
                    if (
                      allFilterVal[filkey] &&
                      allFilterVal[filkey].length !== 0
                    ) {
                      return allFilterVal[filkey].map((fildata) => (
                        <div key={`${filkey}-${fildata.key}`}>
                          <Tag key={`${filkey}-${fildata.key}`}>
                            <span style={{ textTransform: "capitalize" }}>
                              {filkey}:{" "}
                              {filkey === "Updated On"
                                ? `${fildata.value[0]} - ${fildata.value[1]}`
                                : fildata.label
                                ? fildata.label
                                : fildata.value}
                            </span>
                            <span
                              onClick={() => onCloseTag(filkey, fildata.key)}
                              className="cursor-pointer"
                              style={{ paddingLeft: "5px" }}
                            >
                              <CloseCircleOutlined />
                            </span>
                          </Tag>
                        </div>
                      ));
                    }
                  })
                ) : (
                  <p className="placeholder-text">Add Filters</p>
                )}
              </Space>
              <Space
                className="filter-white-space-icons"
                style={{ width: "10%" }}
                size={[0, 8]}
              >
                {showSaveIcon && (
                  <>
                    <SaveOutlined onClick={() => onClickSave()} />
                    <CloseOutlined
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedSavedFilter();
                        setAllFilterVal({});
                        setSaveDialog(false);
                      }}
                    />
                  </>
                )}
              </Space>
            </div>
            {saveDialog && showSaveIcon && (
              <div className="filter-inner-div save-dialog-box">
                <div className="filter-title">
                  <span>Save Filter</span>{" "}
                  <CloseCircleOutlined
                    style={{
                      fontSize: 17,
                      color: "var(--color-border)",
                    }}
                    onClick={() => {
                      setSaveDialog(false);
                    }}
                  />
                </div>
                <div className="inner-div">
                  <Form onFinish={onSave}>
                    <Form.Item
                      name="filter_type"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Filter Type !",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Filter Type"
                        options={[
                          {
                            value: "public",
                            label: "Public",
                          },
                          {
                            value: "private",
                            label: "Private",
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      name="filter_name"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Filter Name !",
                        },
                      ]}
                    >
                      <Input placeholder="Filter Name" />
                    </Form.Item>
                    <span className="buttons">
                      <p onClick={() => setSaveDialog(false)}>Cancel</p>
                      <Button
                        htmlType="submit"
                        style={{
                          padding: "0",
                          border: "0",
                          height: "auto",
                        }}
                      >
                        <p>Save</p>
                      </Button>
                    </span>
                  </Form>
                </div>
              </div>
            )}
            <Table
              loading={tableLoader}
              rowClassName={() => "contract-tree-table-row"}
              dataSource={tableData}
              columns={columns}
              showSorterTooltip={false}
              bordered
              size="large "
              scroll={{
                x: "calc(300px + 50%)",
                y: "calc(100vh - 280px)",
              }}
              pagination={false}
              className="system-setup-table mt-10"
            />
            <div className="float-right mb-15 mt-15">
              <Pagination
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div>
          </>
        )}
        {selectedContract && (
          <>
            <Row
              align="top"
              type="flex"
              gutter={[8, 0]}
              className="m-10"
              style={{ display: "none" }}
            >
              <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
                <div className="pipeline-breadcrumb mt-10">
                  <span>
                    <Image
                      src={arrow_left}
                      style={{ width: 25 }}
                      preview={false}
                      onClick={() => {
                        setSelectedContract();
                      }}
                      className="cursor-pointer"
                    />
                    <span className="breadcrumb-path">
                      {finalPath &&
                        finalPath.map((item, i) => {
                          return (
                            <>
                              <span
                                key={item.id}
                                className="path-text-pipeline"
                              >
                                {item.name === "home" ? "Suppliers" : item.name}
                              </span>
                              <span
                                style={{
                                  display:
                                    i === finalPath.length - 1
                                      ? "none"
                                      : "inline-block",
                                }}
                                className="path-icon"
                              >
                                <RightOutlined />
                              </span>
                            </>
                          );
                        })}
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
            <Row type="flex" gutter={[8, 0]}>
              <Col span={24}>
                <ContractFile
                  pipelinePath={finalPath}
                  backButton={backButton}
                  selected={selectedContract}
                  screenSize={screenSize}
                  changeHomeTab={changeHomeTab}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ContractList;
