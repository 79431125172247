import React, { useState } from "react";
import { Layout, Row, Col, Card, Image, Input, Form } from "antd"; //antd components
import TopHeader from "../../Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../../../store/store";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { CancelButton, SubmitButton, SaveButton } from "../../GlobalButton";
import { useNavigate, useLocation } from "react-router-dom";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { saveTemplateApi } from "../../../api/contractAuthorityApi";
import { createActivityLogApi } from "../../../api/logApi";
import { userDataAtom } from "../../../store/store";

const UploadPreview = ({
  createState,
  createTemplate,
  uploadPreview,
  browseFile,
}) => {
  console.log("createState", createState);
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const navigate = useNavigate();
  const menuKey = "5";
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const userData = useRecoilState(userDataAtom); //global state
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = createState;
  }
  // console.log("temp:", viewTemp);
  console.log("state", state);
  console.log("Caluse.........", state.clause_content);
  const [stateButton, setStateButton] = useState(1);
  const popupModalInstance = PopupModal();

  const onFinish = async () => {
    if (stateButton === 1) {
      setSaveTableLoader(true);
      state["status"] = "Draft";
      await saveTemplateApi(state).then((res) => {
        let ssLog = {
          ss_id: res.data.id ? res.data.id : res.data._id,
          ss_name: state.contract_template_name,
          code: "template",
          author: userData[0].id,
          action: "view",
          notes: "created contract template",
          updated_on: new Date(),
        };
        createActivityLogApi(ssLog);
        setSaveTableLoader(false);
        console.log("res-draft", res);
        // setTableLoader(false);
        popupModalInstance.successModal(
          state.contract_template_name,
          ModalMessage.CA_TE_Save,
          `/contract_authority/template`
        );
      });
    } else if (stateButton === 2) {
      setSubmitLoader(true);
      state["status"] = "Active";
      await saveTemplateApi(state).then((res) => {
        let ssLog = {
          ss_id: res.data.id ? res.data.id : res.data._id,
          ss_name: state.contract_template_name,
          code: "template",
          author: userData[0].id,
          action: "view",
          notes: "created contract template",
          updated_on: new Date(),
        };
        createActivityLogApi(ssLog);
        setSubmitLoader(false);
        console.log("res-active", res);
        // setTableLoader(false);
        popupModalInstance.successModal(
          state.contract_template_name,
          ModalMessage.CA_TE_Submit,
          `/contract_authority/template`
        );
      });
    }
    console.log("values....", state);
  };

  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      {/* <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      /> */}
      <Form onFinish={onFinish} onKeyDown={handleKeyDown}>
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={18}
            >
              <Card className="setup-header-card">
                <Row type="flex" gutter={[8, 16]}>
                  <Col
                    className="vertical-align"
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Row type="flex" gutter={[8, 16]}>
                      <Col span={12}>
                        {state.viewTemp ? (
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/contract_authority/template`)
                            } // Navigate to a different location
                          />
                        ) : (
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              createTemplate(false);
                              uploadPreview(false);
                              browseFile(true);
                            }}
                          />
                        )}
                      </Col>
                      <Col span={12}>
                        <Image
                          src={arrow_right_disable}
                          style={{ width: 20 }}
                          preview={false}
                          className="cursor-not-allowed"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={17}
                    sm={17}
                    md={19}
                    lg={19}
                    xl={22}
                  >
                    <span>Contract Authority</span> &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(`/contract_authority/template`)}
                    >
                      Templates
                    </span>
                    &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    {state.viewTemp ? (
                      <span>Preview Template</span>
                    ) : (
                      <span>Create Templates</span>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            {!state.viewTemp && (
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton
                  onClick={() => navigate(`/contract_authority/template`)}
                />
              </Col>
            )}
            {!state.viewTemp && (
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SaveButton
                  type="primary"
                  htmlType="submit"
                  loader={saveTableLoader}
                  onClick={() => setStateButton(1)}
                />
              </Col>
            )}
            {!state.viewTemp && (
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  loader={submitLoader}
                  onClick={() => setStateButton(2)}
                />
              </Col>
            )}
          </Row>
          <div className="price-profile-content m-10">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
              <div className="create-Preview-div">
                <Col span={12} offset={6}>
                  <div className="preview-div mb-10">
                    <div className="template-head mb-25">
                      {state && state.contract_template_name}
                    </div>
                    {state &&
                      state.clause_content.map((pdfObject) => {
                        return (
                          <div className="mt-15" key={pdfObject.h}>
                            <h4>{pdfObject.h}</h4>
                            <p className="mt-10">
                              {pdfObject.p !== "" ? (
                                stripHtml(pdfObject.p)
                              ) : (
                                <div className="empty-tag"></div>
                              )}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </Form>
    </Layout>
  );
};

export default UploadPreview;
