import React from "react";
import { Button, Image } from "antd";
import CreateButtonIcon from "../assets/images/icons/create_button_icon.svg";
import CancelButtonIcon from "../assets/images/icons/cancel_button_icon.svg";
import SaveButtonIcon from "../assets/images/icons/save_button_icon.svg";
import OnlineButtonIcon from "../assets/images/icons/online_button-icon.svg";
import SubmitButtonIcon from "../assets/images/icons/submit_button_icon.svg";
import OkButtonIcon from "../assets/images/icons/ok_button_icon.svg";
import DeleteButtonIcon from "../assets/images/icons/delete_button_icon.svg";
import NextButtonIcon from "../assets/images/icons/next_button_icon.svg";
import AddUserButtonIcon from "../assets/images/icons/add_user_button_icon.svg";
import ExcelButtonIcon from "../assets/images/icons/excel_button_icon.svg";
import TableButtonIcon from "../assets/images/icons/table_button_icon.svg";
import ApproveButtonIcon from "../assets/images/icons/approve_button_icon.svg";
import ReturnButtonIcon from "../assets/images/icons/return_button_icon.svg";
import RejectButtonIcon from "../assets/images/icons/reject_button_icon.svg";
import ClearAllButtonIcon from "../assets/images/icons/clear_all_button_icon.svg";
import UploadButtonIcon from "../assets/images/icons/upload_button_icon.svg";
import DownloadButtonIcon from "../assets/images/icons/download_button_icon.svg";
import GenerateButtonIcon from "../assets/images/icons/generate_button_icon.svg";
import ScheduleButtonIcon from "../assets/images/icons/schedule_button_icon.svg";
import BrowseActivityIcon from "../assets/images/icons/browse_activity_icon.svg";
import BatchPredictionIcon from "../assets/images/icons/batch_prediction_icon.svg";
import PreviewButtonIcon from "../assets/images/icons/preview_button_icon.svg";
import CompareButtonIcon from "../assets/images/icons/compare_arrows_icon.svg";
import {
  EyeOutlined,
  PlusOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import "../assets/style/globalbutton.css";

/********************************************************************
- Global Button Template: 
* Use Ant-design <Row>/<Col> to construct the button in the UI.
* We use YourNameIcon() to make an icon component and pass it to
  the icon props of AntD Button.
* Write your normal button props in your file (ex: onClick()) and
  it will be passed down here through buttonProps.
* Follow the naming conventions for consistency and debugging.
---------------------------------------------------------------------
- Example of constructing two buttons for a form:
* The code below has a Cancel and Save button that will be centered
  and side-by-side with some spacing between them.

<Row type="flex" align="middle" justify="center">
  <Col span={6} style={{ marginRight: 5 }}>
    <CancelButton />
  </Col>
  <Col span={6} style={{ marginLeft: 5 }}>
    <SaveButton />
  </Col>
</Row>

---------------------------------------------------------------------
- Button Template Function:


export const YourNameButton = (buttonProps) => {
  const YourNameIcon = () => {
    return (
      <Image
        src={YourNameButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="yourName-button-global"
      type="primary"
      icon={<YourNameIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        ButtonName
      </span>
    </Button>
  );
};
 

*********************************************************************/

const CreateIcon = () => {
  return (
    <Image
      src={CreateButtonIcon}
      preview={false}
      style={{ height: 20, width: 20 }}
    />
  );
};

const DownloadIcon = () => {
  return (
    <Image
      src={DownloadButtonIcon}
      preview={false}
      style={{ height: 20, width: 20 }}
    />
  );
};

const UploadIcon = () => {
  return (
    <Image
      src={UploadButtonIcon}
      preview={false}
      style={{ height: 20, width: 20 }}
    />
  );
};

export const CreateButton = (buttonProps) => {
  return (
    <Button
      id="create-button-global"
      type="primary"
      icon={<CreateIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Create
      </span>
    </Button>
  );
};

export const CreateButtonShort = (buttonProps) => {
  return (
    <Button
      id="create-button-short-global"
      type="primary"
      icon={<CreateIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Create
      </span>
    </Button>
  );
};

export const CancelButton = (buttonProps) => {
  const CancelIcon = () => {
    return (
      <Image
        src={CancelButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="cancel-button-global"
      type="primary"
      icon={<CancelIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Cancel
      </span>
    </Button>
  );
};

export const SaveButton = (buttonProps) => {
  const SaveIcon = () => {
    return (
      <Image
        src={SaveButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="save-button-global"
      type="primary"
      icon={<SaveIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Save
      </span>
    </Button>
  );
};

export const SubmitButton = (buttonProps) => {
  const SubmitIcon = () => {
    return (
      <Image
        src={SubmitButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="submit-button-global"
      type="primary"
      icon={<SubmitIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Submit
      </span>
    </Button>
  );
};

export const OnlineButton = (buttonProps) => {
  const OnlineIcon = () => {
    return (
      <Image
        src={OnlineButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="online-button-global"
      type="primary"
      icon={<OnlineIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Online
      </span>
    </Button>
  );
};

export const CreateVersionButton = (buttonProps) => {
  return (
    <Button
      id="create-version-button-global"
      type="primary"
      icon={<PlusOutlined />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Create New Version
      </span>
    </Button>
  );
};

export const OkButton = (buttonProps) => {
  const OkIcon = () => {
    return (
      <Image
        src={OkButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="ok-button-global"
      type="primary"
      icon={<OkIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        OK
      </span>
    </Button>
  );
};

export const DeleteButton = (buttonProps) => {
  const DeleteIcon = () => {
    return (
      <Image
        src={DeleteButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="delete-button-global"
      type="primary"
      icon={<DeleteIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Delete
      </span>
    </Button>
  );
};

export const NextButton = (buttonProps) => {
  const NextIcon = () => {
    return (
      <Image
        src={NextButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="next-button-global"
      type="primary"
      icon={<NextIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Next
      </span>
    </Button>
  );
};

export const AddUserButton = (buttonProps) => {
  const AddUserIcon = () => {
    return (
      <Image
        src={AddUserButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="add-user-button-global"
      type="primary"
      icon={<AddUserIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Add
      </span>
    </Button>
  );
};

export const AddUserButtonShort = (buttonProps) => {
  const AddUserIcon = () => {
    return (
      <Image
        src={AddUserButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="add-user-short-button-global"
      type="primary"
      icon={<AddUserIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Add
      </span>
    </Button>
  );
};

export const ExcelButton = (buttonProps) => {
  const ExcelIcon = () => {
    return (
      <Image
        src={ExcelButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="excel-button-global"
      type="primary"
      icon={<ExcelIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Excel
      </span>
    </Button>
  );
};

export const TableButton = (buttonProps) => {
  const TableIcon = () => {
    return (
      <Image
        src={TableButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="table-button-global"
      type="primary"
      icon={<TableIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Table
      </span>
    </Button>
  );
};

export const ApproveButton = (buttonProps) => {
  const ApproveIcon = () => {
    return (
      <Image
        src={ApproveButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="approve-button-global"
      type="primary"
      icon={<ApproveIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Approve
      </span>
    </Button>
  );
};

export const ReturnButton = (buttonProps) => {
  const ReturnIcon = () => {
    return (
      <Image
        src={ReturnButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="return-button-global"
      type="primary"
      icon={<ReturnIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Return
      </span>
    </Button>
  );
};

export const RejectButton = (buttonProps) => {
  const RejectIcon = () => {
    return (
      <Image
        src={RejectButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="reject-button-global"
      type="primary"
      icon={<RejectIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Reject
      </span>
    </Button>
  );
};

export const ClearAllButton = (buttonProps) => {
  const ClearAllIcon = () => {
    return (
      <Image
        src={ClearAllButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="clear-all-button-global"
      type="primary"
      icon={<ClearAllIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Clear All
      </span>
    </Button>
  );
};

export const UploadButton = (buttonProps) => {
  return (
    <Button
      id="upload-button-global"
      type="primary"
      icon={<UploadIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Upload
      </span>
    </Button>
  );
};

export const DownloadButton = (buttonProps) => {
  return (
    <Button
      id="download-button-global"
      type="primary"
      icon={<DownloadIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Download
      </span>
    </Button>
  );
};

export const DownloadTemplateButton = (buttonProps) => {
  return (
    <Button
      id="download-template-button-global"
      type="primary"
      icon={<DownloadIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Download Template
      </span>
    </Button>
  );
};

export const DownloadAllCommercialDataButton = (buttonProps) => {
  return (
    <Button
      id="download-template-button-global"
      type="primary"
      icon={<DownloadIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Download Detailed View
      </span>
    </Button>
  );
};

export const AddButton = (buttonProps) => {
  return (
    <Button
      id="add-button-global"
      type="primary"
      icon={<PlusOutlined />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Add
      </span>
    </Button>
  );
};

export const ViewButton = (buttonProps) => {
  return (
    <Button
      id="view-button-global"
      type="primary"
      icon={<EyeOutlined />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        View
      </span>
    </Button>
  );
};

export const GenerateButton = (buttonProps) => {
  const GenerateIcon = () => {
    return (
      <Image
        src={GenerateButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="generate-button-global"
      type="primary"
      icon={<GenerateIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Generate Now
      </span>
    </Button>
  );
};

export const ScheduleButton = (buttonProps) => {
  const ScheduleIcon = () => {
    return (
      <Image
        src={ScheduleButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="schedule-button-global"
      type="primary"
      icon={<ScheduleIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Schedule Later
      </span>
    </Button>
  );
};

export const BrowseButton = (buttonProps) => {
  const BrowseIcon = () => {
    return (
      <Image
        src={BrowseActivityIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="browse-button-global"
      type="primary"
      icon={<BrowseIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Browse
      </span>
    </Button>
  );
};

export const BatchButton = (buttonProps) => {
  const BatchIcon = () => {
    return (
      <Image
        src={BatchPredictionIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="batch-button-global"
      type="primary"
      icon={<BatchIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Create Batch
      </span>
    </Button>
  );
};

export const PreviewButton = (buttonProps) => {
  const PreviewIcon = () => {
    return (
      <Image
        src={PreviewButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };

  return (
    <Button
      id="preview-button-global"
      type="primary"
      icon={<PreviewIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Preview
      </span>
    </Button>
  );
};

export const UploadDocumentButton = (buttonProps) => {
  return (
    <Button
      id="upload-document-button-global"
      type="primary"
      icon={<UploadIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Upload Document
      </span>
    </Button>
  );
};

export const CompareButton = (buttonProps) => {
  const CompareIcon = () => {
    return (
      <Image
        src={CompareButtonIcon}
        preview={false}
        style={{ height: 20, width: 20 }}
      />
    );
  };
  return (
    <Button
      id="compare-button-global"
      type="primary"
      icon={<CompareIcon />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Compare
      </span>
    </Button>
  );
};

export const AuthorChangeButton = (buttonProps) => {
  return (
    <Button
      id="author-change-button-global"
      type="primary"
      icon={<UserSwitchOutlined style={{ fontSize: "20px" }} />}
      {...buttonProps}
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        Change Author
      </span>
    </Button>
  );
};
