import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Form,
  Input,
  Select,
  Tag,
  Layout,
  Checkbox,
  DatePicker,
  Tabs,
  Divider,
  Radio,
} from "antd"; //antd components
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../Header";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import { ContractCurrency } from "../../../utils/data";
import { suppliersTreeApi } from "../../../api/suppliersApi";
import { getAllMsaApi } from "../../../api/msaApi";
import { getAllRatecardApi } from "../../../api/ratecardApi";
import { getAllFxTableApi } from "../../../api/fxTableApi";
import {
  createPricingProfileAPi,
  editPricingProfileAPi,
} from "../../../api/pricingProfileApi";
import { createActivityLogApi } from "../../../api/logApi";
import { CancelButton, SaveButton, SubmitButton } from "../../GlobalButton";
import NotAuthorized from "../../NotAuthorized";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  HeaderChangerAtom,
  RoleAccessAtom,
  userDataAtom,
  SupplierTreeDataAtom,
} from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import HeaderCard from "../../HeaderCard";
import { getContractByPP } from "../../../api/contractsApi";
import ContractFile from "../../Contract/ContractFile";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

const PricingProfileController = () => {
  const [form] = Form.useForm();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  //global state varaiable hold the dashboard user data to display
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  //global state variable hold the header
  let header = headerChanger.header;
  // global state variable hold the key while switch header name
  const menuKey = "5";
  //global state variable hold the navigating different routes
  const history = useNavigate();
  //global state variable hold the corresponding rate card details using location
  let { state } = useLocation();
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  console.log("state", state);

  const stateBtn = {
    button: 1,
  };
  // state variable hold the Rate Card Data from fetch API
  const [ratecardData, setRateCardData] = useState([]);
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [associationData, setAssociationData] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // Variable to hold supplier tree data
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  // state variable hold the Fx Data from fetch API
  const [fxData, setFxData] = useState([]);
  // state variable hold the pricing profile  from input field
  const [pricingSelect, setPricingSelect] = useState(
    state.data && state.data.contract_pricing
  );
  // global variable hold the inital value of Contract Currency
  const plainOptions = ["Fixed", "Variable", "Agreed"];
  // global variable hold the change value of Contract Currency
  const [checkedList, setCheckedList] = useState(
    state.data && state.data.contract_pricing
  );
  const [ppId, setPpId] = useState("");
  // global varaiable handle the button disable
  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [leFile, setLeFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  // global variable hold the all value is selected in contract currency
  const checkAll = plainOptions.length === checkedList && checkedList.length;
  useEffect(() => {
    if (state.flag === "edit" || state.flag === "view") {
      getContractByPP(state.data && state.data._id).then((res) => {
        let association = res.map((data) => {
          let tableRow = {
            associated_item: "Contract",
            name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                  ) {
                    setHeaderChanger({
                      header: "contract",
                      headerItem: "2",
                      headerItemName: "contract",
                      stateSetter: data,
                    });
                    getTopPath(data);
                    // navigate(`/contract-setup/view/invoicing-profile`, {
                    //   state: data,
                    // });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.view_contract.view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.contract_name}
              </span>
            ),
            association_date: moment(data.createdAt).format("DD-MMM-YY"),
          };
          return tableRow;
        });
        setAssociationData(association);
      });
    }
  }, [state]);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);
  const indeterminate =
    checkedList &&
    checkedList.length > 0 &&
    checkedList.length < plainOptions.length;
  // checkedList &&
  // checkedList.length > 0 &&
  // checkedList &&
  // checkedList.length < plainOptions &&
  // plainOptions.length;
  /*function to handle on change value of contract currency
   *@param list - selected input value is given
   */
  const onChange = (list) => {
    let a = list.map((element, index) => {
      if (element === "Fixed") {
        return 0;
      } else if (element === "Variable") {
        return 1;
      } else if (element === "Agreed") {
        return 2;
      }
    });
    setPricingSelect(a);
    setCheckedList(list);
    list.length > 0 &&
    !arraysEqual(
      list,
      state.data && state.data.contract_pricing
        ? state.data.contract_pricing
        : []
    )
      ? setDisable("")
      : setDisable(disableVal);
  };
  const onCheckAllChange = (e) => {
    setPricingSelect(["Fixed", "Variable", "Agreed", "All"]);
    setCheckedList(e.target.checked ? plainOptions : []);
    setDisable("");
  };

  useEffect(() => {
    getAllFxTableApi().then((res) => {
      setFxData(res);
    });
  }, []);

  const [pricingProfileName, setpricingProfileName] = useState(
    state.data.price_profile_name
  );
  // state variable hold the supplierId  from input field
  const [supplierId, setSupplierId] = useState(
    state.data && state.data.supplier_id
  );
  // state variable hold the msaId  from input field
  const [msaId, setMsaId] = useState(state.data && state.data.msa_id);
  // state variable hold the rate card details  from input field
  const [selectedOptionsRateCard, setSelectedOptionsRateCard] = useState();
  // state variable hold the rate card details  id from input field
  const [selectedOptionsRateCardId, setSelectedOptionsRateCardId] = useState(
    state.data && state.data.rate_card && state.data.rate_card._id
      ? state.data.rate_card._id
      : undefined
  );
  // state variable hold the FX table details  id from input field
  const [selectedOptionsFxId, setSelectedOptionsFxId] = useState(
    state.data && state.data.fx_table
  );
  // state variable hold the Contract Currency details  from input field
  const [selectedOptionsContractCurrency, setSelectedOptionsContractCurrency] =
    useState(state.data && state.data.contract_cc);
  // state variable hold the Delivery Currency details  from input field
  const [selectedOptionsDeliveryCurrency, setSelectedOptionsDeliveryCurrency] =
    useState(state.data && state.data.delivery_cc);
  // state variable hold the multicheck box in input field
  const [dirty, setDirty] = useState(false);
  // state variable hold the Supplier Data from fetch API
  const [supplierData, setSupplierData] = useState([]);
  // state variable hold the Msa Data from fetch API
  const [msaData, setMsaData] = useState([]);
  // state variable hold the loader on api integration
  const [loader, setLoader] = useState(false);
  // state variable hold the loading while create the data and send to API
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [ppDates, setPPDates] = useState({
    startDate: state.data.start_date,
    endDate: state.data.end_date,
  });

  // use effect handle the fetch api from suppier data
  useEffect(() => {
    suppliersTreeApi(userData[0].id).then((res) => {
      let supplierMaster = res.map((data) => {
        delete data.children;
        return data;
      });
      setSupplierData(supplierMaster);
    });
    getAllMsaApi().then((res) => {
      // console.log("res", res);
      // const filteredMsa = res.filter((msa) => msa.id === state.data.msa_id);
      // form.setFieldsValue({ msa_id: filteredMsa.contract_name });
      setMsaData(res);
    });
  }, []);

  /*function to handle on  rate cards change
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleRateCard = (key, value) => {
    setSelectedOptionsRateCardId(key);
    setSelectedOptionsRateCard(value.key);
    key !== undefined &&
    state.data &&
    state.data.rate_card &&
    state.data.rate_card._id !== key
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle on fx table change
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleFxTable = (key, value) => {
    setSelectedOptionsFxId(key);
    key !== undefined && state.data && state.data.fx_table !== key
      ? setDisable("")
      : setDisable(disableVal);
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  /*function to handle on contract currency change
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleContractCurrency = (value) => {
    setSelectedOptionsContractCurrency(value);
    value.length > 0 &&
    !arraysEqual(
      value,
      state.data && state.data.contract_cc ? state.data.contract_cc : []
    )
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle on contract currency separate close
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleContractCurrencyClose = (value) => {
    const selectcontractCurrency = selectedOptionsContractCurrency.filter(
      (data) => data !== value
    );
    setSelectedOptionsContractCurrency(selectcontractCurrency);
    form.setFieldsValue({
      contract_cc: selectcontractCurrency,
    });
    value.length > 0 &&
    !arraysEqual(
      value,
      state.data && state.data.contract_cc ? state.data.contract_cc : []
    )
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle on  delivery currency change
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleDeliveryCurrency = (value) => {
    setSelectedOptionsDeliveryCurrency(value);
    setDirty(true);
    value.length > 0 &&
    !arraysEqual(
      value,
      state.data && state.data.delivery_cc ? state.data.delivery_cc : []
    )
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle on  delivery currency separate close
   *@param key - selected input value key
   *@param value - selected input value
   */
  const handleDeliveryCurrencyClose = (value) => {
    const selectdeliveryCurrency = selectedOptionsDeliveryCurrency.filter(
      (data) => data !== value
    );
    setSelectedOptionsDeliveryCurrency(selectdeliveryCurrency);
    form.setFieldsValue({
      delivery_cc: selectdeliveryCurrency,
    });
    setDirty(false);
    value.length > 0 &&
    !arraysEqual(
      value,
      state.data && state.data.delivery_cc ? state.data.delivery_cc : []
    )
      ? setDisable("")
      : setDisable(disableVal);
  };
  //pricing profile change
  const onCreateProfileName = (e) => {
    setpricingProfileName(e.target.value === "" ? undefined : e.target.value);
    e.target.value !== "" &&
    state.data &&
    state.data.price_profile_name !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle on  Supplier data change
   *@param key - selected input value key
   *@param value - selected input value
   */
  // const onChangeSupplier = (key, value) => {
  //   form.setFieldsValue({ msa_id: null });
  //   if (value) {
  //     setSupplierId(value.key);
  //     getAllMsaApi().then((res) => {
  //       let filteredMsa = res.filter((data) => data.supplier_id === value.key);
  //       setMsaData(filteredMsa);
  //     });

  //     getAllRatecardApi().then((res) => {
  //       let filteredRc = res.filter((data) => data.supplier_id === value.key);
  //       setRateCardData(filteredRc);
  //     });

  //     getAllFxTableApi().then((res) => {
  //       let filteredFx = res.filter((data) => data.supplier_id === value.key);
  //       setFxData(filteredFx);
  //     });
  //   } else {
  //     // Handle the case where value is undefined (supplier removed)
  //     setSupplierId(null); // or whatever default value you want
  //     setMsaData([]); // or clear the MsaData
  //     setRateCardData([]); // or clear the RateCardData
  //     setFxData([]); // or clear the FxData
  //   }
  //   key !== undefined && state.data && state.data.supplier_id !== key
  //     ? setDisable("")
  //     : setDisable(disableVal);
  // };

  const onChangeSupplier = (key, value) => {
    // Clear the RateCard, MSA, FX Table, and other related fields when supplier is changed
    form.setFieldsValue({ rate_card: undefined, msa_id: null, fx_table: null }); // Clear MSA, RateCard, and FX Table fields
    setMsaId(null); // Reset selected MSA ID
    setRateCardData([]); // Clear RateCard data
    setFxData([]); // Clear FX Table data
    setSelectedOptionsRateCardId(null); // Reset selected RateCard
    setSelectedOptionsFxId(null); // Reset selected FX Table ID

    if (value) {
      setSupplierId(value.key);

      // Fetch and filter MSA, Rate Cards, and FX data based on the supplier
      getAllMsaApi().then((res) => {
        let filteredMsa = res.filter((data) => data.supplier_id === value.key);
        setMsaData(filteredMsa);
      });

      getAllRatecardApi().then((res) => {
        let filteredRc = res.filter((data) => data.supplier_id === value.key);
        setRateCardData(filteredRc); // Set filtered RateCard data
      });

      getAllFxTableApi().then((res) => {
        let filteredFx = res.filter((data) => data.supplier_id === value.key);
        setFxData(filteredFx); // Set filtered FX data
      });
    } else {
      setSupplierId(null); // Reset Supplier ID
      setMsaData([]); // Clear MSA data
      setRateCardData([]); // Clear RateCard data
      setFxData([]); // Clear FX Table data
    }

    // Disable submit if Supplier changes and RateCard becomes empty
    setDisable(true);
  };

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  const [status, setStatus] = useState(state.data && state.data.status);

  const handleRadioClick = (value) => {
    if (
      value !== "Active" &&
      state.data &&
      state.data.status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.PP_Status_Change)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setStatus(value);
            state.data && state.data.status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(
          value === "Active"
            ? ModalMessage.PP_Status_Change_To_Active
            : ModalMessage.PP_Status_Change_Confirm
        )
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setStatus(value);
            state.data && state.data.status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    }
  };

  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString, field) => {
    // for start-date datepicker
    if (field === "start_date") {
      setPPDates({
        ...ppDates,
        startDate: dateString,
      });
      dateString !== "" &&
      state.data &&
      state.data.start_date &&
      moment(state.data.start_date).format("DD MMM YYYY") !== dateString
        ? setDisable("")
        : setDisable(disableVal);
    }

    // for end-date datepicker
    if (field === "end_date") {
      setPPDates({
        ...ppDates,
        endDate: dateString,
      });
      dateString !== "" &&
      state.data &&
      state.data.end_date &&
      moment(state.data.end_date).format("DD MMM YYYY") !== dateString
        ? setDisable("")
        : setDisable(disableVal);
    }
  };

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!ppDates.endDate) {
        return false;
      }
      return dateValue.isAfter(ppDates.endDate, "day");
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!ppDates.startDate) {
        return false;
      }
      return dateValue.isBefore(ppDates.startDate, "day");
    }
  };

  /*function to handle on  MSA data change
   *@param key - selected input value key
   *@param value - selected input value
   */
  const onChangeMsa = (key, value) => {
    if (typeof value === "undefined" && typeof key === "undefined") {
      setMsaId(null);
    } else {
      setMsaId(value.key);
    }
    key !== undefined && state.data && state.data.msa_id !== key
      ? setDisable("")
      : setDisable(disableVal);
  };

  /* Function to handle next form
   * @paran value - collection of component data in value
   */
  const onFinish = async (values) => {
    if (stateBtn.button === 1) {
      setSaveTableLoader(true);
    } else {
      setLoader(true);
    }

    if (pricingProfileName === undefined) {
      values["price_profile_name"] =
        state.data && state.data.price_profile_name;
    } else {
      values["price_profile_name"] = pricingProfileName;
    }
    values["supplier_id"] = supplierId;
    values["msa_id"] = msaId;
    values["contract_pricing"] = checkedList;
    values["rate_card"] = selectedOptionsRateCardId;
    values["updated_by"] = userData[0].id;

    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];

    const uploadForm = new FormData();
    if (leFile && leFile.length > 0) {
      for (let i = 0; i < leFile.length; i++) {
        uploadForm.append("notesCommentsFiles", leFile[i].originFileObj);
      }
    }

    if (state.flag === "edit") {
      values["status"] =
        state.data && state.data.status === "In Draft"
          ? stateBtn.button === 1
            ? "In Draft"
            : "Active"
          : stateBtn.button === 1
          ? "In Draft"
          : status;
      if (stateBtn.button === 2) {
        uploadForm.append("bodyData", JSON.stringify(values));
        setLoader(true);
        popupModalInstance
          .infoModal(ModalMessage.PP_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              await editPricingProfileAPi(
                state.data._id ? state.data._id : state.data.id,
                uploadForm
              ).then((res) => {
                let ssLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: values["price_profile_name"],
                  code: "pricing_profile",
                  author: userData[0].id,
                  action: "edit",
                  notes: "Edited Pricing Profile",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                setLoader(false);
                history(`/system-setup/?back=con-pp`);
              });
            }
            if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      } else {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        uploadForm.append("bodyData", JSON.stringify(values));
        setSaveTableLoader(true);
        await editPricingProfileAPi(
          state.data._id ? state.data._id : state.data.id,
          uploadForm
        ).then((res) => {
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values["price_profile_name"],
            code: "pricing_profile",
            author: userData[0].id,
            action: "edit",
            notes: "Edited Pricing Profile",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.price_profile_name,
            ModalMessage.PP_Create_Save,
            ``
          );
        });
      }
    } else {
      values["status"] = stateBtn.button === 2 ? "Active" : "In Draft";
      if (stateBtn.button === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        if (ppId === "") {
          values["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(values));
          await createPricingProfileAPi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(
                ModalMessage.Pricing_Profile_Create_Error
              );
              setSaveTableLoader(false);
            } else {
              setPpId(res.id ? res.id : res._id);
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["price_profile_name"],
                code: "pricing_profile",
                author: userData[0].id,
                action: "view",
                notes: "Saved Pricing Profile",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                values.price_profile_name,
                ModalMessage.PP_Clone_Save,
                ``
              );
            }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          await editPricingProfileAPi(ppId, uploadForm).then((res) => {
            let ssLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: values["price_profile_name"],
              code: "pricing_profile",
              author: userData[0].id,
              action: "edit",
              notes: "Saved Pricing Profile",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              values.price_profile_name,
              ModalMessage.PP_Create_Save,
              ``
            );
          });
        }
      }
      if (stateBtn.button === 2) {
        if (ppId === "") {
          values["created_by"] = userData[0].id;
          const modalMessage =
            state.flag === "clone"
              ? ModalMessage.PP_Clone_Submit
              : ModalMessage.PP_Create_Submit;
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(modalMessage)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                createPricingProfileAPi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    popupModalInstance.errorModal(
                      ModalMessage.Pricing_Profile_Create_Error
                    );
                    setLoader(false);
                  } else {
                    let ssLog = {
                      ss_id: res.id ? res.id : res._id,
                      ss_name: values["price_profile_name"],
                      code: "pricing_profile",
                      author: userData[0].id,
                      action: "view",
                      notes: "Cloned Pricing Profile",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog);
                    setLoader(false);
                    history(`/system-setup/?back=con-pp`);
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(ModalMessage.PP_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                await editPricingProfileAPi(ppId, uploadForm).then((res) => {
                  let ssLog = {
                    ss_id: res.id ? res.id : res._id,
                    ss_name: values["price_profile_name"],
                    code: "pricing_profile",
                    author: userData[0].id,
                    action: "edit",
                    notes: "Edited Pricing Profile",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog);
                  setLoader(false);
                  history(`/system-setup/?back=con-pp`);
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        }
      }
    }
  };

  /* Function to handle submit form failed
   *@ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const DetailedForm = () => {
    return (
      <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
        <Col className="" xs={24} sm={20} md={21} lg={21} xl={24}>
          <div className="create-entity-div mt-20">
            <Row
              align="middle"
              type="flex"
              style={{ padding: "10px 20px 0px" }}
            >
              <Col span={24} className="vertical-align">
                <h4 className="div-head">Rate-Cards</h4>
              </Col>
            </Row>
            <hr className="mt-10 view-supplier-hr" />
            <Row
              align="middle"
              type="flex"
              style={{ padding: "0px 20px 0px" }}
              gutter={[8, 16]}
            >
              <Col span={24} className="vertical-align">
                {state.flag !== "view" && (
                  <Form.Item name="rate_card">
                    <Select
                      disabled={state.flag === "view"}
                      allowClear
                      onChange={(key, value) => handleRateCard(key, value)}
                      className="supplier-name-input"
                      showSearch={true}
                      placeholder="Choose rate card"
                      options={
                        ratecardData &&
                        ratecardData.map((data) => ({
                          key: data._id,
                          label: data.ratetable_name,
                          value: data._id,
                        }))
                      }
                    />
                  </Form.Item>
                )}
                {state.flag === "view" && (
                  <div className="selected-items">
                    <p className="display-items">
                      {state.data &&
                        state.data.rate_card &&
                        state.data.rate_card.ratetable_name}
                    </p>
                  </div>
                )}
                <br />
              </Col>
            </Row>
          </div>

          <div className="create-entity-div mt-30">
            <Row
              align="middle"
              type="flex"
              style={{ padding: "10px 20px 0px" }}
            >
              <Col span={24} className="vertical-align">
                <h4 className="div-head">Contract Currency</h4>
              </Col>
            </Row>
            <hr className="mt-10 view-supplier-hr" />
            <Row
              align="middle"
              type="flex"
              style={{ padding: "0px 20px" }}
              gutter={[8, 16]}
            >
              <Col span={24} className="vertical-align">
                {state.flag !== "view" && (
                  <Form.Item name="contract_cc">
                    <Select
                      disabled={state.flag === "view"}
                      allowClear
                      onChange={handleContractCurrency}
                      className="supplier-name-input"
                      showSearch={true}
                      placeholder="Choose contract currency"
                      tagRender={(props) => {
                        const { label } = props;
                        return (
                          <span
                            style={{
                              display: "none",
                            }}
                            onMouseDown={(e) => {
                              setDirty(false);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {label}
                          </span>
                        );
                      }}
                      mode="multiple"
                      options={
                        ContractCurrency &&
                        ContractCurrency.map((item) => ({
                          label: (
                            <Checkbox
                              onClick={(e) => {
                                if (dirty) {
                                  e.stopPropagation();
                                }
                                setDirty(false);
                              }}
                              checked={
                                selectedOptionsContractCurrency &&
                                selectedOptionsContractCurrency.includes(
                                  `${item.country} - ${item.currency_code}`
                                )
                              }
                            >
                              {`${item.country} - ${item.currency_code}`}
                            </Checkbox>
                          ),
                          value: `${item.country} - ${item.currency_code}`,
                        }))
                      }
                    ></Select>
                  </Form.Item>
                )}
                {selectedOptionsContractCurrency &&
                  selectedOptionsContractCurrency.length > 0 && (
                    <div className="selected-items">
                      <p className="display-items">
                        {selectedOptionsContractCurrency.map((i) => (
                          <>
                            <Tag
                              closable={state.flag !== "view"}
                              onClose={() => handleContractCurrencyClose(i)}
                            >
                              {i}
                            </Tag>
                          </>
                        ))}
                      </p>
                    </div>
                  )}
                <br />
              </Col>
            </Row>
          </div>

          <div className="create-entity-div mt-30">
            <Row
              align="middle"
              type="flex"
              style={{ padding: "10px 20px 0px" }}
            >
              <Col span={24} className="vertical-align">
                <h4 className="div-head">Delivery Currency</h4>
              </Col>
            </Row>
            <hr className="mt-10 view-supplier-hr" />
            <Row
              align="middle"
              type="flex"
              style={{ padding: "0px 20px" }}
              gutter={[8, 16]}
            >
              <Col span={24} className="vertical-align">
                {state.flag !== "view" && (
                  <Form.Item name="delivery_cc">
                    <Select
                      disabled={state.flag === "view"}
                      allowClear
                      onChange={handleDeliveryCurrency}
                      className="supplier-name-input"
                      showSearch={true}
                      placeholder="Choose delivery currency"
                      tagRender={(props) => {
                        const { label } = props;
                        return (
                          <span
                            style={{
                              display: "none",
                            }}
                            onMouseDown={(e) => {
                              setDirty(false);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {label}
                          </span>
                        );
                      }}
                      mode="multiple"
                      options={ContractCurrency.map((item) => ({
                        label: (
                          <Checkbox
                            onClick={(e) => {
                              if (dirty) {
                                e.stopPropagation();
                              }
                              setDirty(false);
                            }}
                            checked={
                              selectedOptionsDeliveryCurrency &&
                              selectedOptionsDeliveryCurrency.includes(
                                `${item.country} - ${item.currency_code}`
                              )
                            }
                          >
                            {`${item.country} - ${item.currency_code}`}
                          </Checkbox>
                        ),
                        value: `${item.country} - ${item.currency_code}`,
                      }))}
                    ></Select>
                  </Form.Item>
                )}
                {selectedOptionsDeliveryCurrency &&
                  selectedOptionsDeliveryCurrency.length > 0 && (
                    <div className="selected-items">
                      <p className="display-items">
                        {selectedOptionsDeliveryCurrency.map((i) => (
                          <>
                            <Tag
                              closable={state.flag !== "view"}
                              onClose={() => handleDeliveryCurrencyClose(i)}
                            >
                              {i}
                            </Tag>
                          </>
                        ))}
                      </p>
                    </div>
                  )}
                <br />
              </Col>
            </Row>
          </div>

          <div className="create-entity-div mt-30">
            <Row
              align="middle"
              type="flex"
              style={{ padding: "10px 20px 0px" }}
            >
              <Col span={24} className="vertical-align">
                <h4 className="div-head">Fx Table</h4>
              </Col>
            </Row>
            <hr className="mt-10 view-supplier-hr" />
            <Row
              align="middle"
              type="flex"
              style={{ padding: "0px 20px 0px" }}
              gutter={[8, 16]}
            >
              <Col span={24} className="vertical-align">
                {state.flag !== "view" && (
                  <Form.Item name="fx_table">
                    <Select
                      disabled={state.flag === "view"}
                      allowClear
                      onChange={handleFxTable}
                      className="supplier-name-input"
                      showSearch={true}
                      placeholder="Choose Fx Table"
                      options={fxData.map((item) => ({
                        label: item.fx_table_name,
                        value: item._id,
                        key: item._id,
                      }))}
                    ></Select>
                  </Form.Item>
                )}
                {state.flag === "view" && (
                  <div className="selected-items">
                    <p className="display-items">
                      {state.data &&
                        state.data.fx_Data &&
                        state.data.fx_Data.fx_table_name}
                    </p>
                  </div>
                )}
                <br />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  // function handle the component box and design
  const Pricingcover = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-20">
                <Row align="middle" type="flex">
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="PRICING PROFILE NAME"
                      colon={false}
                      rules={[
                        {
                          required: "true",
                          message: "Please enter name",
                          pattern: /^(?!\s*$).+/,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Pricing Profile Name"
                        readOnly={state.flag === "view"}
                        defaultValue={
                          state.data && state.data.price_profile_name
                        }
                        onChange={onCreateProfileName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="supplier_id"
                      label="SUPPLIER NAME"
                      colon={false}
                    >
                      {state.flag === "view" ? (
                        <Input
                          disabled={state.flag === "view"}
                          placeholder="Choose Supplier"
                        ></Input>
                      ) : (
                        <Select
                          allowClear
                          onChange={(key, value) =>
                            onChangeSupplier(key, value)
                          }
                          showSearch={true}
                          placeholder="Choose Supplier"
                          options={
                            supplierData &&
                            supplierData.map((data) => ({
                              key: data._id,
                              label: data.supplier_name,
                              value: data._id,
                            }))
                          }
                        ></Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="MSA"
                      name="msa_id"
                      colon={false}
                    >
                      {state.flag === "view" ? (
                        <Input
                          disabled={state.flag === "view"}
                          placeholder="Choose MSA"
                        ></Input>
                      ) : (
                        <Select
                          allowClear
                          onChange={(key, value) => onChangeMsa(key, value)}
                          showSearch={true}
                          placeholder="Choose MSA"
                          options={
                            supplierId && msaData.length > 0
                              ? msaData.map((data) => ({
                                  key: data.id,
                                  label: data.contract_name,
                                  value: data.id,
                                }))
                              : []
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}> */}
                  <Row align="middle" type="flex">
                    <Col span={24} className="vertical-align">
                      <Form.Item
                        label="CONTRACT PRICING"
                        className="org-supplier-label"
                        colon={false}
                      />
                    </Col>
                    <Col span={19}>
                      <Form.Item>
                        <div className="pricing-div">
                          <Row>
                            <Col>
                              <Checkbox.Group
                                options={plainOptions}
                                value={checkedList}
                                onChange={
                                  state.flag === "view" ? null : onChange
                                }
                              />
                            </Col>
                            <Col>
                              <Checkbox
                                indeterminate={indeterminate}
                                onChange={
                                  state.flag === "view"
                                    ? null
                                    : onCheckAllChange
                                }
                                checked={checkAll}
                              >
                                All
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* </Col> */}
                </Row>

                <Row align="middle" type="flex">
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="start_date"
                      label="START DATE"
                      colon={false}
                      initialValue={
                        state.data && state.data.start_date
                          ? dayjs(state.data.start_date)
                          : undefined
                      }
                    >
                      {state.flag === "view" ? (
                        <div className="formlayout-div">
                          {state.data && state.data.start_date
                            ? dayjs(state.data.start_date).format("DD MMM YYYY")
                            : "Not Selected"}
                        </div>
                      ) : (
                        <DatePicker
                          onChange={(date, dateString) =>
                            onDateChange(date, dateString, "start_date")
                          }
                          format={"DD MMM YYYY"}
                          className="report-input"
                          placeholder="Select Start Date"
                          disabledDate={(dateValue) =>
                            disabledDate(dateValue, "start_date")
                          }
                        />
                      )}
                    </Form.Item>
                    {/* <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="start_date"
                      label="START DATE"
                      colon={false}
                      initialValue={
                        state.data && state.data.start_date
                          ? dayjs(state.data.start_date)
                          : undefined
                      }
                    >
                      <DatePicker
                        disabled={state.flag === "view"}
                        disabledDate={(dateValue) =>
                          disabledDate(dateValue, "start_date")
                        }
                        onChange={(date, dateString) =>
                          onDateChange(date, dateString, "start_date")
                        }
                        format={"DD MMM YYYY"}
                        className="report-input"
                        placeholder="Select Start Date"
                      />
                    </Form.Item> */}
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="end_date"
                      label="END DATE"
                      colon={false}
                      initialValue={
                        state.data && state.data.end_date
                          ? dayjs(state.data.end_date)
                          : undefined
                      }
                    >
                      {state.flag === "view" ? (
                        <div className="formlayout-div">
                          {state.data && state.data.end_date
                            ? dayjs(state.data.end_date).format("DD MMM YYYY")
                            : "Not Selected"}
                        </div>
                      ) : (
                        <DatePicker
                          onChange={(date, dateString) =>
                            onDateChange(date, dateString, "end_date")
                          }
                          format={"DD MMM YYYY"}
                          className="report-input"
                          placeholder="Select End Date"
                          disabledDate={(dateValue) =>
                            disabledDate(dateValue, "end_date")
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {state.flag === "create" && <DetailedForm />}
                {state.flag === "edit" &&
                  state.data &&
                  state.data.status !== "In Draft" && (
                    <Row align="middle" type="flex">
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="status"
                          label="PRICING PROFILE STATUS"
                          colon={false}
                        >
                          <div className="association-radio-status">
                            <Radio.Group buttonStyle="solid" value={status}>
                              <Radio
                                value={"Active"}
                                onClick={(e) =>
                                  handleRadioClick(e.target.value)
                                }
                              >
                                Active
                              </Radio>
                              <Radio
                                value={"Inactive"}
                                onClick={(e) =>
                                  handleRadioClick(e.target.value)
                                }
                              >
                                Inactive
                              </Radio>
                            </Radio.Group>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  // function handle the design and component for pricing profile
  const PricingProfileDetails = () => {
    return (
      <Row align="middle" type="flex" className="m-0">
        <Col className="" xs={24} sm={20} md={21} lg={21} xl={24}>
          <div className="org-view-div">
            <div className="m-20">
              <DetailedForm />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  // function handle the key in row an table
  const items = [
    {
      key: "1",
      label: `Pricing cover`,
      children: Pricingcover(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag !== "create",
    },
    {
      key: "2",
      label: `Pricing Profile Details`,
      children: PricingProfileDetails(),
      show: state.flag !== "create",
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "3",
      label: `Associations`,
      children: Associations(),
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "4",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider4",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "5",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setLeFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    document.title = "Pricing Profile | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (leFile && leFile.length > 0) {
      for (let i = 0; i < leFile.length; i++) {
        uploadForm.append("file", leFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "pp"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <>
          {!selectedContract && (
            <div className="system-setup1">
              <Form
                form={form}
                name="create-pricingprofile"
                onFinish={onFinish}
                onKeyDown={handleKeyDown}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  ["price_profile_name"]: state.data.price_profile_name,
                  ["contract_pricing"]:
                    state.data.contract_pricing &&
                    state.data.contract_pricing.length > 0 &&
                    state.data.contract_pricing[0],
                  ["rate_card"]:
                    state.data &&
                    state.data.rate_card &&
                    state.data.rate_card.ratetable_name,
                  // state.rate_card.length !== 0 &&
                  // state.rate_card.map((rc) => rc._id),
                  ["contract_cc"]: state.data.contract_cc,
                  ["delivery_cc"]: state.data.delivery_cc,
                  ["fx_table"]: state.data.fx_table,
                  ["supplier_id"]:
                    state.data &&
                    state.data.supplier &&
                    state.data.supplier.supplier_name
                      ? state.data.supplier.supplier_name
                      : undefined,
                  // state.supplier.length !== 0
                  //   ? state.supplier[0].supplier_name
                  //   : "N/A",
                  ["msa_id"]:
                    state.data && state.data.msa_name
                      ? state.data.msa_name
                      : undefined,
                }}
              >
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-10"
                >
                  <Col
                    className="vertical-align"
                    xs={24}
                    sm={state.flag === "view" ? 24 : 20}
                    md={state.flag === "view" ? 24 : 21}
                    lg={state.flag === "view" ? 24 : 21}
                    xl={state.flag === "view" ? 24 : 18}
                  >
                    <HeaderCard
                      mainMenu="Contract Setup"
                      subMenu="Pricing Profile"
                      itemName={
                        state.flag === "create"
                          ? "New Pricing Profile"
                          : state.flag === "clone"
                          ? "Clone Pricing Profile"
                          : state.data?.price_profile_name
                      }
                      itemStatus={state.data?.status}
                      goBackLink="/system-setup/?back=con-pp"
                      state={state.data}
                      showDetails={
                        state.flag === "view" || state.flag === "edit"
                      }
                    />
                  </Col>
                  {state.flag !== "view" && (
                    <>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <CancelButton
                          onClick={() => history(`/system-setup/?back=con-pp`)}
                        />
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <SaveButton
                          onClick={() => (stateBtn.button = 1)}
                          type="primary"
                          htmlType="submit"
                          loading={saveTableLoader}
                          disabled={
                            state.flag === "create"
                              ? pricingProfileName
                                ? false
                                : true
                              : state.flag === "edit"
                              ? state.data && state.data.status !== "In Draft"
                                ? true
                                : pricingProfileName
                                ? false
                                : true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <SubmitButton
                          onClick={() => (stateBtn.button = 2)}
                          type="primary"
                          htmlType="submit"
                          loading={loader}
                          disabled={
                            (state.data && state.data.status === "In Draft") ||
                            state.flag === "create" ||
                            state.flag === "clone" ||
                            (state.flag === "edit" &&
                              !selectedOptionsRateCardId) // Disable when RateCard is empty in Edit mode
                              ? pricingProfileName &&
                                supplierId &&
                                ppDates.startDate &&
                                selectedOptionsRateCardId && // Ensure RateCard is selected
                                selectedOptionsContractCurrency &&
                                selectedOptionsContractCurrency.length > 0 &&
                                selectedOptionsDeliveryCurrency &&
                                selectedOptionsDeliveryCurrency.length > 0 &&
                                selectedOptionsFxId &&
                                pricingSelect &&
                                pricingSelect.length > 0
                                ? false
                                : true
                              : disable
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <div className="price-profile-content m-10">
                  <Row type="flex" gutter={[14, 0]} className="m-0">
                    <Col span={24}>
                      <Tabs
                        items={items}
                        onChange={handleTabChange}
                        tabBarExtraContent={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: -6,
                            }}
                          >
                            {state.flag === "view" && leTabKey === "5" && (
                              <SaveButton
                                loading={notesSaveLoader}
                                onClick={() => {
                                  saveNotesCommentsData();
                                }}
                                style={{ width: 140 }}
                              />
                            )}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          )}
          {selectedContract && (
            <div className="pipeline-click">
              <Row
                align="top"
                type="flex"
                gutter={[8, 0]}
                className="m-10"
                style={{ display: "none" }}
              >
                <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
                  <div className="pipeline-breadcrumb mt-10">
                    <span>
                      <Image
                        src={arrow_left}
                        style={{ width: 25 }}
                        preview={false}
                        onClick={() => {
                          setHeaderChanger({
                            header: "systemSetup",
                            headerItem: "1",
                            headerItemName: "systemSetup",
                          });
                          setSelectedContract();
                        }}
                        className="cursor-pointer"
                      />
                      <span className="breadcrumb-path">
                        {finalPath &&
                          finalPath.map((item, i) => {
                            return (
                              <>
                                <span
                                  key={item.id}
                                  className="path-text-pipeline"
                                >
                                  {item.name === "home"
                                    ? "Suppliers"
                                    : item.name}
                                </span>
                                <span
                                  style={{
                                    display:
                                      i === finalPath.length - 1
                                        ? "none"
                                        : "inline-block",
                                  }}
                                  className="path-icon"
                                >
                                  <RightOutlined />
                                </span>
                              </>
                            );
                          })}
                      </span>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" gutter={[8, 0]}>
                <Col span={24}>
                  <ContractFile
                    pipelinePath={finalPath}
                    backButton={backButton}
                    selected={selectedContract}
                    changeHomeTab={changeHomeTab}
                  />
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-pp`} />
      )}
    </Layout>
  );
};

export default PricingProfileController;
