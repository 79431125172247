import { axiosInstance } from "../utils/common";

export const getAllPricingProfileApi = () => {
  return axiosInstance
    .get("v1/pricingProfiles")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfilePaginationApi = (
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/pricingProfiles/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileOnlyApi = () => {
  return axiosInstance
    .get(`v1/pricingProfiles/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSinglePricingProfileDataApi = (pricingProfileId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/single/${pricingProfileId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileByIdApi = (pricingProfileId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/${pricingProfileId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileReportApi = (pricingProfileId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/report/${pricingProfileId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createPricingProfileAPi = (pricingProfileDetail) => {
  return axiosInstance
    .post("v1/pricingProfiles", pricingProfileDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const editPricingProfileAPi = (
  pricingProfileId,
  pricingProfileDetail
) => {
  return axiosInstance
    .patch(`v1/pricingProfiles/${pricingProfileId}`, pricingProfileDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileBySupplier = (supplierId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/get/pp/by-supplier/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileByRatecard = (ratecardId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/get/pp/by-ratecard/${ratecardId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileByFx = (fxId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/get/pp/by-fx/${fxId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete legal entity by ID
export const deletePPApi = (ppId) => {
  return axiosInstance
    .delete(`v1/pricingProfiles/${ppId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllPricingProfileFiltersApi = () => {
  return axiosInstance
    .get(`v1/pricingProfiles/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPricingProfileList = (supplierId) => {
  return axiosInstance
    .get(`v1/pricingProfiles/supplier-based/list?supplier_id=${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
