import React, { useState, useEffect } from "react";
import { Table, Row, Col, Pagination } from "antd"; //antd components
import { useNavigate } from "react-router-dom";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  PricingProfileAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import {
  getPricingProfilePaginationApi,
  deletePPApi,
  getAllPricingProfileApi,
} from "../../../api/pricingProfileApi";
import { CreateButton } from "../../GlobalButton";
import moment from "moment";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import ActionButton from "../../ActionButton";
import GlobalFilter from "../../GlobalFilter";

function PricingProfile() {
  const popupModalInstance = PopupModal();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [pricingProfileName, setPricingProfileName] =
    useRecoilState(PricingProfileAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  const [showiconsOf, setShowiconsOf] = useState(null);
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    pricingProfileData(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Pricing Profile Name", "pricing_profile_name"),
      dataIndex: "pricing_profile_name",
      align: "left",
      width: 400,
      sorter: true,
      sortOrder: isColumnSorted("pricing_profile_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Ratetable Name", "ratetable_name"),
      dataIndex: "ratetable_name",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("ratetable_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract Pricing", "contract_pricing"),
      dataIndex: "contract_pricing",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("contract_pricing") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.pricing_profile.view ||
          roleUser[0].page_access.system_setup.pricing_profile.create)
          ? true
          : false,
    },
  ];

  const columns = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleViewPricingProfile = (item) => {
    let ssLog = {
      ss_id: item._id ? item._id : item.id,
      ss_name: item.price_profile_name,
      code: "pricing_profile",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Pricing Profile",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get pricing profile data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const pricingProfileData = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getPricingProfilePaginationApi(page, limit, allFilterVal, sortColumn).then(
      (res) => {
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        console.log("getPricingProfilePaginationApi", res);
        const pricingData = res.docs.map((item, index) => {
          let tableRow = {
            key: item._id ? item._id : item.id,
            pricing_profile_name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                  ) {
                    handleViewPricingProfile(item);
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: item,
                    });
                    history(`/contract-setup/view/pricing-profile`, {
                      state: { data: item, flag: "view" },
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "pointer"
                      : "default",
                }}
              >
                {item.price_profile_name}
              </span>
            ),
            ratetable_name:
              item.rate_card && item.rate_card.ratetable_name
                ? item.rate_card.ratetable_name
                : "-",
            contract_pricing:
              item.contract_pricing && item.contract_pricing.length > 0
                ? item.contract_pricing
                    .map((cp) => {
                      let cp_decode;
                      cp_decode = cp;
                      return cp_decode;
                    })
                    .join(", ")
                : "-",
            status: (
              <span
                style={{
                  // color: "var(--color-solid-orange)",
                  fontWeight: "500",
                }}
              >
                {item.status}
              </span>
            ),
            created_on: moment(item.createdAt).format("DD-MMM-YY"),
            created_by: item.created_user_name,
            updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
            updated_by: item.updated_user_name,
            action: (
              <ActionButton
                AccessCheck={
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.pricing_profile
                }
                data={item}
                dataStatus={item.status}
                itemName={item.price_profile_name}
                setHeaderChanger={setHeaderChanger}
                headerValue={{
                  header: "systemSetup",
                  headerItem: "2",
                  headerItemName: "systemSetup",
                }}
                menuItem="contract-setup"
                subMenuItem="pricing-profile"
                deleteInfo={ModalMessage.PP_Delete_Info}
                handleDelete={handleDeletePP}
                handleView={handleViewPricingProfile}
              />
            ),
          };
          return tableRow;
        });
        setTableData(pricingData);
        setLoader(false);
      }
    );
  };

  const handleDeletePP = (ppId, pp_name, inputPPName) => {
    if (inputPPName === pp_name) {
      deletePPApi(ppId).then((res) => {
        let umLog = {
          ss_id: ppId,
          ss_name: pp_name,
          code: "pricing_profile",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted Pricing Profile",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(pp_name, ModalMessage.PP_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              pricingProfileData(1, 20, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.PP_Delete_Error);
    }
  };

  // store pricingprofile name in global state
  const getAllPP = async () => {
    try {
      const res = await getAllPricingProfileApi();
      setPricingProfileName(res.map((i) => i.price_profile_name));
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished fetching pricingprofile");
    }
  };

  useEffect(() => {
    getAllPP();

    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, []);

  const onPageChange = (page, limit) => {
    pricingProfileData(page, limit, allFilterVal, sortColumnOptions);
  };

  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      pricingProfileData(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        pricingProfileData(1, 20, allFilterVal, sortColumnOptions);
      } else {
        pricingProfileData(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Pricing Profile | Judo";
  }, []);

  const onCreatePP = () => {
    history(`/contract-setup/create/pricing-profile`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.pricing_profile.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.pricing_profile.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.pricing_profile.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"pricingProfile"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.pricing_profile.create && (
            <Col xl={2} md={4} xs={24} className="vertical-align">
              <CreateButton onClick={onCreatePP} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          loading={loader}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columns}
          onChange={handleTableChange}
          bordered
          size="large "
          scroll={{ y: "calc(100vh - 255px)" }}
          pagination={false}
          className="system-setup-table"
          onRow={(record) => {
            return {
              onMouseOver: () => {
                setShowiconsOf(record.key);
              },
              onMouseOut: () => {
                setShowiconsOf(null);
              },
            };
          }}
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default PricingProfile;
