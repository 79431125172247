import { axiosInstance } from "../utils/common";

export const contractPipelineApi = (
  page,
  limit,
  switchFilter,
  searchVal,
  filterObj,
  loggedInUser,
  sortColumn
) => {
  return axiosInstance
    .post(
      `v1/contracts/pipeline?limit=${limit}&page=${page}&switchFilter=${switchFilter}&loggedInUser=${loggedInUser}`,
      { filterObj: filterObj, sortColumn }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const contractsAllApi = (supplier_id) => {
  return axiosInstance
    .get(`v1/contracts/${supplier_id}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const contractFilterApi = (
  setupCategoryType,
  supplier_id,
  contract_id,
  start_date,
  end_date,
  status
) => {
  return axiosInstance
    .get(
      `v1/contracts/${setupCategoryType}/${supplier_id}/${contract_id}/${start_date}/${end_date}/${status}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createContractAPi = (contractBody) => {
  return axiosInstance
    .post("v1/contracts", contractBody, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// Get total ACV & TCV values for all contracts
export const getAllAcvTcvApi = () => {
  return axiosInstance
    .get(`v1/contracts/all/acv_tcv`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// get contract by ID
export const getAcvTcvApi = (contractId) => {
  return axiosInstance
    .get(`v1/contracts/acv_tcv/${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // console.log("ResponseData",res.data);
};

// view contract
export const searchContractsApi = (
  searchVal,
  page,
  limit,
  switchFilter,
  mode,
  loggedInUser
) => {
  // console.log("searchval", searchVal);
  return (
    axiosInstance
      // .get(`v1/contracts/search/${searchVal}`)
      .post(`v1/contracts/search`, {
        searchVal: searchVal,
        page: page,
        limit: limit,
        switchFilter: switchFilter,
        mode: mode,
        loggedInUser: loggedInUser,
      })
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  );
};

// get single contract
export const getSingleContractApi = (contractId) => {
  return axiosInstance
    .get(`v1/contracts/single/${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractParent = (contractId) => {
  return axiosInstance
    .get(
      `v1/contracts/get/parent/grandparent/data/from/contract/master/${contractId}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// get contract detail data
export const getContractParentData = (contractId, parentType) => {
  return axiosInstance
    .post(`v1/contracts/parent/data`, {
      contractId: contractId,
      parentType: parentType,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editContractAPi = (contractId, updateBody) => {
  return axiosInstance
    .patch(`v1/contracts/${contractId}`, updateBody, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllPipelineContractsAPi = (loggedInUser) => {
  return axiosInstance
    .get(`v1/contracts/get/all/pipeline/${loggedInUser}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAggregateValueApi = (contractId, contractType) => {
  return axiosInstance
    .get(`v1/contracts/aggregate/table/${contractId}/${contractType}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllApprovedContracts = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/approved-contracts`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllSupplements = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/supplement`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllCcn = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/ccn`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllSow = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/sow`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractBySupplier = (supplierId, contractType) => {
  return axiosInstance
    .post(`v1/contracts/get/contract/association/supplier/`, {
      supplierId: supplierId,
      contractType: contractType,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractByPP = (ppId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/association/pp/${ppId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractByIP = (ipId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/association/ip/${ipId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractByWF = (wfId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/association/wf/${wfId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllActionOwner = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/action-owner`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getApproverListFromEmailList = (approverEmailList) => {
  return axiosInstance
    .post(`v1/contracts/get/approver-email-list`, approverEmailList)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractByUser = (userId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/association/by-user/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getBySupplierWoMsa = (supplierId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/by-supplier/wo-msa/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getByMsa = (msaId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/by-msa/${msaId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllApprovedContractsWoMsa = () => {
  return axiosInstance
    .get(`v1/contracts/get/all/approved-contracts/wo-msa`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractExpiryCount = () => {
  return axiosInstance
    .get(`v1/contracts/expiry/contractlist/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchContractListApi = (
  searchBody,
  page,
  limit,
  filterObj,
  searchCase
) => {
  return axiosInstance
    .post(`v1/contracts/search/contractlist/?limit=${limit}&page=${page}`, {
      searchBody: searchBody,
      allFilterVal: filterObj,
      searchCase: searchCase,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractPipelineFiltersApi = (loggedInUser) => {
  return axiosInstance
    .get(`v1/contracts/filters/pipeline/all/${loggedInUser}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractListFiltersApi = (searchBody) => {
  return axiosInstance
    .post(`v1/contracts/filters/contractlist/all/`, searchBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateContractDocsApi = (contractId, updatedContractDocBody) => {
  return axiosInstance.post(
    `v1/contracts/contract-doc/${contractId}`,
    updatedContractDocBody,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const authorChangeApi = (updateBody) => {
  return axiosInstance
    .patch(`v1/contracts/author/change`, updateBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const contractExtractionApi = (extractBody) => {
  return axiosInstance
    .post(`v1/contracts/contract-extraction`, extractBody)
    .then((res) => res.data)
    .catch((err) => console.error("ce-error", err));
};

export const getContractValueApi = (contractId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/value?contract_id=${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractById = (contractId) => {
  return axiosInstance
    .get(`v1/contracts/get/contract/details?contract_id=${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractTreeApi = (
  page,
  limit,
  supplierId,
  contractId,
  parentType
) => {
  return axiosInstance
    .get(
      `v1/contracts/get/contract/tree?page=${page}&limit=${limit}&supplier_id=${supplierId}&contract_id=${contractId}&parent_type=${parentType}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchContractTree = (searchQuery) => {
  return axiosInstance
    .get(`v1/contracts/search/contract/tree?searchQuery=${searchQuery}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
