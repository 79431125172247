import { axiosInstance } from "../utils/common";

// Get all details of a single supplier by Supplier ID
export const getSingleSupplierDataApi = (supplierId) => {
  return axiosInstance
    .get(`v1/suppliers/single/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const suppliersTreeApi = (userId) => {
  return axiosInstance
    .get(`v1/suppliers/?userId=${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// create supplier
export const suppliersCreateApi = (supplier) => {
  console.log("supplier***", supplier);
  return axiosInstance
    .post("v1/suppliers", supplier, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// Get Active Supplier Count
export const getActiveSupplierCount = () => {
  return axiosInstance
    .get(`v1/suppliers/active/count`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// list active supplier only
export const suppliersOnlyApi = (generatedDate) => {
  return axiosInstance
    .post(`v1/suppliers/only`, { generatedDate: generatedDate })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

//list all suppliers regardless of status
export const allSuppliersOnlyApi = () => {
  return axiosInstance
    .get(`v1/suppliers/all`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// supplier pagination
export const suppliersPaginationApi = (
  page,
  limit,
  searchVal,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/suppliers/pagination/${searchVal}?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// edit supplier
export const editSupplierApi = (supplierId, supplierDetail) => {
  return axiosInstance
    .patch(`v1/suppliers/${supplierId}`, supplierDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete supplier by ID
export const deleteSupplierApi = (supplierId) => {
  console.log("supplierId", supplierId);
  return axiosInstance
    .delete(`v1/suppliers/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const treeDataApi = (userId, contractType) => {
  return axiosInstance
    .get(`v1/suppliers/contract/tree/${userId}/${contractType}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSupplierAcvTcv = (page, limit) => {
  return axiosInstance
    .get(`v1/suppliers/atcv?page=${page}&limit=${limit}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllSupplierFiltersApi = () => {
  return axiosInstance
    .get(`v1/suppliers/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const downloadSupplierFileApi = (updateBody) => {
  return axiosInstance
    .post(`v1/suppliers/download/file`, updateBody, {
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSupplierEntityList = (supplierId) => {
  return axiosInstance
    .get(`v1/suppliers/entities/list?supplier_id=${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
