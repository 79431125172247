import React, { createContext, useEffect, useState } from "react";
import ContractRouter from "../Contract/ContractRouter";

export const ContractTabContext = createContext();

export const ContractTabProvider = ({ children }) => {
  const defaultBreadcrumbs = [
    {
      title: "Suppliers",
      apiArgs: {
        supplier_id: "undefined",
        contract_id: "undefined",
        type: "Supplier",
      },
    },
  ];
  const [tabs, setTabs] = useState(() => {
    const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
    return storedTabs.length
      ? storedTabs
      : [
          {
            id: "contract-tree",
            breadcrumbs: [
              {
                title: "Suppliers",
                apiArgs: {
                  supplier_id: "undefined",
                  contract_id: "undefined",
                  type: "Supplier",
                },
              },
            ],
            children: <ContractRouter breadcrumbs={defaultBreadcrumbs} />,
            type: "Supplier",
            title: "Contracts",
          },
        ];
  });

  const [breadcrumbs, setBreadcrumbs] = useState(defaultBreadcrumbs);
  const [activeKey, setActiveKey] = useState(() => {
    const storedActiveKey = localStorage.getItem("activeContractTab");
    return storedActiveKey ? JSON.parse(storedActiveKey) : "contract-tree";
  });

  // Store tabs in localStorage whenever they change
  useEffect(() => {
    const tabsToStore = tabs.map(({ id, title, breadcrumbs, type }) => ({
      id,
      title,
      breadcrumbs,
      type,
    }));
    localStorage.setItem("tabs", JSON.stringify(tabsToStore));
  }, [tabs]);

  useEffect(() => {
    localStorage.setItem("activeContractTab", JSON.stringify(activeKey));
    document.title =
      `${
        tabs.find((tab) => tab.id === activeKey)?.title
      } | Contract Tree | Judo` || `Contracts | Contract Tree | Judo`;
  }, [activeKey]);

  const handleAddTab = (tab) => {
    const existingTab = tabs.find((t) => t.id === tab.id);
    if (existingTab) {
      setActiveKey(existingTab.id);
    } else {
      const newTabId = tab.id;
      const newTab = {
        id: newTabId,
        title: tab.title || `New Tab ${tabs.length + 1}`,
        breadcrumbs: tab.breadcrumbs,
        children: tab.children,
        type: tab.type,
        closable: true,
      };
      setTabs((prevTabs) => [...prevTabs, newTab]);
      setActiveKey(newTabId);
    }
  };

  const handleRemoveTab = (targetKey) => {
    setTabs((prevTabs) => {
      const targetIndex = prevTabs.findIndex((tab) => tab.id === targetKey);
      const remainingTabs = prevTabs.filter((tab) => tab.id !== targetKey);

      if (targetKey === activeKey) {
        const nextTab =
          remainingTabs[targetIndex] || remainingTabs[targetIndex - 1];
        setActiveKey(nextTab ? nextTab.id : "contract-tree");
      }

      return remainingTabs;
    });
  };

  return (
    <ContractTabContext.Provider
      value={{
        tabs,
        setTabs,
        activeKey,
        setActiveKey,
        defaultBreadcrumbs,
        breadcrumbs,
        setBreadcrumbs,
        handleAddTab,
        handleRemoveTab,
      }}
    >
      {children}
    </ContractTabContext.Provider>
  );
};
