import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Input,
  Table,
  Switch,
  Badge,
  Space,
  Pagination,
  Button,
  Tabs,
  Select,
  Checkbox,
  Tag,
  Form,
  message,
  DatePicker,
} from "antd"; //antd components
import { Link, useNavigate } from "react-router-dom";
import {
  EyeOutlined,
  FilterOutlined,
  UpOutlined,
  DownOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  FxAtom,
} from "../../../store/store";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import { createActivityLogApi } from "../../../api/logApi";
import searchIcon from "../../../assets/images/icons/search.svg";
import edit from "../../../assets/images/icons/edit_pen.svg";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import save_filter from "../../../assets/images/icons/save_filter.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import {
  getAllFxTablePaginationApi,
  deleteFXApi,
  getAllFxTableFiltersApi,
  getAllFxTableOnlyApi,
} from "../../../api/fxTableApi";
import { createFiltersApi, getFiltersApi } from "../../../api/usersApi";
import { CreateButton } from "../../GlobalButton";
import moment from "moment";
import dayjs from "dayjs";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import delete_disable from "../../../assets/images/icons/delete_disable.png";
import GlobalFilter from "../../GlobalFilter";
import ActionButton from "../../ActionButton";

function FxSetup() {
  const popupModalInstance = PopupModal();
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  // Variable to use navigate function
  const history = useNavigate();
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [fxName, setFxName] = useRecoilState(FxAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    fxSetupDataTable(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold a all column and style
  const allColumns = [
    {
      title: renderColumnTitle("FX Table Name", "fx_table_name"),
      dataIndex: "fx_table_name",
      align: "left",
      width: 400,
      sorter: true,
      sortOrder: isColumnSorted("fx_table_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Name", "supplier_name"),
      dataIndex: "supplier_name",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("MSA", "msa"),
      dataIndex: "msa",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("msa") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.fx_setup.view ||
          roleUser[0].page_access.system_setup.fx_setup.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columns = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleViewFX = (item) => {
    let ssLog = {
      ss_id: item.id ? item.id : item._id,
      ss_name: item.fx_table_name,
      code: "fx_table",
      author: userData[0].id,
      action: "view",
      notes: "Viewed FX Setup",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get Fx table data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const fxSetupDataTable = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getAllFxTablePaginationApi(page, limit, allFilterVal, sortColumn).then(
      (res) => {
        console.log("fx res", res);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        const fxSetupData = res.docs.map((item, index) => {
          let tableRow = {
            key: item.id ? item.id : item._id,
            fx_table_name: (
              <span
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                      ? "pointer"
                      : "default",
                }}
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                  ) {
                    handleViewFX(item);
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: item,
                    });
                    history(`/contract-setup/view/fx-table`, {
                      state: { data: item, flag: "view" },
                    });
                  }
                }}
              >
                {item.fx_table_name}
              </span>
            ),
            supplier_id: item.supplier_id,
            supplier_name: item.supplier_name ? item.supplier_name : "-",
            msa: item.msa_name ? item.msa_name : "-",
            status: item.status,
            created_on: moment(item.createdAt).format("DD-MMM-YY"),
            created_by: item.created_user_name,
            updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
            updated_by: item.updated_user_name,
            action: (
              <ActionButton
                AccessCheck={
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.fx_setup
                }
                data={item}
                dataStatus={item.status}
                itemName={item.fx_table_name}
                setHeaderChanger={setHeaderChanger}
                headerValue={{
                  header: "systemSetup",
                  headerItem: "2",
                  headerItemName: "systemSetup",
                }}
                menuItem="contract-setup"
                subMenuItem="fx-table"
                deleteInfo={ModalMessage.FX_Delete_Info}
                handleDelete={handleDeleteFx}
                handleView={handleViewFX}
              />
            ),
          };
          return tableRow;
        });
        setTableData(fxSetupData);
        setLoader(false);
      }
    );
  };

  const handleDeleteFx = (fxId, fx_name, inputFXName) => {
    if (inputFXName === fx_name) {
      deleteFXApi(fxId).then((res) => {
        let umLog = {
          ss_id: fxId,
          ss_name: fx_name,
          code: "fx_table",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted Fx Table",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(fx_name, ModalMessage.FX_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              fxSetupDataTable(1, 20, allFilterVal, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.FX_Delete_Error);
    }
  };

  /* Function to handle change the page number
   * @param page -  new page number.
   * @param limit - set a limit of data dor this page
   */
  const onPageChange = (page, limit) => {
    fxSetupDataTable(page, limit, allFilterVal, sortColumnOptions);
  };

  // store ratecard name in global state
  const getAllFx = async () => {
    try {
      const res = await getAllFxTableOnlyApi();
      setFxName(res.map((i) => i.fx_table_name));
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished fetching fx");
    }
  };

  useEffect(() => {
    getAllFx();
  }, []);

  // Function to handle get all Fx table data
  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      fxSetupDataTable(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        fxSetupDataTable(1, 20, allFilterVal, sortColumnOptions);
      } else {
        fxSetupDataTable(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "FX Setup | Judo";
  }, []);

  const onCreateFx = () => {
    history(`/contract-setup/create/fx-table`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.fx_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.fx_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.fx_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"fxTable"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.fx_setup.create && (
            <Col xl={2} md={4} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateFx} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          loading={loader}
          dataSource={tableData}
          columns={columns}
          onChange={handleTableChange}
          showSorterTooltip={false}
          bordered
          size="large "
          scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }}
          pagination={false}
          className="system-setup-table rate-card-table"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            // simple
            // pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default FxSetup;
