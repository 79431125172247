// default import
import { Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import "./assets/style/table.css";
import { PrivateRoutes, PublicRoutes } from "./utils/common";
import URLextractor from "./URLextractor";

// components import
import Reports from "./components/Reports/Reports.js";
import CreateReport from "./components/Reports/CreateReport.js";
import ViewBuildReport from "./components/Reports/ViewBuildReport.js";
import ViewScheduleReport from "./components/Reports/ViewScheduleReport.js";
import EditReport from "./components/Reports/EditReport.js";
import ClauseLibrary from "./components/ContractAuthority/ClauseLibrary/ClauseLibrary.js";
import ViewClauseLibrary from "./components/ContractAuthority/ClauseLibrary/ViewClauseLibrary.js";
import EditClauseLibrary from "./components/ContractAuthority/ClauseLibrary/EditClauseLibrary.js";
import CreateClauseLibrary from "./components/ContractAuthority/ClauseLibrary/CreateClauseLibrary.js";
import Template from "./components/ContractAuthority/Template/Template.js";
import ViewTemplate from "./components/ContractAuthority/Template/ViewTemplate.js";
import EditTemplate from "./components/ContractAuthority/Template/EditTemplate.js";
import CreateTemplate from "./components/ContractAuthority/Template/CreateTemplate.js";
import UploadPreview from "./components/ContractAuthority/Template/UploadPreview.js";
import EditPreview from "./components/ContractAuthority/Template/EditPreview.js";
import OnlinePreview from "./components/ContractAuthority/Template/OnlinePreview.js";
import OnlineTemplate from "./components/ContractAuthority/Template/OnlineTemplate.js";
import PricingProfileController from "./components/ContractSetup/PricingProfile/PricingProfileController.js";
import SupplierSetupController from "./components/OrganizationSetup/SupplierSetup/SupplierSetupController";
import LegalEntityController from "./components/OrganizationSetup/LegalEntitySetup/LegalEntityController";
import RateCardController from "./components/ContractSetup/RateCardSetup/RateCardController";
import InvoicingProfileController from "./components/ContractSetup/InvoicingProfile/InvoicingProfileController.js";
import CostCenterController from "./components/OrganizationSetup/CostCenterSetup/CostCenterController";
import ViewWorkFlow from "./components/ContractSetup/WorkFlow/ViewWorkFlow";
import ExcelWorkFlow from "./components/ContractSetup/WorkFlow/ExcelWorkFlow";
import WorkFlowSetup from "./components/ContractSetup/WorkFlow/WorkFlowSetup";
import OnlineWorkFlow from "./components/ContractSetup/WorkFlow/OnlineWorkFlow";
import CreateWorkFlow from "./components/ContractSetup/WorkFlow/CreateWorkFlow";
import ViewRole from "./components/Roles/ViewRole";
import CreateRoleAccess from "./components/Roles/CreateRoleAccess";
import EditRoleAccess from "./components/Roles/EditRoleAccess";
import ViewGroup from "./components/Groups/ViewGroup";
import EditGroup from "./components/Groups/EditGroup";
import CloneGroup from "./components/Groups/CloneGroup";
import EditUser from "./components/Users/EditUser";
import CreateUser from "./components/Users/CreateUser";
import FlowDiagram from "./components/FlowDiagram.js";
import CustomWorkFlow from "./components/ContractSetup/WorkFlow/CustomWorkFlow.js";
import CreateRootAction from "./components/ActionManagement/RootAction/CreateRootAction.js";
import ActionInstance from "./components/ActionManagement/ActionInstance/ActionInstance.js";
import ViewRootAction from "./components/ActionManagement/RootAction/ViewRootAction.js";
import EditRootAction from "./components/ActionManagement/RootAction/EditRootAction.js";
import CloneRootAction from "./components/ActionManagement/RootAction/CloneRootAction.js";
import ViewActionInstance from "./components/ActionManagement/ActionInstance/ViewActionInstance.js";
import ViewUser from "./components/Users/ViewUser.js";
import CloneRole from "./components/Roles/CloneRole.js";
import FxSetupController from "./components/ContractSetup/FxSetup/FxSetupController.js";

// pages import
import LoginPage from "./pages/Login";
import DashboardPage from "./pages/Dashboard";
import SystemSetup from "./pages/SystemSetup";
import GeneralForm from "./pages/ContractForms/GeneralForm";
import OnlineAuthoring from "./pages/ContractForms/OnlineAuthoring";
import Contract from "./pages/Contract";
import ProfilePriceDetails from "./pages/ProfilePriceDetails";
import Commercial from "./pages/Commercial";
import Obligation from "./pages/Obligation";
import Settings from "./pages/Settings";
import VerificationSuccess from "./pages/VerificationSuccess";
import SetPassword from "./pages/SetPassword";
import ApprovalPage from "./pages/ApprovalPage";
import ContrcatReview from "./pages/ContrcatReview";
import EditGeneralForm from "./pages/ContractForms/EditGeneralForm.js";
import ActionManagement from "./pages/ActionManagement.js";
import InstanceApprovalPage from "./pages/InstanceApprovalPage.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword.js";
import DatabaseSeeder from "./pages/DatabaseSeeder.js";
import CreateContract from "./components/Contract/CreateContract.js";
import ContractController from "./components/Contract/ContractController.js";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" exact element={<LoginPage />} />
          <Route path="url" exact element={<URLextractor />} />
          {/* <Route
            exact
            path="/set-password/:id/:type/:expiry_time"
            element={<SetPassword />}
          />
          <Route
            exact
            path="/verificationSuccess/:id/:expiry_time"
            element={<VerificationSuccess />}
          /> */}
        </Route>
        <Route
          exact
          path="/set-password/:id/:type/:expiry_time"
          element={<SetPassword />}
        />
        <Route
          exact
          path="/verificationSuccess/:id/:expiry_time"
          element={<VerificationSuccess />}
        />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/flow-diagram" element={<FlowDiagram />} />

        <Route element={<PrivateRoutes />}>
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/contract-approval/:contractId/:expiry_time"
            element={<ApprovalPage />}
          />
          <Route
            exact
            path="/action-instance-approval/:instanceId"
            element={<InstanceApprovalPage />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route exact path="/user-dashboard" element={<DashboardPage />} />
          <Route exact path="/contract" element={<Contract />} />
          <Route exact path="/system-setup" element={<SystemSetup />} />
          <Route exact path="/user-management" element={<Settings />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route
            exact
            path="/action-management"
            element={<ActionManagement />}
          />
          <Route
            exact
            path="/price-profile/details/:id"
            element={<ProfilePriceDetails />}
          />
          <Route exact path="/test" element={<ProfilePriceDetails />} />
          <Route exact path="/commercial" element={<Commercial />} />
          <Route exact path="/obligation" element={<Obligation />} />
          <Route
            exact
            path="/contract/create/general"
            element={<GeneralForm />}
          />
          <Route
            exact
            path="/contract/edit/general"
            element={<EditGeneralForm />}
          />
          <Route
            exact
            path="/contract/create/onlineauthoring"
            element={<OnlineAuthoring />}
          />
          <Route
            exact
            path="/contract-review/:contractId/:supplier/:expiry_time"
            element={<ContrcatReview />}
          />
          <Route
            exact
            path="/reports/create/report"
            element={<CreateReport />}
          />
          {/* <Route exact path="/Action/Create" element={<CreateRootAction />} />
          <Route exact path="/ActionInstance" element={<ActionInstance />} />
          <Route
            exact
            path="/viewactioninstance"
            element={<ViewActionInstance />}
          /> */}
          <Route
            exact
            path="/reports/build-report/view/report"
            element={<ViewBuildReport />}
          />
          <Route
            exact
            path="/reports/schedule-report/view/report"
            element={<ViewScheduleReport />}
          />
          <Route exact path="/reports/edit/report" element={<EditReport />} />
          <Route
            exact
            path="/contract-setup/:action/pricing-profile"
            element={<PricingProfileController />}
          />
          <Route
            exact
            path="/contract_authority/clause-library"
            element={<ClauseLibrary />}
          />
          <Route
            exact
            path="/contract_authority/view/clause-library"
            element={<ViewClauseLibrary />}
          />
          <Route
            exact
            path="/contract_authority/edit/clause-library"
            element={<EditClauseLibrary />}
          />
          <Route
            exact
            path="/contract_authority/create/clause-library"
            element={<CreateClauseLibrary />}
          />
          <Route
            exact
            path="/contract_authority/template"
            element={<Template />}
          />
          <Route
            exact
            path="/contract_authority/create/template"
            element={<CreateTemplate />}
          />
          <Route
            exact
            path="/contract_authority/template/upload-preview"
            element={<UploadPreview />}
          />
          <Route
            exact
            path="/contract_authority/template/edit-preview"
            element={<EditPreview />}
          />
          <Route
            exact
            path="/contract_authority/template/online-preview"
            element={<OnlinePreview />}
          />
          <Route
            exact
            path="/contract_authority/online/template"
            element={<OnlineTemplate />}
          />
          <Route
            exact
            path="/contract_authority/view/template"
            element={<ViewTemplate />}
          />
          <Route
            exact
            path="/contract_authority/edit/template"
            element={<EditTemplate />}
          />
          <Route
            exact
            path="/contract-setup/view/workflow"
            element={<ViewWorkFlow />}
          />
          <Route
            exact
            path="/contract-setup/Excel/excel-Work-Flow"
            element={<ExcelWorkFlow />}
          />
          <Route
            exact
            path="/contract-setup/Excel/custom-Work-Flow"
            element={<CustomWorkFlow />}
          />
          <Route
            exact
            path="/contract-setup/WorkFlow/Work-Flow-Setup"
            element={<WorkFlowSetup />}
          />
          <Route
            exact
            path="/contract-setup/Online/online-Work-Flow"
            element={<OnlineWorkFlow />}
          />
          <Route
            exact
            path="/contract-setup/create/create-Work-Flow"
            element={<CreateWorkFlow />}
          />
          <Route
            exact
            path="/contract-setup/:action/fx-table"
            element={<FxSetupController />}
          />
          <Route
            exact
            path="/contract-setup/:action/invoicing-profile"
            element={<InvoicingProfileController />}
          />
          <Route
            exact
            path="/contract-setup/:action/rate-card"
            element={<RateCardController />}
          />
          <Route
            exact
            path="/organization-setup/:action/supplier-setup"
            element={<SupplierSetupController />}
          />
          <Route
            exact
            path="/organization-setup/:action/legal-entity"
            element={<LegalEntityController />}
          />
          <Route
            exact
            path="/organization-setup/:action/cost-center"
            element={<CostCenterController />}
          />
          <Route
            exact
            path="/user-management/roles/view"
            element={<ViewRole />}
          />
          <Route
            exact
            path="/user-management/roles/create-role-access"
            element={<CreateRoleAccess />}
          />
          <Route
            exact
            path="/user-management/roles/clone-role-access"
            element={<CloneRole />}
          />
          <Route
            exact
            path="/user-management/roles/edit-role-access"
            // element={<EditRoleAccessCopy />
            element={<EditRoleAccess />}
          />
          <Route
            exact
            path="/user-management/groups/view"
            element={<ViewGroup />}
          />
          <Route
            exact
            path="/user-management/groups/edit"
            element={<EditGroup />}
          />
          <Route
            exact
            path="/user-management/groups/clone"
            element={<CloneGroup />}
          />
          <Route
            exact
            path="/user-management/user/edit"
            element={<EditUser />}
          />
          <Route
            exact
            path="/user-management/user/view"
            element={<ViewUser />}
          />
          <Route
            exact
            path="/user-management/user/create"
            element={<CreateUser />}
          />
          <Route
            exact
            path="/action-management/root-action/create"
            element={<CreateRootAction />}
          />
          <Route
            exact
            path="/action-management/action-instance"
            element={<ActionInstance />}
          />
          <Route
            exact
            path="/action-management/view-action"
            element={<ViewRootAction />}
          />
          <Route
            exact
            path="/action-management/edit-action"
            element={<EditRootAction />}
          />
          <Route
            exact
            path="/action-management/clone-action"
            element={<CloneRootAction />}
          />
          <Route
            exact
            path="/action-management/view-action-instance"
            element={<ViewActionInstance />}
          />
          <Route exact path="/test/playground" element={<DatabaseSeeder />} />
          <Route exact path="/create/contract" element={<CreateContract />} />
          <Route
            exact
            path="/contract/:action/form"
            element={<ContractController />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
