import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Layout,
  Table,
  Popconfirm,
  Checkbox,
  Tabs,
} from "antd"; //antd components
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "../../assets/style/setup.css";
import "../../assets/style/settings.css";
import { createActivityLogApi } from "../../api/logApi";
import TopHeader from "../../components/Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, userDataAtom } from "../../store/store";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import { suppliersTreeApi } from "../../api/suppliersApi";
import { createRoleAPi } from "../../api/rolesApi";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  DeleteButton,
  AddButton,
} from "../GlobalButton";
import {
  getAllApprovedContractsWoMsa,
  getByMsa,
  getBySupplierWoMsa,
} from "../../api/contractsApi";
import { getAllMsaApi } from "../../api/msaApi";
import { getUsersByRole } from "../../api/usersApi";
import CustomLoader from "../CustomLoader";
import { associationCol } from "../../utils/tableColumns";
import HeaderCard from "../HeaderCard";

function CloneRole() {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom); //global state
  const [addForm] = Form.useForm();
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Variable to hold the header local data
  let header = headerChanger.header;
  // State variable to hold key of local component
  const [tabkey, setKey] = useState("1");
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold local form values
  let { state } = useLocation();
  const [stateButton, setStateButton] = useState(1);
  // Assign the header values into state variable
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  const [form] = Form.useForm();
  console.log("state", state);
  // State avriable to hold role name
  const [rolename, setRoleName] = useState(state.role_name);
  // State variable to hold role description
  const [roledescription, setRoleDescription] = useState(
    state.role_description
  );
  // State variable to hold screen loading screen visibility status
  const [loader, setLoader] = useState(false);
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [associationData, setAssociationData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [allSupplierData, setAllSupplierData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [entityData, setEntityData] = useState({
    supplier: [],
    msa: [],
    contract: [],
  });
  const [addedData, setAddedData] = useState([]);
  const [selectedData, setSelectedData] = useState({
    supplier: [],
    msa: [],
    contract: [],
  });
  const [selectedAcc, setSelectedAcc] = useState({
    supplier: state.supplier,
    msa: state.msa,
    contract: state.contract,
  });
  const [dataAccessLoader, setDataAccessLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const updatedData =
        state && state.entity_rights && state.entity_rights.length > 0
          ? await Promise.all(
              state.entity_rights.map(async (sup) => {
                let msaOption = [];
                let contractOption = [];
                const msaS = sup.msa.map((m) => m.msa) || [];

                await getAllMsaApi().then((res) => {
                  let filteredMsa = res.filter(
                    (data) => sup.supplier === data.supplier_id
                  );
                  msaOption =
                    filteredMsa.length > 0
                      ? [
                          {
                            id: "All",
                            contract_name: "All",
                          },
                          ...filteredMsa,
                        ]
                      : [];
                });

                if (msaS.length > 0) {
                  await getByMsa(msaS.join(",")).then((res) => {
                    let child = [];
                    res &&
                      res.length > 0 &&
                      res.map((par) => child.push(...par.children));
                    contractOption =
                      child.length > 0
                        ? [{ id: "All", contract_name: "All" }, ...child]
                        : [];
                  });
                } else {
                  contractOption = [];
                }

                return {
                  ...sup,
                  msa_options: msaOption,
                  contract_options: contractOption,
                };
              })
            )
          : [];

      console.log("updateddata", updatedData);

      const supplierMaster = await suppliersTreeApi(userData[0].id);
      let filteredSup = supplierMaster
        .map((data) => data)
        .filter((supplier) => {
          return !(
            state.entity_rights &&
            state.entity_rights.length > 0 &&
            state.entity_rights.some(
              (entity) => entity.supplier === supplier._id
            )
          );
        });

      filteredSup =
        filteredSup && filteredSup.length > 0
          ? filteredSup.map((data) => ({
              supplier: data.id,
              supplier_name: data.supplier_name,
              msa_options: [],
              contract_options: [],
              msa: [],
              contract: [],
            }))
          : [];

      console.log("filteredSup", filteredSup);

      const finalUpdatedData =
        state &&
        state.entity_rights &&
        state.entity_rights.length > 0 &&
        state.entity_rights.some((ele) => ele.supplier === "All")
          ? [...updatedData, ...filteredSup]
          : updatedData;
      console.log("finalUpdatedData", finalUpdatedData);
      setAddedData(finalUpdatedData);
    };

    fetchData();
  }, [state]);

  const handleDelete = (supId) => {
    try {
      setDataAccessLoader(true);
      let deletedSup = allSupplierData.filter(
        (data) => (data.id ? data.id : data._id) === supId
      );
      setSupplierData([...supplierData, ...deletedSup]);
      setAddedData(
        addedData.filter(
          (data) => data.supplier !== supId && data.supplier !== "All"
        )
      );
      setDataAccessLoader(false);
    } catch (error) {
      console.error("Error occurred:", error);
      setDataAccessLoader(false);
    }
  };

  const handleAdd = async () => {
    try {
      setDataAccessLoader(true);
      let supIds = entityData.supplier.map((data) => data.id);
      setSupplierData(
        supplierData.filter(
          (sup) => !supIds.includes(sup.id ? sup.id : sup._id)
        )
      );

      // Map through selected suppliers to gather corresponding contract and msa values
      const output = [];
      await Promise.all(
        entityData.supplier.map(async (sup) => {
          let msaOption = [];
          let contractOption = [];
          const msaS =
            entityData.msa
              .filter((m) => m.supplier === sup.id)
              ?.map((m) => m.msa) || [];

          await getAllMsaApi().then((res) => {
            let filteredMsa = res.filter((data) => sup.id === data.supplier_id);
            msaOption =
              filteredMsa.length > 0
                ? [
                    {
                      id: "All",
                      contract_name: "All",
                    },
                    ...filteredMsa,
                  ]
                : [];
          });

          if (msaS.length > 0) {
            await getByMsa(msaS.join(",")).then((res) => {
              let child = [];
              res &&
                res.length > 0 &&
                res.map((par) => child.push(...par.children));
              contractOption =
                child.length > 0
                  ? [{ id: "All", contract_name: "All" }, ...child]
                  : [];
            });
          } else {
            contractOption = [];
          }

          const supplierContracts =
            entityData.contract
              ?.filter((c) => c.supplier === sup.id)
              ?.map((c) => c) || [];
          const supplierMsas =
            entityData.msa
              ?.filter((m) => m.supplier === sup.id)
              ?.map((m) => m) || [];

          output.push({
            supplier: sup.id,
            supplier_name: sup.supplier_name,
            msa: supplierMsas,
            contract: supplierContracts,
            msa_options: msaOption,
            contract_options: contractOption,
            contractMode: entityData.contract.some(
              (ele) => ele.contract === "All"
            )
              ? "All"
              : supplierContracts.map((data) => data.contract),
            msaMode: entityData.msa.some((ele) => ele.msa === "All")
              ? "All"
              : supplierMsas.map((data) => data.msa),
          });
        })
      );
      console.log("output :", output);
      setAddedData([...addedData, ...output]);
      setContractData([]);
      setMsaData([]);
      setSelectedData({
        supplier: [],
        msa: [],
        contract: [],
      });
      setEntityData({
        supplier: [],
        msa: [],
        contract: [],
      });
      addForm.resetFields(); // This will clear all fields in the form
      addForm.setFieldsValue({ contract: null });
      addForm.setFieldsValue({ msa: null });
      setDataAccessLoader(false);
    } catch (error) {
      console.error("Error occurred:", error);
      setDataAccessLoader(false);
    }
  };

  // State variable to hold checked the screen access
  const [checked, setChecked] = useState({
    // entity_rights: {
    //   create: state.data_access === "All access" ? true : false,
    //   view: state.data_access === "All access" ? true : false,
    //   action: state.data_access === "All access" ? true : false,
    // },
    contracts: {
      contract_tree: {
        view: state && state.page_access.contracts.contract_tree.view,
      },
      view_contract: {
        view: state && state.page_access.contracts.view_contract.view,
      },
      contract_pipeline: {
        view: state && state.page_access.contracts.contract_pipeline.view,
      },
      create_contract: {
        view: state && state.page_access.contracts.create_contract.view,
        create: state && state.page_access.contracts.create_contract.create,
      },
    },
    system_setup: {
      supplier_setup: {
        view: state && state.page_access.system_setup.supplier_setup.view,
        create: state && state.page_access.system_setup.supplier_setup.create,
      },
      legal_entity_setup: {
        view: state && state.page_access.system_setup.legal_entity_setup.view,
        create:
          state && state.page_access.system_setup.legal_entity_setup.create,
      },
      cost_center_setup: {
        view: state && state.page_access.system_setup.cost_center_setup.view,
        create:
          state && state.page_access.system_setup.cost_center_setup.create,
      },
      ratecard_setup: {
        view: state && state.page_access.system_setup.ratecard_setup.view,
        create: state && state.page_access.system_setup.ratecard_setup.create,
      },
      fx_setup: {
        view: state && state.page_access.system_setup.fx_setup.view,
        create: state && state.page_access.system_setup.fx_setup.create,
      },
      indexation: {
        view: state && state.page_access.system_setup.indexation.view,
        create: state && state.page_access.system_setup.indexation.create,
      },
      pricing_profile: {
        view: state && state.page_access.system_setup.pricing_profile.view,
        create: state && state.page_access.system_setup.pricing_profile.create,
      },
      invoicing_profile: {
        view: state && state.page_access.system_setup.invoicing_profile.view,
        create:
          state && state.page_access.system_setup.invoicing_profile.create,
      },
      workflow: {
        view: state && state.page_access.system_setup.workflow.view,
        create: state && state.page_access.system_setup.workflow.create,
      },
    },
    contract_authority: {
      clause_library: {
        view: state && state.page_access.contract_authority.clause_library.view,
        create:
          state && state.page_access.contract_authority.clause_library.create,
      },
      templates: {
        view: state && state.page_access.contract_authority.templates.view,
        create: state && state.page_access.contract_authority.templates.create,
      },
    },
    action_management: {
      root_action: {
        view: state && state.page_access.action_management.root_action.view,
        create: state && state.page_access.action_management.root_action.create,
      },
      action_instance: {
        view: state && state.page_access.action_management.action_instance.view,
        create:
          state && state.page_access.action_management.action_instance.create,
      },
    },
    reports: {
      build_report: {
        view: state && state.page_access.reports.build_report.view,
        create: state && state.page_access.reports.build_report.create,
      },
      scheduled_report: {
        view: state && state.page_access.reports.scheduled_report.view,
        create: state && state.page_access.reports.scheduled_report.create,
      },
    },
  });
  const [disableAddButton, setDisableAddButton] = useState(true);
  // variabel hold the button visibility css
  let disableVal = "disable-btn";
  // State variable to hold button visibility status
  const [disable, setDisable] = useState(disableVal);
  /* Function to handle change the data access
   * @Param key - a hold data access for all access or assigned only
   */
  const onCheckAll = (e) => {
    setChecked({
      contracts: {
        contract_tree: {
          view: e.target.checked,
        },
        view_contract: {
          view: e.target.checked,
        },
        contract_pipeline: {
          view: e.target.checked,
        },
        create_contract: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      system_setup: {
        supplier_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        legal_entity_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        cost_center_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        ratecard_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        fx_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        indexation: {
          view: e.target.checked,
          create: e.target.checked,
        },
        pricing_profile: {
          view: e.target.checked,
          create: e.target.checked,
        },
        invoicing_profile: {
          view: e.target.checked,
          create: e.target.checked,
        },
        workflow: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      contract_authority: {
        clause_library: {
          view: e.target.checked,
          create: e.target.checked,
        },
        templates: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      action_management: {
        root_action: {
          view: e.target.checked,
          create: e.target.checked,
        },
        action_instance: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      reports: {
        build_report: {
          view: e.target.checked,
          create: e.target.checked,
        },
        scheduled_report: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
    });
  };

  /* Function to handle onChange event of check and uncheck the screen access
   * @Param type - a value to screen assess in main catogrey
   * @Param assess - a screen name which is going to be checked or unchecked
   * Param value - a boolean value to check or uncheck the screen access
   */
  const onCheckAccess = (menu, access, type, value) => {
    // console.log("checked value", value);
    // console.log("type", type);
    // console.log("access", access);
    if (type === "create" && value && !checked[menu][access]["view"]) {
      setChecked((prevState) => ({
        ...prevState,
        [menu]: {
          ...prevState[menu],
          [access]: {
            view: true,
            create: true,
          },
        },
      }));
    } else {
      setChecked((prevState) => ({
        ...prevState,
        [menu]: {
          ...prevState[menu],
          [access]: {
            ...prevState[menu][access],
            [type]: value,
          },
        },
      }));
    }
  };

  const onChangeSupplier = (key, value) => {
    setDisableAddButton(true);
    console.log("supplier key", key);
    console.log("supplier value", value);
    form.setFieldsValue({ msa: null });
    setSelectedData({
      ...selectedData,
      supplier: key,
      msa: [],
    });
    setSelectedAcc({
      ...selectedAcc,
      supplier: [...selectedAcc.supplier, ...key],
    });
    setEntityData({
      ...entityData,
      supplier: key.includes("All")
        ? supplierData.map((val) => {
            return {
              id: val.id ? val.id : val._id,
              supplier_name: val.supplier_name,
            };
          })
        : value.map((val) => {
            return {
              id: val.key,
              supplier_name: val.label,
            };
          }),
    });
    if (key.length > 0) {
      if (key.includes("All")) {
        getAllMsaApi().then((res) => {
          let allMsaData =
            res.length > 0 ? [{ id: "All", contract_name: "All" }, ...res] : [];
          setMsaData(allMsaData);
          // setMsaData({
          //   ...msaData,
          //   [count]: allMsaData,
          // });
        });
      } else {
        getAllMsaApi().then((res) => {
          let filteredMsa = res.filter((data) =>
            key.includes(data.supplier_id)
          );
          filteredMsa =
            filteredMsa.length > 0
              ? [
                  {
                    id: "All",
                    contract_name: "All",
                  },
                  ...filteredMsa,
                ]
              : [];
          setMsaData(filteredMsa);
          // setMsaData({
          //   ...msaData,
          //   [count]: filteredMsa,
          // });
        });
      }
    } else {
      setMsaData([]);
      // setMsaData({
      //   ...msaData,
      //   [count]: [{ id: "All", contract_name: "All" }, ...key],
      // });
    }
  };

  const onChangeMsa = (key, value) => {
    console.log("msa key", key);
    console.log("msa value", value);
    form.setFieldsValue({ contract: null });
    setSelectedData({
      ...selectedData,
      msa: key,
      contract: [],
    });
    setSelectedAcc({
      ...selectedAcc,
      msa: [...selectedAcc.msa, ...key],
    });
    setEntityData({
      ...entityData,
      msa: key.includes("All")
        ? msaData.map((val) => {
            return {
              msa: val.id ? val.id : val._id,
              supplier: val.supplier_id,
              msa_name: val.contract_name,
            };
          })
        : value.map((val) => {
            return {
              msa: val.key,
              supplier: val.supplier,
              msa_name: val.label,
            };
          }),
    });
    if (key.length > 0) {
      if (key.includes("All")) {
        if (selectedData.supplier.includes("All")) {
          getAllApprovedContractsWoMsa().then((res) => {
            console.log("all contracts", res);
            setContractData(
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : []
            );
            // setContractData({
            //   ...contractData,
            //   [count]: [{ id: "All", contract_name: "All" }, ...res],
            // });
          });
        } else {
          getBySupplierWoMsa(selectedData.supplier.join(",")).then((res) => {
            console.log("Contract - get by supplier", res);
            setContractData(
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : []
            );
            // setContractData({
            //   ...contractData,
            //   [count]: [{ id: "All", contract_name: "All" }, ...res],
            // });
          });
        }
      } else {
        getByMsa(key.join(",")).then((res) => {
          console.log("Contract - get by msa", res);
          let child = [];
          res &&
            res.length > 0 &&
            res.map((par) => child.push(...par.children));
          setContractData(
            child.length > 0
              ? [{ id: "All", contract_name: "All" }, ...child]
              : []
          );
          // setContractData({
          //   ...contractData,
          //   [count]: [{ id: "All", contract_name: "All" }, ...child],
          // });
        });
      }
    } else {
      setContractData([]);
      // setContractData({
      //   ...contractData,
      //   [count]: [{ id: "All", contract_name: "All" }, ...key],
      // });
    }
  };

  const onChangeContract = (key, value) => {
    console.log("contract key", key);
    console.log("contract value", value);
    setSelectedData({
      ...selectedData,
      contract: key,
    });
    setSelectedAcc({
      ...selectedAcc,
      contract: [...selectedAcc.contract, ...key],
    });
    setEntityData({
      ...entityData,
      contract: key.includes("All")
        ? contractData.map((val) => {
            return {
              contract: val.id ? val.id : val._id,
              supplier: val.supplier_id,
              contract_name: val.contract_name,
            };
          })
        : value.map((val) => {
            return {
              contract: val.key,
              supplier: val.supplier,
              contract_name: val.label,
            };
          }),
    });
  };

  useEffect(() => {
    suppliersTreeApi(userData[0].id).then((res) => {
      let supplierMaster = res.map((data) => {
        return data;
      });
      supplierMaster = [
        {
          _id: "All",
          supplier_name: "All",
        },
        ...supplierMaster,
      ];
      const filteredSupplierMaster =
        state &&
        state.entity_rights &&
        state.entity_rights.length > 0 &&
        state.entity_rights.some((ele) => ele.supplier === "All")
          ? []
          : supplierMaster.filter((supplier) => {
              return !(
                state.entity_rights &&
                state.entity_rights.length > 0 &&
                state.entity_rights.some(
                  (entity) => entity.supplier === supplier._id
                )
              );
            });
      console.log("supplierMaster", supplierMaster);
      console.log("filteredSupplierMaster", filteredSupplierMaster);
      setSupplierData(filteredSupplierMaster);
      setAllSupplierData(supplierMaster);
    });
  }, []);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const onChangeAddedMsa = (key, value, supplier) => {
    console.log("msa key", key);
    console.log("msa value", value);

    const updatedMsa = addedData.map(async (obj) => {
      if (obj.supplier === supplier) {
        let contractOption = [];
        if (key.length > 0) {
          if (key.includes("All")) {
            const res = await getBySupplierWoMsa(supplier);
            console.log("Contract - get by supplier", res);
            contractOption =
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : [];
          } else {
            const res = await getByMsa(key.join(","));
            console.log("Contract - get by msa", res);
            let child = [];
            res &&
              res.length > 0 &&
              res.map((par) => child.push(...par.children));
            contractOption =
              child.length > 0
                ? [{ id: "All", contract_name: "All" }, ...child]
                : [];
          }
        } else {
          contractOption = [];
        }
        return {
          ...obj,
          msaMode: key.includes("All") ? "All" : key,
          msa: key.includes("All")
            ? obj.msa_options.map((val) => {
                return {
                  msa: val._id ? val._id : val.id,
                  supplier: val.supplier_id,
                  msa_name: val.contract_name,
                };
              })
            : value.map((val) => {
                return {
                  msa: val.key,
                  supplier: val.supplier,
                  msa_name: val.label,
                };
              }),
          contract_options: contractOption,
        };
      } else {
        return obj;
      }
    });

    // Wait for all promises to resolve before updating state
    Promise.all(updatedMsa).then((updatedData) => {
      setAddedData(updatedData);
    });
  };

  const onChangeAddedContract = (key, value, supplier) => {
    console.log("contract key", key);
    console.log("contract value", value);

    const updatedContract = addedData.map((obj) => {
      if (obj.supplier === supplier) {
        return {
          ...obj,
          contractMode: key.includes("All") ? "All" : key,
          contract: key.includes("All")
            ? obj.contract_options.map((val) => {
                return {
                  contract: val._id ? val._id : val.id,
                  supplier: val.supplier_id,
                  contract_name: val.contract_name,
                };
              })
            : value.map((val) => {
                return {
                  contract: val.key,
                  supplier: val.supplier,
                  contract_name: val.label,
                };
              }),
        };
      } else {
        return obj;
      }
    });
    setAddedData(updatedContract);
  };

  // Function to handle the return of the data from the API
  const roleDetails = () => {
    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="legal-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="role_name"
                    label="ROLE NAME"
                    className="org-supplier-label"
                    colon={false}
                    rules={[
                      {
                        message: "Please enter Role Name!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="" className="" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="role_description"
                    label="ROLE DESCRIPTION"
                    className="org-supplier-label"
                    colon={false}
                    rules={[
                      {
                        message: "Please enter Role Description!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="" className="" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  // Function to return the page access for Contrct and System setup
  const pageAccess = () => {
    return (
      <>
        {/* <div className="create-supplier-div"> */}
        <Row>
          <Col span={24}>
            <Checkbox
              // className="float-right"
              onChange={onCheckAll}
            >
              <b>Select All</b>
            </Checkbox>
          </Col>
        </Row>
        {/* </div> */}
        <div className="create-supplier-div mt-20">
          <Row>
            <Col span={24}>
              <div className="create-user-entity-div">
                <h4 className="access-title">Contracts</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Contract Tree</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   checked.contracts.contract_tree.create
                                  // }
                                  checked={checked.contracts.contract_tree.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "contract_tree",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>View Contract</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={checked.contracts.view_contract.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "view_contract",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Contract Pipeline</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.contracts.contract_pipeline.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "contract_pipeline",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Create Contract</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.contracts.create_contract.create
                                  }
                                  checked={
                                    checked.contracts.create_contract.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "create_contract",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.contracts.create_contract.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "create_contract",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Contract Authority</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Clause Library</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.contract_authority.clause_library
                                      .create
                                  }
                                  checked={
                                    checked.contract_authority.clause_library
                                      .view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "clause_library",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.contract_authority.clause_library
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "clause_library",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Templates</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.contract_authority.templates.create
                                  }
                                  checked={
                                    checked.contract_authority.templates.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "templates",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.contract_authority.templates.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "templates",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Action Management</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Root Action</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.action_management.root_action.create
                                  }
                                  checked={
                                    checked.action_management.root_action.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "root_action",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.action_management.root_action.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "root_action",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Action Instance</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.action_management.action_instance
                                      .create
                                  }
                                  checked={
                                    checked.action_management.action_instance
                                      .view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "action_instance",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.action_management.action_instance
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "action_instance",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Reports</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Build Report</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={checked.reports.build_report.create}
                                  checked={checked.reports.build_report.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "build_report",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={checked.reports.build_report.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "build_report",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Scheduled Report</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.reports.scheduled_report.create
                                  }
                                  checked={
                                    checked.reports.scheduled_report.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "scheduled_report",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.reports.scheduled_report.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "scheduled_report",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">System Setup</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Supplier Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.supplier_setup.create
                                  }
                                  checked={
                                    checked.system_setup.supplier_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "supplier_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.supplier_setup.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "supplier_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Legal Entity Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.legal_entity_setup
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.legal_entity_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "legal_entity_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.legal_entity_setup
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "legal_entity_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Cost Center Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.cost_center_setup
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.cost_center_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "cost_center_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.cost_center_setup
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "cost_center_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Rate Card Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.ratecard_setup.create
                                  }
                                  checked={
                                    checked.system_setup.ratecard_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "ratecard_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.ratecard_setup.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "ratecard_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Fx Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.fx_setup.create
                                  }
                                  checked={checked.system_setup.fx_setup.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "fx_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={checked.system_setup.fx_setup.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "fx_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Indexation</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.indexation.create
                                  }
                                  checked={checked.system_setup.indexation.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "indexation",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.indexation.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "indexation",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Pricing Profile</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.pricing_profile.create
                                  }
                                  checked={
                                    checked.system_setup.pricing_profile.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "pricing_profile",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.pricing_profile.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "pricing_profile",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Invoicing Profile</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.invoicing_profile
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.invoicing_profile.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "invoicing_profile",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={
                                    checked.system_setup.invoicing_profile
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "invoicing_profile",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Workflow</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  disabled={
                                    checked.system_setup.workflow.create
                                  }
                                  checked={checked.system_setup.workflow.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "workflow",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  checked={checked.system_setup.workflow.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "workflow",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  // Function to return the entity access for create, view and action
  const dataAccess = () => {
    return (
      <div className="create-supplier-div">
        <Row>
          <Col span={24}>
            <div className="create-user-entity-div">
              <h4 className="access-title">Select Data</h4>
              {!dataAccessLoader ? (
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0 mt-30"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form Form={addForm}>
                        <div className="create-roles-div p-20">
                          <Col span={24} className="add-data-col">
                            <Row align="middle" type="flex" gutter={[24, 16]}>
                              <Col span={8}>
                                <Form.Item
                                  name="supplier"
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  label="SUPPLIER"
                                  colon={false}
                                >
                                  <Select
                                    value={selectedData.supplier}
                                    mode="multiple"
                                    showSearch={true}
                                    maxTagCount="responsive"
                                    placeholder="Select Supplier"
                                    allowClear={true}
                                    onChange={(key, value) =>
                                      onChangeSupplier(key, value)
                                    }
                                    options={
                                      supplierData &&
                                      supplierData.length > 0 &&
                                      supplierData.map((data) => ({
                                        key: data._id ? data._id : data.id,
                                        label: data.supplier_name,
                                        value: data._id ? data._id : data.id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="msa"
                                  label="MSA"
                                  colon={false}
                                >
                                  <Select
                                    value={selectedData.msa}
                                    mode="multiple"
                                    allowClear
                                    showSearch={true}
                                    placeholder="Choose MSA"
                                    onChange={(key, value) =>
                                      onChangeMsa(key, value)
                                    }
                                    maxTagCount="responsive"
                                    options={
                                      msaData &&
                                      msaData.length > 0 &&
                                      msaData.map((data) => ({
                                        key: data._id ? data._id : data.id,
                                        label: data.contract_name,
                                        value: data._id ? data._id : data.id,
                                        supplier: data.supplier_id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="contract"
                                  label="CONTRACT"
                                  colon={false}
                                >
                                  <Select
                                    value={selectedData.contract}
                                    allowClear
                                    mode="multiple"
                                    showSearch={true}
                                    placeholder="Choose Contract"
                                    onChange={(key, value) =>
                                      onChangeContract(key, value)
                                    }
                                    maxTagCount="responsive"
                                    options={
                                      contractData &&
                                      contractData.length > 0 &&
                                      contractData.map((data) => ({
                                        key: data._id ? data._id : data.id,
                                        label: data.contract_name,
                                        value: data._id ? data._id : data.id,
                                        supplier: data.supplier_id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <Row>
                          <Col
                            span={24}
                            align="center"
                            className="vertical-align"
                          >
                            <Col span={2}>
                              <AddButton
                                onClick={handleAdd}
                                disabled={disableAddButton}
                              />
                            </Col>
                          </Col>
                        </Row>

                        {addedData &&
                          addedData.length > 0 &&
                          addedData.map((added) => {
                            // console.log("addedd on return", added);
                            if (added.supplier !== "All") {
                              return (
                                <Row
                                  align="middle"
                                  type="flex"
                                  gutter={[4, 16]}
                                  className="m-0 mt-10"
                                  key={added.supplier}
                                >
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="added-div">
                                      <Col span={24} className="added-data-col">
                                        <Row
                                          align="middle"
                                          type="flex"
                                          gutter={[24, 16]}
                                        >
                                          <Col span={7}>
                                            <h5>SUPPLIER</h5>
                                            <Input
                                              className="ellipsis-input mt-10"
                                              defaultValue={added.supplier_name}
                                              readOnly
                                            />
                                          </Col>

                                          <Col span={7}>
                                            <h5>MSA</h5>
                                            <Select
                                              className="added-select mt-10"
                                              defaultValue={added.msa.map(
                                                (val) => val.msa
                                              )}
                                              mode="multiple"
                                              allowClear
                                              showSearch={true}
                                              placeholder="Choose MSA"
                                              onChange={(key, value) =>
                                                onChangeAddedMsa(
                                                  key,
                                                  value,
                                                  added.supplier
                                                )
                                              }
                                              maxTagCount="responsive"
                                              options={
                                                added.msa_options &&
                                                added.msa_options.length > 0 &&
                                                added.msa_options.map(
                                                  (data) => ({
                                                    key: data._id
                                                      ? data._id
                                                      : data.id,
                                                    label: data.contract_name,
                                                    value: data._id
                                                      ? data._id
                                                      : data.id,
                                                    supplier: data.supplier_id,
                                                  })
                                                )
                                              }
                                            ></Select>
                                          </Col>

                                          <Col span={7}>
                                            <h5>CONTRACT</h5>
                                            <Select
                                              className="added-select mt-10"
                                              defaultValue={added.contract.map(
                                                (val) => val.contract
                                              )}
                                              mode="multiple"
                                              allowClear
                                              showSearch={true}
                                              placeholder="Choose Contract"
                                              onChange={(key, value) =>
                                                onChangeAddedContract(
                                                  key,
                                                  value,
                                                  added.supplier
                                                )
                                              }
                                              maxTagCount="responsive"
                                              options={
                                                added.contract_options &&
                                                added.contract_options.length >
                                                  0 &&
                                                added.contract_options.map(
                                                  (data) => ({
                                                    key: data._id
                                                      ? data._id
                                                      : data.id,
                                                    label: data.contract_name,
                                                    value: data._id
                                                      ? data._id
                                                      : data.id,
                                                    supplier: data.supplier_id,
                                                  })
                                                )
                                              }
                                            ></Select>
                                          </Col>

                                          <Col span={3}>
                                            <Popconfirm
                                              title="Sure to delete?"
                                              onConfirm={() =>
                                                handleDelete(added.supplier)
                                              }
                                            >
                                              <a>
                                                <DeleteButton />
                                              </a>
                                            </Popconfirm>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                      </Form>
                    </Col>
                  </Row>
                </div>
              ) : (
                <CustomLoader />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  // Variable to hold a two components of page access and entity access
  const items = [
    {
      key: "1",
      label: `Role Details`,
      children: roleDetails(),
    },
    {
      key: "2",
      label: `Page Access`,
      children: pageAccess(),
    },
    {
      key: "3",
      label: `Data Access`,
      children: dataAccess(),
    },
  ];
  // Function to handle the create Role form data stored
  const onFinish = async (values) => {
    let data = {
      role_name: values.role_name,
      role_description: values.role_description,
      // data_access: values.data_access,
      supplier: addedData.some((data) => data.supplier === "All")
        ? "All"
        : "Not All",
      msa:
        addedData.some((data) => data.supplier === "All") &&
        addedData.every((data) => data.msaMode === "All")
          ? "All"
          : "Not All",
      contract:
        addedData.some((data) => data.supplier === "All") &&
        addedData.every((data) => data.msaMode === "All") &&
        addedData.every((data) => data.contractMode === "All")
          ? "All"
          : "Not All",
      page_access: {
        contracts: checked.contracts,
        contract_authority: checked.contract_authority,
        action_management: checked.action_management,
        reports: checked.reports,
        system_setup: checked.system_setup,
      },
      entity_rights: addedData.map((item) => {
        const newItem = {
          supplier: item.supplier,
          msa: item.msa.map((msaItem) => ({
            msa: msaItem.msa,
            supplier: msaItem.supplier,
          })),
          contract: item.contract.map((contractItem) => ({
            contract: contractItem.contract,
            supplier: contractItem.supplier,
          })),
          contractMode: item.contractMode,
          msaMode: item.msaMode,
        };
        return newItem;
      }),
      status: stateButton === 1 ? "In Draft" : "Active",
      created_by: userData[0].id,
      updated_by: userData[0].id,
      created_on: new Date(),
      updated_on: new Date(),
    };
    console.log("clone data", data);

    if (stateButton === 1) {
      setSaveTableLoader(true);
      await createRoleAPi(data).then((res) => {
        let umLog = {
          ss_id: res.id ? res.id : res._id,
          ss_name: res.role_name,
          code: "roles",
          author: userData[0].id,
          action: "draft",
          notes: "Saved New Role",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog).then((res) => {});
        setSaveTableLoader(false);
        popupModalInstance.successModal(
          state.role_name,
          ModalMessage.Role_Save,
          `/user-management/?back=set-roles`
        );
      });
    }
    if (stateButton === 2) {
      setLoader(true);
      popupModalInstance
        .infoModal(ModalMessage.Role_Create)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            createRoleAPi(data).then((res) => {
              let umLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: res.role_name,
                code: "roles",
                author: userData[0].id,
                action: "create",
                notes: "Created New Role",
                updated_on: new Date(),
              };
              createActivityLogApi(umLog).then((res) => {});
              setLoader(false);
              navigate(`/user-management/?back=set-roles`);
            });
          }
          if (userChoice === "cancel") {
            setLoader(false);
          }
        });
    }
  };
  /* Function to handle the create user get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout>
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      <div className="system-setup1">
        <Form
          className="mt-10 create-role"
          autoComplete="off"
          initialValues={state && state}
          onFinish={onFinish}
          onKeyDown={handleKeyDown}
          onFinishFailed={onFinishFailed}
        >
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={20}
              md={21}
              lg={21}
              xl={18}
            >
              <HeaderCard
                mainMenu="User Management"
                subMenu="Roles"
                itemName={"Clone Role"}
                // itemStatus={state.status}
                goBackLink="/user-management/?back=set-roles"
                // state={""}
                // showDetails={"clone"}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <CancelButton onClick={() => navigate(`/user-management`)} />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SaveButton
                type="primary"
                onClick={() => setStateButton(1)}
                htmlType="submit"
                loading={loader}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SubmitButton
                type="primary"
                onClick={() => setStateButton(2)}
                htmlType="submit"
                loading={loader}
              />
            </Col>
          </Row>
          <div className="scrolling-content">
            <Tabs items={items} className="m-15" />
          </div>
        </Form>
      </div>
    </Layout>
  );
}

export default CloneRole;
