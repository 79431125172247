import React, { useState, useEffect } from "react";
import "../assets/style/globalfilter.css";
import {
  Button,
  Popover,
  Row,
  Col,
  Image,
  Tabs,
  Select,
  Badge,
  DatePicker,
  message,
  Checkbox,
  Input,
  Space,
  Tag,
  Form,
  List,
} from "antd";
import {
  FilterOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { userDataAtom } from "../store/store";
import { useRecoilState } from "recoil";
import searchIcon from "../assets/images/icons/search.svg";
import {
  createFiltersApi,
  getFiltersApi,
  getAllUserFiltersApi,
} from "../api/usersApi";
import {
  getContractListFiltersApi,
  getContractPipelineFiltersApi,
} from "../api/contractsApi";
import {
  getAllClauseLibraryFiltersApi,
  getAllTemplateFiltersApi,
} from "../api/contractAuthorityApi";
import {
  getAllMySpaceFiltersApi,
  getSearchInstanceFiltersApi,
  getAllInstanceFiltersApi,
} from "../api/actionInstanceApi";
import { getAllSupplierFiltersApi } from "../api/suppliersApi";
import { getAllLegalFiltersApi } from "../api/legalEntityApi";
import { getAllCostFiltersApi } from "../api/costCenterApi";
import { getAllRateCardFiltersApi } from "../api/ratecardApi";
import { getAllFxTableFiltersApi } from "../api/fxTableApi";
import { getAllPricingProfileFiltersApi } from "../api/pricingProfileApi";
import { getAllInvoicingFiltersApi } from "../api/invoicingProfileApi";
import { getAllWorkflowFiltersApi } from "../api/workflowApi";
import {
  getAllBuildReportFiltersApi,
  getAllScheduleReportFiltersApi,
} from "../api/reportApi";
import { getAllRoleFiltersApi } from "../api/rolesApi";
import { getAllGroupFiltersApi } from "../api/groupsApi";
import { SaveButton } from "./GlobalButton";

const GlobalFilter = ({
  selectedTab, // Stores the tab name for which filters need to be rendered
  initialTableData, // Stores the table data from the selected tab
  allFilterVal, // Stores the selected filter values
  setAllFilterVal, // Stores the function to update the filter values when changes
}) => {
  const userData = useRecoilState(userDataAtom); // State variable to store user data
  const [globalFilterTab, setGlobalFilterTab] = useState("custom"); // State variable to store which filter tab is currently selected
  const [savedFilters, setSavedFilters] = useState([]); // State variable to hold saved filter conditions
  const [innerFilterShow, setInnerFilterShow] = useState(); // State variable to hold inner filter data
  const [savedFilterShow, setSavedFilterShow] = useState(false); // State variable to hold save filter visibility status
  const [pubPvt, setPubPvt] = useState("public"); // State variable to hold filter category for public & private
  const [filterDynamic, setFilterDynamic] = useState([]); // State variable to hold dynamic filter data
  const [filterSearchVal, setFilterSearchVal] = useState("null"); // State variable to hold search filter data
  const [showSaveIcon, setShowSaveIcon] = useState(false); // State variable to hold save icon visibility status
  const [filterCount, setFilterCount] = useState(0); // State variable to hold all legal entity data
  const [searchQuery, setSearchQuery] = useState(""); // State variable to store the search query from the search input box
  const [messageApi, saveMessageContextHolder] = message.useMessage(); // State variable to hold message when saving filters
  const CheckboxGroup = Checkbox.Group; // Variable to store Checkbox Group type component
  const { RangePicker } = DatePicker; //Variable which enables DatePicker component to transform into RangePicker

  // Stores the map of filter configuration for the selected tab
  const filterConfig = {
    contractList: {
      api: getContractListFiltersApi,
      main_menu: "contract_list_filter_data",
      sub_menu: "contract_list_filter",
    },
    contractPipeline: {
      api: getContractPipelineFiltersApi,
      main_menu: null,
      sub_menu: "pipeline_filter_data",
    },
    clauseLibrary: {
      api: getAllClauseLibraryFiltersApi,
      main_menu: "contract_authority_filter_data",
      sub_menu: "clause_library_filter",
    },
    template: {
      api: getAllTemplateFiltersApi,
      main_menu: "contract_authority_template_filter_data",
      sub_menu: "template_filter_data",
    },
    mySpace: {
      api: getAllMySpaceFiltersApi,
      main_menu: "action_management_filter_data",
      sub_menu: "my_space_filter",
    },
    searchInstance: {
      api: getSearchInstanceFiltersApi,
      main_menu: "action_management_filter_data",
      sub_menu: "action_search_filter",
    },
    instance: {
      api: getAllInstanceFiltersApi,
      main_menu: "action_management_filter_data_view",
      sub_menu: "view_filter",
    },
    supplier: {
      api: getAllSupplierFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "supplier_setup_filter",
    },
    legalEntity: {
      api: getAllLegalFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "legal_entity_filter",
    },
    costCenter: {
      api: getAllCostFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "cost_center_filter",
    },
    rateCard: {
      api: getAllRateCardFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "rate_card_filter",
    },
    fxTable: {
      api: getAllFxTableFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "fx_setup_filter",
    },
    pricingProfile: {
      api: getAllPricingProfileFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "pricing_profile_filter",
    },
    invoicingProfile: {
      api: getAllInvoicingFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "invoicing_profile_filter",
    },
    workflow: {
      api: getAllWorkflowFiltersApi,
      main_menu: "system_setup_filter_data",
      sub_menu: "workflow_setup_filter",
    },
    buildReport: {
      api: getAllBuildReportFiltersApi,
      main_menu: "report_filter_data",
      sub_menu: "build_report_filter",
    },
    scheduleReport: {
      api: getAllScheduleReportFiltersApi,
      main_menu: "report_filter_data",
      sub_menu: "schedule_report_filter",
    },
    roles: {
      api: getAllRoleFiltersApi,
      main_menu: "user_management_filter_data",
      sub_menu: "role_filter",
    },
    users: {
      api: getAllUserFiltersApi,
      main_menu: "user_management_filter_data",
      sub_menu: "user_filter",
    },
    groups: {
      api: getAllGroupFiltersApi,
      main_menu: "user_management_filter_data",
      sub_menu: "group_filter",
    },
  };

  const mainMenuFilterKey = filterConfig[selectedTab].main_menu; // Stores the main menu key to access the fetched filter data
  const savedFilterKey = filterConfig[selectedTab].sub_menu; // Stores the sub menu key to access the fetched filter data

  // Fetches the selected tab filters and saved filters
  useEffect(() => {
    const getSelectedTabFilters = async () => {
      if (initialTableData?.length !== 0 && filterConfig[selectedTab].api) {
        const selectedTabFilterApi = filterConfig[selectedTab].api;
        const savedFiltersFromApi = await selectedTabFilterApi(
          selectedTab === "contractPipeline" || selectedTab === "mySpace"
            ? userData[0].id || userData[0]._id
            : ""
        );
        setFilterDynamic(savedFiltersFromApi);
      }
    };
    getSelectedTabFilters();
    getAllSavedFilters();
  }, [initialTableData]);

  // Function to handle selecting/deselecting the filter options to filter the table data
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      setShowSaveIcon(false);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        setShowSaveIcon(false);
      } else {
        setShowSaveIcon(true);
      }
    }

    setFilterCount(
      Object.keys(allFilterVal || {}).filter(
        (a) => allFilterVal[`${a}`].length !== 0
      ).length
    );
  }, [allFilterVal]);

  /* Function to handle to search the saved filters
   * @param e - the selected filter from dropdown
   */
  const onSearchFilterVal = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    if (searchValue === "") {
      setFilterSearchVal("null");
    } else {
      setFilterSearchVal(searchValue);
    }
  };

  /* Function to handle select filter
   * @param key - Contains label and key of the selected option
   * @param value - Contains value of the selected option
   */
  const onCloseTag = (key, value) => {
    let emptyFilVal = {
      ...allFilterVal,
      [`${key}`]: allFilterVal[`${key}`].filter((val) => val.key !== value),
    };
    Object.keys(emptyFilVal).forEach((key) => {
      if (emptyFilVal[key].length === 0) {
        delete emptyFilVal[key];
      }
    });
    setAllFilterVal(emptyFilVal);
  };

  /* Function to handle the create lagal entity form data stored
   * @param values - a user create form values
   */
  const onSave = async (values) => {
    const data = {
      filter_type: values.filter_type,
      filter_name: values.filter_name,
      filter_values: allFilterVal,
    };
    await createFiltersApi({
      created_by: userData[0].id,
      [mainMenuFilterKey !== null
        ? mainMenuFilterKey
        : filterConfig.contractPipeline.sub_menu]:
        mainMenuFilterKey !== null ? { [savedFilterKey]: [data] } : data,
      created_on: new Date(),
      updated_on: new Date(),
    }).then((res) => {
      messageApi.open({
        type: "success",
        content: (
          <span>
            <span style={{ fontWeight: 600 }}>{values.filter_name} </span>
            {values.filter_type} filter has been saved successfully
          </span>
        ),
      });
      getAllSavedFilters();
    });
  };

  // Function to get a all filter details from database
  const getAllSavedFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        let savedFilterData = [];
        if (mainMenuFilterKey) {
          savedFilterData =
            res &&
            res.length !== 0 &&
            res
              .flatMap((item) => item?.[mainMenuFilterKey]?.[savedFilterKey])
              .filter((n) => n);
        } else {
          savedFilterData =
            res &&
            res.length !== 0 &&
            res.flatMap((item) => item?.[savedFilterKey]).filter((n) => n);
        }
        if (savedFilterData) {
          setSavedFilters(savedFilterData);
        } else {
          setSavedFilters([]);
        }
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  // Stores the filter options to render as list items
  const colFilterVal =
    filterDynamic && filterDynamic.length !== 0
      ? filterDynamic.map((item) => ({
          value: item.name,
          label: item.name,
        }))
      : [];

  const filteredSavedFilters =
    savedFilters.length !== 0
      ? savedFilters
          .filter((x) => x.filter_type === pubPvt)
          .filter((item) =>
            item.filter_name.toLowerCase().includes(searchQuery)
          )
          .map((item, i) => ({
            label: item.filter_name,
            value: i,
          }))
      : [];

  // Save dialog box UI
  const SaveDialogBox = (
    <>
      <Row>
        <Col span={24} className="mb-10">
          <span
            style={{
              fontWeight: 600,
              fontSize: 13,
              color: "var(--color-solid-darkergrey)",
            }}
          >
            Save Filter
          </span>
        </Col>
      </Row>
      <Form onFinish={onSave}>
        <Row justify="center">
          <Col span={24}>
            <Form.Item
              name="filter_type"
              className="mb-10"
              rules={[
                {
                  required: true,
                  message: "Please Select Filter Type!",
                },
              ]}
            >
              <Select
                placeholder="Select Filter Type"
                options={[
                  {
                    value: "public",
                    label: "Public",
                  },
                  {
                    value: "private",
                    label: "Private",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="filter_name"
              className="mb-15"
              rules={[
                {
                  required: true,
                  message: "Please Enter Filter Name!",
                },
              ]}
            >
              <Input placeholder="Filter Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <SaveButton htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </>
  );

  //Filter title UI
  const FilterTitle = ({ title }) => {
    return (
      <div className="global-inner-filter-title">
        <span style={{ textTransform: "capitalize" }}>{title}</span>
        <CloseCircleOutlined
          style={{
            fontSize: 17,
            color: "var(--color-border)",
          }}
          onClick={() => {
            setInnerFilterShow(null);
            setGlobalFilterTab("custom");
          }}
        />
      </div>
    );
  };

  // Filter content list UI
  const GlobalFilterContent = (
    <>
      <Tabs
        className="global-filter-tab"
        defaultActiveKey={globalFilterTab}
        items={[
          {
            key: "custom",
            label: "Custom Filters",
          },
          {
            key: "saved",
            label: "Saved Filter",
          },
        ]}
        onChange={(key) => setGlobalFilterTab(key)}
      />

      {globalFilterTab === "custom" && (
        <List
          className="global-filter-list"
          bordered={false}
          dataSource={colFilterVal}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                setInnerFilterShow(item.value);
                setGlobalFilterTab("custom_inner");
              }}
            >
              {item.label}
            </List.Item>
          )}
        />
      )}

      {globalFilterTab === "saved" && (
        <>
          <Tabs
            className="global-filter-tab-inner"
            defaultActiveKey={pubPvt}
            items={[
              {
                key: "public",
                label: `Public`,
              },
              {
                key: "private",
                label: `Private`,
              },
            ]}
            onChange={(key) => setPubPvt(key)}
          />
          <Input
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            placeholder="Search"
            suffix={
              <Image
                preview={false}
                className="search-icon"
                src={searchIcon}
                width={30}
              />
            }
            className="pp-search-input mb-10"
          />
          <List
            id="saved-filter-list"
            className="global-filter-list"
            dataSource={filteredSavedFilters}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  setSavedFilterShow(!savedFilterShow);
                  setAllFilterVal(
                    savedFilters
                      .filter((data) => data.filter_name === item.label)
                      .map((v) => v.filter_values)[0]
                  );
                }}
              >
                {item.label}
              </List.Item>
            )}
          />
        </>
      )}

      {globalFilterTab === "custom_inner" &&
        filterDynamic &&
        filterDynamic.length !== 0 &&
        filterDynamic.map((item, index) => {
          if (
            innerFilterShow === "Updated On" ||
            innerFilterShow === "Created On" ||
            innerFilterShow === "Contract Start Date" ||
            innerFilterShow === "Contract End Date" ||
            innerFilterShow === "Signed On"
          ) {
            if (index === 0) {
              return (
                <div className="filter-inner-div-1">
                  <FilterTitle title={innerFilterShow} />
                  <RangePicker
                    className="range-picker"
                    format="DD MMM YYYY"
                    value={
                      Object.keys(allFilterVal || {}).includes(
                        innerFilterShow
                      ) &&
                      allFilterVal[innerFilterShow] &&
                      allFilterVal[innerFilterShow].length !== 0 &&
                      allFilterVal[innerFilterShow][0].key.map((date) =>
                        dayjs(date, "DD MMM YYYY")
                      )
                    }
                    onChange={(moment, val) => {
                      if (moment != null) {
                        const updatedFilterVal = {
                          ...allFilterVal,
                          [innerFilterShow]: [
                            {
                              key: val,
                              value: val,
                            },
                          ],
                        };
                        setAllFilterVal(updatedFilterVal);
                      } else if (moment === null) {
                        setAllFilterVal((prevState) => ({
                          ...prevState,
                          [innerFilterShow]: [],
                        }));
                      }
                    }}
                  />
                </div>
              );
            }
          } else {
            return (
              <>
                {innerFilterShow === item.name && (
                  <div className="global-individual-filter">
                    <FilterTitle title={innerFilterShow} />
                    <Input
                      onChange={onSearchFilterVal}
                      placeholder="Search"
                      suffix={
                        <Image
                          preview={false}
                          className="search-icon"
                          src={searchIcon}
                          width={30}
                        />
                      }
                      className="pp-search-input mt-5"
                    />
                    <CheckboxGroup
                      className="global-filter-checkbox"
                      value={
                        Object.keys(allFilterVal || {}).includes(item.name) &&
                        allFilterVal[item.name] &&
                        allFilterVal[item.name].length !== 0 &&
                        allFilterVal[item.name].map((data) => data.key)
                      }
                      options={
                        filterSearchVal === "null"
                          ? item.filterValues.map((val) => ({
                              label: val.label ? val.label : val.value,
                              value: val.key,
                              key: val.key,
                            }))
                          : item.filterValues
                              .filter(
                                (data) =>
                                  JSON.stringify(data)
                                    .toLowerCase()
                                    .indexOf(filterSearchVal.toLowerCase()) !==
                                  -1
                              )
                              .map((val) => ({
                                label: val.label ? val.label : val.value,
                                value: val.key,
                                key: val.key,
                              }))
                      }
                      onChange={(val) => {
                        let data = [];
                        val.map((key) => {
                          item.filterValues
                            .filter((option) => key === option.key)
                            .map((obj) => {
                              data.push(obj);
                            });
                        });
                        const updatedFilterVal = {
                          ...allFilterVal,
                          [item.name]: data,
                        };
                        setAllFilterVal(updatedFilterVal);
                      }}
                    />
                  </div>
                )}
              </>
            );
          }
        })}
    </>
  );

  return (
    <>
      {saveMessageContextHolder}
      <div className="filter-white-space-head">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={1}
          lg={1}
          xl={1}
          style={{ background: "white" }}
        >
          {initialTableData?.length !== 0 ? (
            <Popover
              overlayClassName="filter-popup-inner"
              trigger={"click"}
              arrow={false}
              placement="bottomLeft"
              content={GlobalFilterContent}
            >
              <Badge count={filterCount}>
                <FilterOutlined className="cursor-pointer filter-image" />
              </Badge>
            </Popover>
          ) : (
            <FilterOutlined className="cursor-pointer filter-image" />
          )}
        </Col>
        <Space
          className="filter-white-space"
          style={{ width: "90%" }}
          size={[0, 8]}
        >
          {Object.keys(allFilterVal || {}).length !== 0 ? (
            Object.keys(allFilterVal || {}).map((filkey) => {
              if (allFilterVal[filkey] && allFilterVal[filkey].length !== 0) {
                return allFilterVal[filkey].map((fildata) => (
                  <div key={`${filkey}-${fildata.key}`}>
                    <Tag key={`${filkey}-${fildata.key}`}>
                      <span style={{ textTransform: "capitalize" }}>
                        {filkey}:{" "}
                        {filkey === "Updated On" ||
                        filkey === "Created On" ||
                        filkey === "Contract Start Date" ||
                        filkey === "Contract End Date" ||
                        filkey === "Signed On"
                          ? `${fildata.value[0]} - ${fildata.value[1]}`
                          : fildata.label
                          ? fildata.label
                          : fildata.value}
                      </span>
                      <span
                        onClick={() => onCloseTag(filkey, fildata.key)}
                        className="cursor-pointer"
                        style={{ paddingLeft: "5px" }}
                      >
                        <CloseCircleOutlined />
                      </span>
                    </Tag>
                  </div>
                ));
              }
            })
          ) : (
            <p className="placeholder-text">Add Filters</p>
          )}
        </Space>
        <Space
          className="filter-white-space-icons"
          style={{ width: "10%" }}
          size={[0, 8]}
        >
          {showSaveIcon && (
            <>
              <Popover
                overlayClassName="filter-popup-saved-inner"
                trigger={"click"}
                arrow={false}
                placement="bottomRight"
                content={SaveDialogBox}
              >
                <SaveOutlined />
              </Popover>

              <CloseOutlined
                className="cursor-pointer"
                onClick={() => {
                  setAllFilterVal({});
                }}
              />
            </>
          )}
        </Space>
      </div>
    </>
  );
};

export default GlobalFilter;
