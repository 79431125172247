module.exports = {
  CP_Edit: "",
  CC_Save: "Contract saved as Draft",
  CC_Submit_Warnimg: "Please fill all mandatory fields before submitting.",
  CC_Commercial_Max_Month:
    "Days adjusted to maximum calender days for subsequent months. Please check the number of days for all months before submitting. ",
  CC_Commercial_Start_Date:
    "Days mismatch - Number of days in starting month exceeds the maximum possible days as per Contract Start date.",
  CC_Commercial_End_Date:
    "Days mismatch - Number of days in ending month exceeds the maximum possible days as per Contract End date.",
  CC_Commercial_Max_Calender:
    "Days cannot exceed the maximum calender days in a month.",
  CC_Commercial_Day_Period: "Entered value must be a whole number.",
  CC_Commercial_FTE_Period:
    "For FTE Rate Basis, entered value must be a whole number or two digit decimal number.",
  CC_FTE_limit: "For FTE Rate Basis the input must be 99 or less.",
  CC_Submit: "Do you wish to submit the Contract?",
  Report_Save: "Report Setup Updated.",
  Report_Schedule_Later: "Report Scheduled.",
  Report_Edit: "Report Setup saved.",
  Report_Name_Same: "Report Name already existed..",
  Role_Create: "Do you wish to create the Role?",
  Role_Save: "Role saved as Draft",
  Role_Edit: "Do you wish to Update the Role?",
  Role_Delete_Info: "Please enter the Role Name to confirm deactivation.",
  Role_Delete_Success: "Role ID set to Inactive",
  Role_Delete_Error: "Incorrect User Name",
  User_Edit: "User Details Updated.",
  User_Create: "Do you wish to create the User?",
  User_Create_Error: "Email ID exists. User ID not created.",
  User_Inactive_Create:
    "An Inactive User account exists with the Email ID provided.  Do you want to reactivate the User ID? ",
  User_Delete_Info: "Please enter the User Name to confirm deactivation.",
  User_Delete_Success: "User ID set to Inactive",
  User_Delete_Error: "Incorrect User Name",
  Group_Create_Error: "Email ID does not exist",
  Group_Create: "Do you wish to create the Group?",
  Group_Update: "Do you wish to update the Group details?",
  Group_Delete_Info: "Please enter Group Name to confirm deactivation.",
  Group_Delete_Success: "Group set to Inactive",
  Group_Delete_Error: "Incorrect Group Name",
  SS_Create_Save: "Supplier Details saved as Draft.",
  SS_Create_Submit: "Do you wish to create the Supplier?",
  SS_Edit_Submit: "Do you wish to submit the Supplier Details?",
  SS_Clone_Save: "Supplier Details saved.",
  SS_Clone_Submit: "Do you wish to clone the selected Supplier?",
  SS_Excel_Upload_Error:
    "Upload Unsuccessful. Uploaded Excel file does not match the required format.",
  LE_Create_Save: "Legal Entity Details saved as Draft.",
  LE_Create_Submit: "Do you wish to create the Legal Entity?",
  LE_Edit_Submit: "Do you wish to submit the Legal Entity Details?",
  LE_Clone_Save: "Legal Entity Details saved.",
  LE_Clone_Submit: "Do you wish to clone the selected Legal Entity?",
  CC_Create_Save: "Cost Center Details saved as Draft.",
  CC_Create_Submit: "Do you wish to create the Cost Center?",
  CC_Edit_Submit: "Do you wish to submit the Cost Center Details?",
  CC_Clone_Save: "Cost Center Details saved.",
  CC_Clone_Submit: "Do you wish to clone the selected Cost Center?",
  RC_Create_Save: "Rate Card Details saved as Draft.",
  RC_Create_Submit: "Do you wish the create the Rate Card?",
  RC_Edit_Submit: "Do you wish to submit the Rate Card Details?",
  RC_Clone_Save: "Rate Card Details saved.",
  RC_Clone_Submit: "Do you wish to clone the selected Rate Card?",
  RC_Excel_Upload_Error:
    "Upload Unsuccessful. Uploaded Excel file does not match the required format.",
  PP_Create_Save: "Pricing Profile Details saved as Draft.",
  PP_Create_Submit: "Do you wish to create the Pricing Profile?",
  PP_Edit_Submit: "Do you wish to submit the Pricing Profile Details?",
  PP_Clone_Save: "Pricing Profile Details saved.",
  PP_Clone_Submit: "Do you wish to clone the selected Pricing Profile?",
  FX_Create_Save: "FX Details saved as Draft.",
  FX_Create_Submit: "Do you wish to create the FX?",
  FX_Edit_Submit: "Do you wish to submit the FX Details?",
  FX_Clone_Save: "FX Details Saved.",
  FX_Clone_Submit: "Do you wish to clone the selected FX?",
  FX_Excel_Upload_Error:
    "Upload Unsuccessful. Uploaded Excel file does not match the required format.",
  Invoice_Create_Save: "Invoice Details saved as Draft.",
  Invoice_Create_Submit: "Do you wish to create the Invoice?",
  Invoice_Edit_Submit: "Do you wish to submit the Invoice Details?",
  Invoice_Clone_Save: "Invoice Details saved.",
  Invoice_Clone_Submit: "Do you wish to clone the selected Invoice?",
  Invoice_Excel_Upload_Error:
    "Upload Unsuccessful. Uploaded Excel file does not match the required format.",
  Invoice_Excel_FE_Mismatched:
    "Upload Unsuccessful. Incorrect 'From Entity' details.",
  Invoice_Excel_TE_Mismatched:
    "Upload Unsuccessful. Incorrect 'To Entity' details.",
  Invoice_Excel_Percentage_Mismatched:
    "Upload Unsuccessful. Total Percentage must add up to 100.",
  Invoice_Excel_Name_Mismatched:
    "Upload Unsuccessful. Invoice Name details incorrect.",
  Invoice_Percentage_Mismatched: "Total Percentage must add up to 100.",
  Workflow_Create_Error: "Workflow exists with same name",
  Supplier_Create_Error: "Supplier exists with same name",
  LegalEntity_Create_Error: "LegalEntity exists with same name",
  CostCenter_Create_Error: "CostCenter exists with same name",
  RateCard_Create_Error: "RateCard exists with same name",
  Fx_Create_Error: "Fx exists with same name",
  Pricing_Profile_Create_Error: "PricingProfile exists with same name",
  Invoicing_Profile_Create_Error: "InvoicingProfile exists with same name",
  Group_Create_Error: "Group exists with same name",
  Workflow_Save: "Workflow saved as Draft",
  Workflow_Submit: "Do you wish to create the Workflow?",
  Workflow_Excel_Upload_Error:
    "Upload Unsuccessful. Uploaded Excel file does not match the required format.",
  RA_Create_Save: "Root Action Details saved as Draft.",
  RA_Create_Submit: "Do you wish to create the Root Action?",
  RA_Edit_Save: "Root Action Updated and Details saved as Draft.",
  RA_Edit_Submit: "Do you wish to update the Root Action?",
  RA_Clone_Save: "Root Action cloned and saved as Draft.",
  RA_Clone_Submit: "Do you wish to clone the Root Action?",
  CAC_Temp_Info: "Do you wish to upload the Template File?",
  CL_Create_Save: "Clause Library Details saved as Draft.",
  CL_Create_Submit: "Do you wish to create the Clause Library?",
  CL_Edit_Submit: "Do you wish to update the Existing Data?",
  CL_Edit_Info: "Updated successfully",
  CL_Delete_Info:
    "Please enter the Clause Library Header Name to confirm delete.",
  CL_Delete_Error: "Incorrect Header Name",
  CL_Delete_Success: "Deleted successfully",
  Cl_Name_Warning: "Please enter unique Clause content name the name is exits",
  AI_Update: "Action marked and saved.",
  CA_TE_Save: "Contract Template saved successfully",
  CA_TE_Update: "Contract Template Updated successfully",
  CA_TE_Submit: "Contract Template submitted successfully",
  TEM_Delete_Info: "Please enter the Template Name to confirm delete.",
  TEM_Delete_Error: "Incorrect Template Name",
  TEM_Delete_Success: "Deleted successfully",
  TEM_Edit_Info: "Updated successfully",
  TEM_Edit_Submit: "Do you wish to update the Existing Data?",
  TEM_Create_Submit: "Do you wish to Create the Template?",
  LE_Status_Change:
    "This Legal Entity has been associated with one or more Cost Center. Please reassign the Cost Center to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Cost Center will retain their current Legal Entity assignment",
  LE_Status_Change_Confirm:
    "You are attempting to change the status of this Legal Entity. Note that no associations currently exist for this Legal Entity. If you proceed to change the status, this Legal Entity will be ineligible for any future associations. Are you sure you want to proceed?",
  LE_Status_Change_To_Active:
    "You are attempting to change the status of this Legal Entity to 'Active'. Are you sure you want to proceed?",
  LE_Delete_Info: "Please enter the Legal Entity Name to confirm delete.",
  LE_Delete_Error: "Incorrect Legal Entity Name",
  LE_Delete_Success: "Deleted successfully",
  Supplier_Delete_Info: "Please enter the supplier Name to confirm delete.",
  Supplier_Delete_Error: "Incorrect supplier Name",
  Supplier_Delete_Success: "Deleted successfully",
  Supplier_Status_Change:
    "This Supplier has been associated with one or more Contract and Contract Setup. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Contracts and Contract Setup will retain their current Supplier assignment",
  Supplier_Status_Change_Confirm:
    "You are attempting to change the status of this supplier. Note that no associations currently exist for this supplier. If you proceed to change the status, this Supplier will be ineligible for any future associations. Are you sure you want to proceed?",
  Supplier_Status_Change_To_Active:
    "You are attempting to change the status of this supplier to 'Active'. Are you sure you want to proceed?",
  PP_Status_Change:
    "This Pricing Profile has been associated with one or more contracts. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Contracts will retain their current Pricing Profile assignment",
  PP_Status_Change_Confirm:
    "You are attempting to change the status of this Pricing Profile. Note that no associations currently exist for this Pricing Profile. If you proceed to change the status, this Pricing Profile will be ineligible for any future associations. Are you sure you want to proceed?",

  PP_Status_Change_To_Active:
    "You are attempting to change the status of this Pricing Profile to 'Active'. Are you sure you want to proceed?",
  PP_Delete_Info: "Please enter the Pricing Profile Name to confirm delete.",
  PP_Delete_Error: "Incorrect Pricing Profile Name",
  PP_Delete_Success: "Deleted successfully",
  IP_Status_Change:
    "This Invoicing Profile has been associated with one or more Contracts. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Contracts will retain their current Invoicing Profile assignment",
  IP_Status_Change_Confirm:
    "You are attempting to change the status of this Invoicing Profile. Note that no associations currently exist for this Invoicing Profile. If you proceed to change the status, this Invoicing Profile will be ineligible for any future associations. Are you sure you want to proceed?",
  IP_Status_Change_To_Active:
    "You are attempting to change the status of this Invoicing Profile to 'Active'. Are you sure you want to proceed?",
  IP_Delete_Info: "Please enter the Invoicing Profile Name to confirm delete.",
  IP_Delete_Error: "Incorrect Invoicing Profile Name",
  IP_Delete_Success: "Deleted successfully",
  RC_Status_Change:
    "This Ratecard has been associated with one or more Contracts. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Contracts will retain their current Ratecard assignment",
  RC_Status_Change_Confirm:
    "You are attempting to change the status of this Ratecard. Note that no associations currently exist for this Ratecard. If you proceed to change the status, this Ratecard will be ineligible for any future associations. Are you sure you want to proceed?",
  RC_Status_Change_To_Active:
    "You are attempting to change the status of this Ratecard to 'Active'. Are you sure you want to proceed?",
  RC_Delete_Info: "Please enter the Ratecard Name to confirm delete.",
  RC_Delete_Error: "Incorrect Ratecard Name",
  RC_Delete_Success: "Deleted successfully",
  FX_Status_Change:
    "This FX Table has been associated with one or more Pricing Profile. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Pricing Profile will retain their current FX Table assignment",
  FX_Status_Change_Confirm:
    "You are attempting to change the status of this FX Table. Note that no associations currently exist for this FX Table. If you proceed to change the status, this FX Table will be ineligible for any future associations. Are you sure you want to proceed?",
  FX_Status_Change_To_Active:
    "You are attempting to change the status of this Fx Table to 'Active'. Are you sure you want to proceed?",
  FX_Delete_Info: "Please enter the FX Table Name to confirm delete.",
  FX_Delete_Error: "Incorrect FX Table Name",
  FX_Delete_Success: "Deleted successfully",
  CC_Status_Change:
    "This Cost Center has been associated with one or more Invoicing Profile. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Invoicing Profile will retain their current Cost Center assignment",
  CC_Status_Change_Confirm:
    "You are attempting to change the status of this Cost Center. Note that no associations currently exist for this Cost Center. If you proceed to change the status, this Cost Center will be ineligible for any future associations. Are you sure you want to proceed?",
  CC_Status_Change_To_Active:
    "You are attempting to change the status of this Cost Center to 'Active'. Are you sure you want to proceed?",
  CC_Delete_Info: "Please enter the Cost Center Name to confirm delete.",
  CC_Delete_Error: "Incorrect Cost Center Name",
  CC_Delete_Success: "Deleted successfully",
  WF_Delete_Info: "Please enter the Workflow Name to confirm delete.",
  WF_Delete_Error: "Incorrect Workflow Name",
  WF_Delete_Success: "Deleted successfully",
  User_Status_Change:
    "This User has been associated with one or more Groups and Workflow. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Groups and Workflow will retain their current User assignment",
  User_Status_Change_Confirm:
    "You are attempting to change the status of this User. Note that no associations currently exist for this User. If you proceed to change the status, this User will be ineligible for any future associations. Are you sure you want to proceed?",
  User_Status_Change_To_Active:
    "You are attempting to change the status of this User to 'Active'. Are you sure you want to proceed?",
  User_Delete_Warning:
    "Before proceeding with the deletion, kindly reassign the user's group associations. To review the associations, please click on the user view icon in the table for detailed information.",
  Role_Status_Change:
    "This Role has been associated with one or more Users. Please reassign that to another option before proceeding with the status change. If you wish to continue and change the status regardless, please click 'OK'. Note that any associated Users will retain their current Role assignment",
  Role_Status_Change_Confirm:
    "You are attempting to change the status of this Role. Note that no associations currently exist for this Role. If you proceed to change the status, this Role will be ineligible for any future associations. Are you sure you want to proceed?",
  Role_Status_Change_To_Active:
    "You are attempting to change the status of this Role to 'Active'. Are you sure you want to proceed?",
  Access_Denied_Warning: "You are not Authorized to access this page.",
  User_Not_Exits: "This Email user is not exits",
  Group_Not_Exits: "This Group Name is not exits",
  Author_Change_Info:
    "You are attempting to change the author of this contract. Are you sure you want to proceed?",
  Author_Change_Success: "Contract Author has been changed.",
  Contract_Approve: "Contract has been approved",
  Contract_Reject: "Contract has been rejected",
  Contract_Return: "Contract has been returned",

  // Error Code
  CON_FORM: {
    ERR_Init: `[CON_FORM] EC001: Error initializing Contract Form`,
    ERR_ComTable: `[CON_FORM] EC002: Error saving Contract`,
    ERR_DataPrep: `[CON_FORM] EC003: Error saving Contract`,
    ERR_ConApiErr: `[CON_FORM] EC004: Error saving Contract`,
  },
};
