import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Input, Image, Upload, message, Button, Modal } from "antd"; //antd components
import {
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import attach from "../assets/images/icons/attach.svg";
import send from "../assets/images/icons/send.svg";
import deleteIcon from "../assets/images/icons/delete.svg";
import delete_disable from "../assets/images/icons/delete_disable.png";
import "../assets/style/contractfile.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import arrow_left from "../assets/images/icons/arrow_left.svg";
import DataTable from "./Tables/DataTable";
import moment from "moment";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../store/store";
import { downloadFileApi, getFileApi } from "../api/commonApi";
import { FullscreenOutlined } from "@ant-design/icons"; //antd icons
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const NotesAndComments = ({
  detailData,
  comments,
  url,
  setUrl,
  setFile,
  setFileKeysToDelete,
  setComments,
  pipelinePath = false,
  flag,
  setDisable = false,
}) => {
  // Variable to hold logged user data
  const userData = useRecoilState(userDataAtom);
  // state for store entered comment
  const [commentEntered, setCommentEntered] = useState("");
  // state for store already existing file data
  const [fileOldData, setFileOldData] = useState([]);
  // state for store old comments
  const [oldComments, setOldComments] = useState([]);
  // state for store uploaded file data
  const [notesTableData, setNotesTableData] = useState([]);
  const [viewedFile, setViewedFile] = useState(false);
  const notesCommentsDataRef = useRef(notesTableData);
  const [modalVisible, setModalVisible] = useState(false);
  const [CommentMode, setCommentAction] = useState("");
  const [editorDelta, setEditorDelta] = useState();
  const handleEditorChange = (content, delta, source, editor) => {
    setEditorDelta(editor.getContents());
  };

  const openModal = () => {
    setModalVisible(true);
    setCommentAction("create");
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  useEffect(() => {
    notesCommentsDataRef.current = notesTableData;
  }, [notesTableData]);

  const nDColumns = [
    {
      title: "Document Name",
      dataIndex: "document_name",
      align: "left",
    },
    {
      title: "Doc Uploaded On",
      dataIndex: "uploaded_on",
      align: "left",
    },
    {
      title: "Doc Uploaded By",
      dataIndex: "uploaded_by",
      align: "left",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
    },
  ];

  const viewFile = async (fileData, upload_period) => {
    console.log("fileData", fileData);

    const handleOldUploadPeriod = async () => {
      try {
        const res = await getFileApi({
          fileKey: fileData.file_name_on_s3
            ? fileData.file_name_on_s3
            : fileData.file_content,
          flag: flag,
        });
        console.log("resss", res);
        setUrl([
          {
            uri: res.url,
            fileType: res.fileType,
            fileName: fileData.file_name,
          },
        ]);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };

    const handleNewUploadPeriod = () => {
      const getDataByViewTab = () => {
        const dataRef = notesCommentsDataRef.current;

        return dataRef.find(
          (data) => fileData.file_name === data.document_name.props.children
        );
      };

      const clickedFile = getDataByViewTab();
      if (clickedFile && clickedFile.file_content) {
        const fileUrl = URL.createObjectURL(clickedFile.file_content);
        const fileName = clickedFile.document_name.props.children;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

        setUrl([
          {
            uri: fileUrl,
            fileType,
            fileName,
            newUpload: true,
          },
        ]);
      } else {
        console.log("No file content found for the clicked file.");
      }
    };

    if (upload_period === "old") {
      await handleOldUploadPeriod();
    } else if (upload_period === "new") {
      handleNewUploadPeriod();
    }
  };

  // Download already uploaded file from S3 Bucket
  const onDownloadFile = async (fileKey, filename) => {
    console.log("filename", filename);
    try {
      const response = await downloadFileApi({
        fileKey: fileKey,
        flag: flag,
      });
      console.log("response file", response);
      // Create a temporary anchor element and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      ); // Specify content type
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // download uploaded file
  const onDownloadNewFile = async (fileInfo) => {
    console.log("fileInfo", fileInfo);
    // Extract necessary information
    const { name, originFileObj } = fileInfo;

    // Create a blob from the file object
    const blob = new Blob([originFileObj], { type: originFileObj.type });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = name;

    // Programmatically trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  const viewFileFromNewChat = (fileName) => {
    let fileData =
      notesTableData &&
      notesTableData.length > 0 &&
      notesTableData.filter((data) => data.file_name === fileName);
  };

  // Fucntion call for set document and notes details
  useEffect(() => {
    if (
      detailData &&
      detailData.file_notes_data &&
      detailData.file_notes_data.length !== 0
    ) {
      const fileData = detailData.file_notes_data.map((item) => {
        let tableRow = {
          document_name: (
            <span
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
              }}
              onClick={() => {}}
            >
              {item.file_name}
            </span>
          ),
          doc_uploaded_on: moment(item.uploaded_on).format("DD MMM YYYY"),
          doc_uploader_name: item.author_name,
          doc_uploaded_by: item.uploaded_by,
          file_name_on_s3: item.file_name_on_s3,
        };
        return tableRow;
      });
      // console.log("fileData*****", fileData);

      if (fileData && fileData.length > 0) {
        setFileOldData(
          fileData.sort(
            (a, b) => new Date(a.doc_uploaded_on) - new Date(b.doc_uploaded_on)
          )
        );
      }

      setNotesTableData(
        detailData.file_notes_data
          .map((comment, index) => {
            if (comment.file_name_on_s3) {
              let uploadedDocList = {
                key: index,
                document_name: (
                  <span
                    style={{
                      color: "var(--color-solid-darkblue)",
                      fontWeight: "100",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setViewedFile(true);
                      viewFile(comment, "old");
                    }}
                  >
                    {comment.file_name}
                  </span>
                ),
                uploaded_on: moment(new Date()).format("DD MMM YYYY"),
                uploaded_by: comment.author_name,
                action: (
                  <>
                    <div
                      style={{
                        justifyContent: "space-evenly",
                        display: "flex",
                      }}
                    >
                      <DownloadOutlined
                        title="Download"
                        style={{
                          fontSize: "20px",
                          color: "var(--color-solid-darkgrey)",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          onDownloadFile(
                            comment.file_name_on_s3,
                            comment.file_name
                          );
                        }}
                      />
                      {comment.uploaded_by === userData[0].id ? (
                        <Image
                          title="Delete"
                          src={deleteIcon}
                          preview={false}
                          style={{ width: "20px" }}
                          className="cursor-pointer"
                          onClick={() =>
                            onDeleteFile(
                              "old_upload",
                              comment.file_name_on_s3,
                              comment.file_name
                            )
                          }
                        />
                      ) : (
                        <>
                          <Image
                            title="Delete"
                            src={delete_disable}
                            style={{ width: "20px" }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </>
                      )}
                    </div>
                  </>
                ),
              };

              return uploadedDocList;
            }
          })
          .filter((n) => n)
          .reverse()
      );
      const contractComment = detailData.file_notes_data
        .flatMap((obj) => {
          return (
            obj &&
            obj.comments &&
            obj.comments.length > 0 &&
            obj.comments.map((data) => {
              return {
                comment: data,
                author: obj.author_name,
                created_on: moment(obj.uploaded_on).format(
                  "DD MMMM YYYY, hh:mm A"
                ),
              };
            })
          );
        })
        .filter(Boolean);
      setOldComments(
        contractComment && contractComment.length > 0 ? contractComment : []
      );
    }
  }, [detailData]);

  // function for view file
  const viewFileFromOldChat = (fileName) => {
    let fileData =
      fileOldData &&
      fileOldData.length > 0 &&
      fileOldData.filter(
        (data) => data.document_name.props.children === fileName
      );
    viewFile(fileData[0], "old");
  };

  const onDeleteFile = async (selectedFileType, fileKey, filename) => {
    try {
      setNotesTableData((prevTableNewData) => {
        const updatedTableNewData = prevTableNewData.filter(
          (document) => document.document_name.props.children !== filename
        );
        return updatedTableNewData;
      });

      setComments((prevComments) =>
        prevComments.filter((comment) => comment.content !== filename)
      );

      if (selectedFileType === "old_upload") {
        setFileKeysToDelete((prevFileKeys) => [...prevFileKeys, fileKey]);

        setOldComments((prevOldComments) =>
          prevOldComments.filter(
            (oldComments) => oldComments.comment.content !== filename
          )
        );

        let updatedFileNotes = [...detailData.file_notes_data];

        updatedFileNotes = updatedFileNotes
          .map((fileNotes) => {
            let updatedFileNotes = { ...fileNotes };
            if (fileNotes.file_name === filename) {
              delete updatedFileNotes.file_name;
              delete updatedFileNotes.file_name_on_s3;
              updatedFileNotes.comments = updatedFileNotes.comments.filter(
                (comments) => comments.content !== filename
              );
              if (updatedFileNotes.comments.length === 0) {
                return;
              }
            }
            return updatedFileNotes;
          })
          .filter((n) => n);

        detailData.file_notes_data = updatedFileNotes;
      }

      flag !== "contract" && setDisable("");

      console.log("newState: ", detailData.file_notes_data);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const uploadProps = {
    name: "file",
    listType: "picture",
    showUploadList: false,
    // accept: ["xlsx", "xls"],
    // maxCount: 1,
    onChange(info) {
      const maxSize = 25 * 1024 * 1024;

      if (info.file.size > maxSize) {
        // Checking if the file is within the 25MB limit
        message.error({
          content: `${info.file.name} is too large. Please upload a file smaller than 25MB.`,
          top: 100,
        });
        return;
      }

      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          flag !== "contract" && setDisable("");
          setFile(info.fileList);
          setComments([
            ...comments,
            { type: "file", content: `${info.file.name}` },
          ]);
          let data = [
            {
              document_name: (
                <span
                  style={{
                    color: "var(--color-solid-darkblue)",
                    fontWeight: "100",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setViewedFile(true);
                    viewFile(
                      { file_name: info.file.name },
                      "notes_comments",
                      "new"
                    );
                  }}
                >
                  {info.file.name}
                </span>
              ),
              uploaded_on: moment(new Date()).format("DD MMM YYYY"),
              uploaded_by: userData[0].first_name,
              file_content: info.fileList[0].originFileObj,
              action: (
                <>
                  <div
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                    }}
                  >
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        onDownloadNewFile(info.file);
                      }}
                    />
                    <Image
                      title="Delete"
                      src={deleteIcon}
                      preview={false}
                      style={{ width: "20px" }}
                      className="cursor-pointer"
                      onClick={() =>
                        onDeleteFile("new_upload", "", info.file.name)
                      }
                    />
                  </div>
                </>
              ),
            },
          ];
          setNotesTableData([...data, ...notesTableData]);
        } else {
          setFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
  };

  // req functionality for antd upload component
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // function for set entered comment
  // const onChangeComment = (e) => {
  //   setCommentEntered(e.target.value);
  // };
  const onChangeComment = (content) => {
    setCommentEntered(content);
  };

  // function for set uploaded comments
  const onAddComment = () => {
    if (commentEntered !== "") {
      setComments([...comments, { type: "text", content: commentEntered }]);
      setCommentEntered("");
      flag !== "contract" && setDisable("");
    }
  };

  // on press enter key in input field
  const handlePressEnter = () => {
    // Add logic to execute when Enter key is pressed
    console.log("Enter key pressed");
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={pipelinePath ? "pipeline-org-view" : "org-view-div"}>
            <div className="m-20" style={{ overflowY: "auto" }}>
              {!viewedFile ? (
                <>
                  <div className="pipeline-documents-div">
                    <Row
                      className="p-10"
                      style={{
                        borderBottom: "1px solid var(--color-border)",
                      }}
                    >
                      <Col span={24} align="center">
                        <p
                          style={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: "var(--color-solid-darkergrey)",
                          }}
                        >
                          Notes & Comments
                        </p>
                      </Col>
                    </Row>
                    <div className="p-5">
                      <div className="contract-comments-div mb-20">
                        <div className="contract-comments-align-right">
                          {comments &&
                            comments.length !== 0 &&
                            comments.map((comment) => {
                              return (
                                <div className="mt-15">
                                  <div
                                    className="contract-author-data"
                                    style={{ textAlign: "end" }}
                                  >
                                    <span>
                                      <UserOutlined
                                        style={{ fontSize: "11px" }}
                                      />
                                    </span>
                                    &nbsp; You
                                  </div>
                                  {comment.type === "text" ? (
                                    <div className="reactquill">
                                      <ReactQuill
                                        modules={{ toolbar: false }}
                                        value={comment.content}
                                        readOnly={true}
                                      />
                                    </div>
                                  ) : (
                                    <div className="contract-comments-new">
                                      <span>
                                        <FileOutlined
                                          style={{
                                            color:
                                              "var(--color-solid-darkblue)",
                                          }}
                                        />
                                      </span>
                                      &nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "var(--color-solid-darkblue)",
                                        }}
                                        className="cursor-pointer"
                                        onClick={() =>
                                          viewFileFromNewChat(comment.content)
                                        }
                                      >
                                        {comment.content}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <div className="contract-comments-align-left">
                          {oldComments &&
                            oldComments.length !== 0 &&
                            oldComments.map((data) => {
                              return (
                                <div className="mt-15">
                                  <div className="contract-author-data">
                                    <span>
                                      <UserOutlined
                                        style={{ fontSize: "11px" }}
                                      />
                                    </span>
                                    &nbsp;
                                    {data.author}
                                  </div>
                                  {data.comment.type === "text" ? (
                                    <div className="contract-comments-old">
                                      {data.comment.content}
                                      <div
                                        style={{
                                          fontSize: "8px",
                                          fontWeight: 600,
                                          color: "var(--color-solid-white)",
                                          textAlign: "end",
                                          paddingTop: 8,
                                        }}
                                      >
                                        {data.created_on}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="contract-comments-old">
                                      <span>
                                        <FileOutlined
                                          style={{
                                            color: "var(--color-solid-white)",
                                          }}
                                        />
                                      </span>
                                      &nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "var(--color-solid-white)",
                                        }}
                                        className="cursor-pointer"
                                        onClick={() =>
                                          viewFileFromOldChat(
                                            data.comment.content
                                          )
                                        }
                                      >
                                        {data.comment.content}
                                        <div
                                          style={{
                                            fontSize: "8px",
                                            fontWeight: 600,
                                            color: "var(--color-solid-white)",
                                            textAlign: "end",
                                            paddingTop: 8,
                                          }}
                                        >
                                          {data.created_on}
                                        </div>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div
                        className="contract-org-view-div"
                        style={{ padding: "0 20%" }}
                      >
                        <div className="reactquill-container">
                          <ReactQuill
                            value={commentEntered}
                            onPressEnter={handlePressEnter}
                            onChange={onChangeComment}
                            modules={{ toolbar: false }}
                            placeholder="Commands"
                            className="centered-quill"
                          />
                          <div className="floating-buttons">
                            <Button
                              icon={<FullscreenOutlined />}
                              onClick={openModal}
                            />
                            <Upload
                              {...uploadProps}
                              customRequest={dummyRequest}
                            >
                              <Image
                                title="Upload"
                                className="cursor-pointer"
                                src={attach}
                                preview={false}
                              />
                            </Upload>
                            <Image
                              title="Add Comment"
                              className="cursor-pointer"
                              style={{
                                paddingLeft: 10,
                                pointerEvents:
                                  commentEntered === "" ? "none" : "",
                              }}
                              src={send}
                              preview={false}
                              onClick={onAddComment}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div
                        className="contract-org-view-div"
                        style={{ padding: "0 20%" }}
                      >
                        <Input
                          // readOnly
                          // ref={inputRef}
                          value={commentEntered}
                          onPressEnter={handlePressEnter}
                          onChange={onChangeComment}
                          // style={{ background: "transparent" }}
                          className="contract-notes-input"
                          placeholder="Comments"
                          suffix={
                            <>
                              <Button
                                type="primary"
                                icon={<FullscreenOutlined />}
                                onClick={openModal}
                              />
                              <Upload
                                {...uploadProps}
                                customRequest={dummyRequest}
                              >
                                <Image
                                  title="Upload"
                                  className="cursor-pointer"
                                  src={attach}
                                  preview={false}
                                />
                              </Upload>

                              <Image
                                title="Add Comment"
                                className="cursor-pointer"
                                style={{
                                  paddingLeft: 10,
                                  pointerEvents:
                                    commentEntered === "" ? "none" : "",
                                }}
                                src={send}
                                preview={false}
                                onClick={onAddComment}
                              />
                            </>
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                  <DataTable
                    rowClassName={() => "contract-tree-table-row"}
                    type="clause-library"
                    dataTableData={[...notesTableData]}
                    colData={nDColumns}
                    showSorterTooltip={false}
                  />
                </>
              ) : (
                url.length !== 0 &&
                !url[0].newUpload && (
                  <>
                    <div
                      className="cursor-pointer m-10"
                      onClick={() => {
                        setViewedFile(false);
                        setUrl([
                          {
                            fileType: "docx",
                          },
                        ]);
                      }}
                    >
                      <Image
                        src={arrow_left}
                        style={{ marginLeft: 10, width: 25 }}
                        preview={false}
                      />
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: 13,
                          paddingLeft: 20,
                          color: "var(--color-solid-darkergrey)",
                        }}
                      >
                        &nbsp;{url && url[0] && url[0].fileName}
                      </span>
                    </div>
                    <div style={{ padding: "0px 20px" }}>
                      <DocViewer
                        className="doc-viewer"
                        pluginRenderers={DocViewerRenderers}
                        documents={url}
                        style={{ height: "calc(100vh - 230px)" }}
                        config={{
                          header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                          },
                          pdfZoom: {
                            defaultZoom: 0.7, // 1 as default,
                          },
                          pdfVerticalScrollByDefault: true, // false as default
                        }}
                      />
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <Modal
            className="maximize-batch-content"
            title="Notes & Commands"
            open={modalVisible}
            onCancel={closeModal}
            footer={null}
            width="90%"
            styles={{
              body: {
                height: "80vh",
                overflow: "auto",
              },
            }}
            // closeIcon={
            //   <FullscreenExitOutlined className="exit-fullscreen-icon" />
            // }
            centered
          >
            <ReactQuill
              id="max-quill"
              theme="snow"
              value={commentEntered}
              onChange={onChangeComment}
              modules={{
                toolbar: toolbarOptions,
              }}
              style={{ height: "100%" }}
              placeholder="Commands"
            />
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default NotesAndComments;
