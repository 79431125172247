export const pricingTableData = [
  {
    id: "1",
    profile: "ABC",
    contract: "Fixed",
    fx_table: "fx_table",
    status: "Progress",
    action: "Online",
    contract_currency: [
      {
        id: 1,
        currency: "USD",
      },
      {
        id: 3,
        currency: "GDP",
      },
    ],
    delivery_currency: [
      {
        id: 2,
        currency: "RUL",
      },
    ],
  },
  {
    id: "2",
    profile: "DEF",
    contract: "Agreed",
    fx_table: "fx_table",
    status: "Progress",
    action: "Offline",
    contract_currency: [
      {
        id: 2,
        currency: "USD",
      },
      {
        id: 3,
        currency: "EUR",
      },
    ],
    delivery_currency: [
      {
        id: 1,
        currency: "INR",
      },
    ],
  },
  {
    id: "3",
    profile: "GHI",
    contract: "Variable",
    fx_table: "fx_table",
    status: "Completed",
    action: "Online",
    contract_currency: [
      {
        id: 1,
        currency: "USD",
      },
      {
        id: 3,
        currency: "GDP",
      },
    ],
    delivery_currency: [
      {
        id: 2,
        currency: "RUL",
      },
    ],
  },
];

export const spendOverviewTableData = [
  {
    id: "1",
    supplier: "S1",
    contract: "A",
    cyv: 30000,
    tcv: 90000,
    start_date: "20.09.2022",
    end_date: "20.09.2023",
  },
  {
    id: "2",
    supplier: "S2",
    contract: "B",
    cyv: 40000,
    tcv: 200000,
    start_date: "25.11.2022",
    end_date: "09.07.2023",
  },
  {
    id: "3",
    supplier: "S3",
    contract: "C",
    cyv: 23000,
    tcv: 92000,
    start_date: "10.12.2022",
    end_date: "30.06.2023",
  },
];

export const contractSetupTableData = [
  {
    id: "1",
    supplier: "S1",
    contract: "A",
    cyv: 30000,
    tcv: 90000,
    start_date: "20.09.2022",
    end_date: "20.09.2023",
  },
  {
    id: "2",
    supplier: "S2",
    contract: "B",
    cyv: 40000,
    tcv: 200000,
    start_date: "25.11.2022",
    end_date: "09.07.2023",
  },
  {
    id: "3",
    supplier: "S3",
    contract: "C",
    cyv: 23000,
    tcv: 92000,
    start_date: "10.12.2022",
    end_date: "30.06.2023",
  },
];

export const invoicingViewTableData = [
  {
    id: "1",
    contract: "S1",
    currency: "USD",
    po: 30000,
    invoice: 90000,
    year: "2022",
    month: "03",
    value: 90000,
    eur_value: 90000,
  },
  {
    id: "2",
    contract: "S2",
    currency: "EUR",
    po: 63000,
    invoice: 40000,
    year: "2023",
    month: "11",
    value: 70000,
    eur_value: 70000,
  },
  {
    id: "3",
    contract: "S3",
    currency: "INR",
    po: 75000,
    invoice: 200000,
    year: "2022",
    month: "08",
    value: 40000,
    eur_value: 40000,
  },
];

export const availableDeliveryCurrency = [
  {
    id: 1,
    currency: "INR",
  },
  {
    id: 2,
    currency: "RUL",
  },
];

export const availableContractCurrency = [
  {
    id: 1,
    currency: "USD",
  },
  {
    id: 2,
    currency: "EUR",
  },
  {
    id: 3,
    currency: "GDP",
  },
];

export const ContractPipelineData = [
  {
    id: "1",
    document_name: "abc",
    document_type: "cr",
    supplier_name: "Infosys",
    contract_name: "Infosys Test",
    status: "signed",
    created_on: "2023-04-20T08:46:08.493Z",
    created_by: "admin",
  },
  {
    id: "2",
    document_name: "abcde",
    document_type: "sow",
    supplier_name: "Wipro",
    contract_name: "Wipro Test",
    status: "on-hold",
    created_on: "2023-05-10T09:32:45.493Z",
    created_by: "admin",
  },
  {
    id: "3",
    document_name: "dfg",
    document_type: "sow",
    supplier_name: "TCS",
    contract_name: "TCS Test",
    status: "rejected",
    created_on: "2023-04-15T03:22:11.493Z",
    created_by: "admin",
  },
];

export const RateCards = [
  {
    label: "RT",
    value: "RT",
  },
  {
    label: "XYZ",
    value: "XYZ",
  },
  {
    label: "123",
    value: "123",
  },
  {
    label: "MNO",
    value: "MNO",
  },
];

export const ContractCurrency = [
  {
    country: "Afghanistan",
    currency_code: "AFN",
  },
  {
    country: "Albania",
    currency_code: "ALL",
  },
  {
    country: "Algeria",
    currency_code: "DZD",
  },
  {
    country: "American Samoa",
    currency_code: "USD",
  },
  {
    country: "Andorra",
    currency_code: "EUR",
  },
  {
    country: "Angola",
    currency_code: "AOA",
  },
  {
    country: "Anguilla",
    currency_code: "XCD",
  },
  {
    country: "Antarctica",
    currency_code: "XCD",
  },
  {
    country: "Antigua and Barbuda",
    currency_code: "XCD",
  },
  {
    country: "Argentina",
    currency_code: "ARS",
  },
  {
    country: "Armenia",
    currency_code: "AMD",
  },
  {
    country: "Aruba",
    currency_code: "AWG",
  },
  {
    country: "Australia",
    currency_code: "AUD",
  },
  {
    country: "Austria",
    currency_code: "EUR",
  },
  {
    country: "Azerbaijan",
    currency_code: "AZN",
  },
  {
    country: "Bahamas",
    currency_code: "BSD",
  },
  {
    country: "Bahrain",
    currency_code: "BHD",
  },
  {
    country: "Bangladesh",
    currency_code: "BDT",
  },
  {
    country: "Barbados",
    currency_code: "BBD",
  },
  {
    country: "Belarus",
    currency_code: "BYR",
  },
  {
    country: "Belgium",
    currency_code: "EUR",
  },
  {
    country: "Belize",
    currency_code: "BZD",
  },
  {
    country: "Benin",
    currency_code: "XOF",
  },
  {
    country: "Bermuda",
    currency_code: "BMD",
  },
  {
    country: "Bhutan",
    currency_code: "BTN",
  },
  {
    country: "Bolivia",
    currency_code: "BOB",
  },
  {
    country: "Bosnia and Herzegovina",
    currency_code: "BAM",
  },
  {
    country: "Botswana",
    currency_code: "BWP",
  },
  {
    country: "Bouvet Island",
    currency_code: "NOK",
  },
  {
    country: "Brazil",
    currency_code: "BRL",
  },
  {
    country: "British Indian Ocean Territory",
    currency_code: "USD",
  },
  {
    country: "Brunei",
    currency_code: "BND",
  },
  {
    country: "Bulgaria",
    currency_code: "BGN",
  },
  {
    country: "Burkina Faso",
    currency_code: "XOF",
  },
  {
    country: "Burundi",
    currency_code: "BIF",
  },
  {
    country: "Cambodia",
    currency_code: "KHR",
  },
  {
    country: "Cameroon",
    currency_code: "XAF",
  },
  {
    country: "Canada",
    currency_code: "CAD",
  },
  {
    country: "Cape Verde",
    currency_code: "CVE",
  },
  {
    country: "Cayman Islands",
    currency_code: "KYD",
  },
  {
    country: "Central African Republic",
    currency_code: "XAF",
  },
  {
    country: "Chad",
    currency_code: "XAF",
  },
  {
    country: "Chile",
    currency_code: "CLP",
  },
  {
    country: "China",
    currency_code: "CNY",
  },
  {
    country: "Christmas Island",
    currency_code: "AUD",
  },
  {
    country: "Cocos (Keeling) Islands",
    currency_code: "AUD",
  },
  {
    country: "Colombia",
    currency_code: "COP",
  },
  {
    country: "Comoros",
    currency_code: "KMF",
  },
  {
    country: "Congo",
    currency_code: "XAF",
  },
  {
    country: "Cook Islands",
    currency_code: "NZD",
  },
  {
    country: "Costa Rica",
    currency_code: "CRC",
  },
  {
    country: "Croatia",
    currency_code: "HRK",
  },
  {
    country: "Cuba",
    currency_code: "CUP",
  },
  {
    country: "Cyprus",
    currency_code: "EUR",
  },
  {
    country: "Czech Republic",
    currency_code: "CZK",
  },
  {
    country: "Denmark",
    currency_code: "DKK",
  },
  {
    country: "Djibouti",
    currency_code: "DJF",
  },
  {
    country: "Dominica",
    currency_code: "XCD",
  },
  {
    country: "Dominican Republic",
    currency_code: "DOP",
  },
  {
    country: "East Timor",
    currency_code: "USD",
  },
  {
    country: "Ecuador",
    currency_code: "ECS",
  },
  {
    country: "Egypt",
    currency_code: "EGP",
  },
  {
    country: "El Salvador",
    currency_code: "SVC",
  },
  {
    country: "England",
    currency_code: "GBP",
  },
  {
    country: "Equatorial Guinea",
    currency_code: "XAF",
  },
  {
    country: "Eritrea",
    currency_code: "ERN",
  },
  {
    country: "Estonia",
    currency_code: "EUR",
  },
  {
    country: "Ethiopia",
    currency_code: "ETB",
  },
  {
    country: "Falkland Islands",
    currency_code: "FKP",
  },
  {
    country: "Faroe Islands",
    currency_code: "DKK",
  },
  {
    country: "Fiji Islands",
    currency_code: "FJD",
  },
  {
    country: "Finland",
    currency_code: "EUR",
  },
  {
    country: "France",
    currency_code: "EUR",
  },
  {
    country: "French Guiana",
    currency_code: "EUR",
  },
  {
    country: "French Polynesia",
    currency_code: "XPF",
  },
  {
    country: "French Southern territories",
    currency_code: "EUR",
  },
  {
    country: "Gabon",
    currency_code: "XAF",
  },
  {
    country: "Gambia",
    currency_code: "GMD",
  },
  {
    country: "Georgia",
    currency_code: "GEL",
  },
  {
    country: "Germany",
    currency_code: "EUR",
  },
  {
    country: "Ghana",
    currency_code: "GHS",
  },
  {
    country: "Gibraltar",
    currency_code: "GIP",
  },
  {
    country: "Greece",
    currency_code: "EUR",
  },
  {
    country: "Greenland",
    currency_code: "DKK",
  },
  {
    country: "Grenada",
    currency_code: "XCD",
  },
  {
    country: "Guadeloupe",
    currency_code: "EUR",
  },
  {
    country: "Guam",
    currency_code: "USD",
  },
  {
    country: "Guatemala",
    currency_code: "QTQ",
  },
  {
    country: "Guinea",
    currency_code: "GNF",
  },
  {
    country: "Guinea-Bissau",
    currency_code: "CFA",
  },
  {
    country: "Guyana",
    currency_code: "GYD",
  },
  {
    country: "Haiti",
    currency_code: "HTG",
  },
  {
    country: "Heard Island and McDonald Islands",
    currency_code: "AUD",
  },
  {
    country: "Holy See (Vatican City State)",
    currency_code: "EUR",
  },
  {
    country: "Honduras",
    currency_code: "HNL",
  },
  {
    country: "Hong Kong",
    currency_code: "HKD",
  },
  {
    country: "Hungary",
    currency_code: "HUF",
  },
  {
    country: "Iceland",
    currency_code: "ISK",
  },
  {
    country: "India",
    currency_code: "INR",
  },
  {
    country: "Indonesia",
    currency_code: "IDR",
  },
  {
    country: "Iran",
    currency_code: "IRR",
  },
  {
    country: "Iraq",
    currency_code: "IQD",
  },
  {
    country: "Ireland",
    currency_code: "EUR",
  },
  {
    country: "Israel",
    currency_code: "ILS",
  },
  {
    country: "Italy",
    currency_code: "EUR",
  },
  {
    country: "Ivory Coast",
    currency_code: "XOF",
  },
  {
    country: "Jamaica",
    currency_code: "JMD",
  },
  {
    country: "Japan",
    currency_code: "JPY",
  },
  {
    country: "Jordan",
    currency_code: "JOD",
  },
  {
    country: "Kazakhstan",
    currency_code: "KZT",
  },
  {
    country: "Kenya",
    currency_code: "KES",
  },
  {
    country: "Kiribati",
    currency_code: "AUD",
  },
  {
    country: "Kuwait",
    currency_code: "KWD",
  },
  {
    country: "Kyrgyzstan",
    currency_code: "KGS",
  },
  {
    country: "Laos",
    currency_code: "LAK",
  },
  {
    country: "Latvia",
    currency_code: "LVL",
  },
  {
    country: "Lebanon",
    currency_code: "LBP",
  },
  {
    country: "Lesotho",
    currency_code: "LSL",
  },
  {
    country: "Liberia",
    currency_code: "LRD",
  },
  {
    country: "Libyan Arab Jamahiriya",
    currency_code: "LYD",
  },
  {
    country: "Liechtenstein",
    currency_code: "CHF",
  },
  {
    country: "Lithuania",
    currency_code: "LTL",
  },
  {
    country: "Luxembourg",
    currency_code: "EUR",
  },
  {
    country: "Macau",
    currency_code: "MOP",
  },
  {
    country: "North Macedonia",
    currency_code: "MKD",
  },
  {
    country: "Madagascar",
    currency_code: "MGF",
  },
  {
    country: "Malawi",
    currency_code: "MWK",
  },
  {
    country: "Malaysia",
    currency_code: "MYR",
  },
  {
    country: "Maldives",
    currency_code: "MVR",
  },
  {
    country: "Mali",
    currency_code: "XOF",
  },
  {
    country: "Malta",
    currency_code: "EUR",
  },
  {
    country: "Marshall Islands",
    currency_code: "USD",
  },
  {
    country: "Martinique",
    currency_code: "EUR",
  },
  {
    country: "Mauritania",
    currency_code: "MRO",
  },
  {
    country: "Mauritius",
    currency_code: "MUR",
  },
  {
    country: "Mayotte",
    currency_code: "EUR",
  },
  {
    country: "Mexico",
    currency_code: "MXN",
  },
  {
    country: "Micronesia, Federated States of",
    currency_code: "USD",
  },
  {
    country: "Moldova",
    currency_code: "MDL",
  },
  {
    country: "Monaco",
    currency_code: "EUR",
  },
  {
    country: "Mongolia",
    currency_code: "MNT",
  },
  {
    country: "Montserrat",
    currency_code: "XCD",
  },
  {
    country: "Morocco",
    currency_code: "MAD",
  },
  {
    country: "Mozambique",
    currency_code: "MZN",
  },
  {
    country: "Myanmar",
    currency_code: "MMR",
  },
  {
    country: "Namibia",
    currency_code: "NAD",
  },
  {
    country: "Nauru",
    currency_code: "AUD",
  },
  {
    country: "Nepal",
    currency_code: "NPR",
  },
  {
    country: "Netherlands",
    currency_code: "EUR",
  },
  {
    country: "Netherlands Antilles",
    currency_code: "ANG",
  },
  {
    country: "New Caledonia",
    currency_code: "XPF",
  },
  {
    country: "New Zealand",
    currency_code: "NZD",
  },
  {
    country: "Nicaragua",
    currency_code: "NIO",
  },
  {
    country: "Niger",
    currency_code: "XOF",
  },
  {
    country: "Nigeria",
    currency_code: "NGN",
  },
  {
    country: "Niue",
    currency_code: "NZD",
  },
  {
    country: "Norfolk Island",
    currency_code: "AUD",
  },
  {
    country: "North Korea",
    currency_code: "KPW",
  },
  {
    country: "Northern Ireland",
    currency_code: "GBP",
  },
  {
    country: "Northern Mariana Islands",
    currency_code: "USD",
  },
  {
    country: "Norway",
    currency_code: "NOK",
  },
  {
    country: "Oman",
    currency_code: "OMR",
  },
  {
    country: "Pakistan",
    currency_code: "PKR",
  },
  {
    country: "Palau",
    currency_code: "USD",
  },
  {
    country: "Palestine",
    currency_code: null,
  },
  {
    country: "Panama",
    currency_code: "PAB",
  },
  {
    country: "Papua New Guinea",
    currency_code: "PGK",
  },
  {
    country: "Paraguay",
    currency_code: "PYG",
  },
  {
    country: "Peru",
    currency_code: "PEN",
  },
  {
    country: "Philippines",
    currency_code: "PHP",
  },
  {
    country: "Pitcairn Islands",
    currency_code: "NZD",
  },
  {
    country: "Poland",
    currency_code: "PLN",
  },
  {
    country: "Portugal",
    currency_code: "EUR",
  },
  {
    country: "Puerto Rico",
    currency_code: "USD",
  },
  {
    country: "Qatar",
    currency_code: "QAR",
  },
  {
    country: "Reunion",
    currency_code: "EUR",
  },
  {
    country: "Romania",
    currency_code: "RON",
  },
  {
    country: "Russian Federation",
    currency_code: "RUB",
  },
  {
    country: "Rwanda",
    currency_code: "RWF",
  },
  {
    country: "Saint Helena",
    currency_code: "SHP",
  },
  {
    country: "Saint Kitts and Nevis",
    currency_code: "XCD",
  },
  {
    country: "Saint Lucia",
    currency_code: "XCD",
  },
  {
    country: "Saint Pierre and Miquelon",
    currency_code: "EUR",
  },
  {
    country: "Saint Vincent and the Grenadines",
    currency_code: "XCD",
  },
  {
    country: "Samoa",
    currency_code: "WST",
  },
  {
    country: "San Marino",
    currency_code: "EUR",
  },
  {
    country: "Sao Tome and Principe",
    currency_code: "STD",
  },
  {
    country: "Saudi Arabia",
    currency_code: "SAR",
  },
  {
    country: "Scotland",
    currency_code: "GBP",
  },
  {
    country: "Senegal",
    currency_code: "XOF",
  },
  {
    country: "Serbia",
    currency_code: "RSD",
  },
  {
    country: "Seychelles",
    currency_code: "SCR",
  },
  {
    country: "Sierra Leone",
    currency_code: "SLL",
  },
  {
    country: "Singapore",
    currency_code: "SGD",
  },
  {
    country: "Slovakia",
    currency_code: "EUR",
  },
  {
    country: "Slovenia",
    currency_code: "EUR",
  },
  {
    country: "Solomon Islands",
    currency_code: "SBD",
  },
  {
    country: "Somalia",
    currency_code: "SOS",
  },
  {
    country: "South Africa",
    currency_code: "ZAR",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    currency_code: "GBP",
  },
  {
    country: "South Korea",
    currency_code: "KRW",
  },
  {
    country: "South Sudan",
    currency_code: "SSP",
  },
  {
    country: "Spain",
    currency_code: "EUR",
  },
  {
    country: "Sri Lanka",
    currency_code: "LKR",
  },
  {
    country: "Sudan",
    currency_code: "SDG",
  },
  {
    country: "Suriname",
    currency_code: "SRD",
  },
  {
    country: "Svalbard and Jan Mayen",
    currency_code: "NOK",
  },
  {
    country: "Swaziland",
    currency_code: "SZL",
  },
  {
    country: "Sweden",
    currency_code: "SEK",
  },
  {
    country: "Switzerland",
    currency_code: "CHF",
  },
  {
    country: "Syria",
    currency_code: "SYP",
  },
  {
    country: "Tajikistan",
    currency_code: "TJS",
  },
  {
    country: "Tanzania",
    currency_code: "TZS",
  },
  {
    country: "Thailand",
    currency_code: "THB",
  },
  {
    country: "The Democratic Republic of Congo",
    currency_code: "CDF",
  },
  {
    country: "Togo",
    currency_code: "XOF",
  },
  {
    country: "Tokelau",
    currency_code: "NZD",
  },
  {
    country: "Tonga",
    currency_code: "TOP",
  },
  {
    country: "Trinidad and Tobago",
    currency_code: "TTD",
  },
  {
    country: "Tunisia",
    currency_code: "TND",
  },
  {
    country: "Turkey",
    currency_code: "TRY",
  },
  {
    country: "Turkmenistan",
    currency_code: "TMT",
  },
  {
    country: "Turks and Caicos Islands",
    currency_code: "USD",
  },
  {
    country: "Tuvalu",
    currency_code: "AUD",
  },
  {
    country: "Uganda",
    currency_code: "UGX",
  },
  {
    country: "Ukraine",
    currency_code: "UAH",
  },
  {
    country: "United Arab Emirates",
    currency_code: "AED",
  },
  {
    country: "United Kingdom",
    currency_code: "GBP",
  },
  {
    country: "United States",
    currency_code: "USD",
  },
  {
    country: "United States Minor Outlying Islands",
    currency_code: "USD",
  },
  {
    country: "Uruguay",
    currency_code: "UYU",
  },
  {
    country: "Uzbekistan",
    currency_code: "UZS",
  },
  {
    country: "Vanuatu",
    currency_code: "VUV",
  },
  {
    country: "Venezuela",
    currency_code: "VEF",
  },
  {
    country: "Vietnam",
    currency_code: "VND",
  },
  {
    country: "Virgin Islands, British",
    currency_code: "USD",
  },
  {
    country: "Virgin Islands, U.S.",
    currency_code: "USD",
  },
  {
    country: "Wales",
    currency_code: "GBP",
  },
  {
    country: "Wallis and Futuna",
    currency_code: "XPF",
  },
  {
    country: "Western Sahara",
    currency_code: "MAD",
  },
  {
    country: "Yemen",
    currency_code: "YER",
  },
  {
    country: "Zambia",
    currency_code: "ZMW",
  },
  {
    country: "Zimbabwe",
    currency_code: "ZWD",
  },
];

export const supplierSetupTableData = [
  {
    supplier_id: "KR_PS_0090",
    supplier_name: "PSL LLP",
    supplier_entity: "PSL US LLC",
    supplier_status: "Active",
    supplier_country: "United States",
    supplier_address: "New York, USA",
    created_on: "2023-04-20",
  },
  {
    supplier_id: "KR_MK_0023",
    supplier_name: "McKernon Services",
    supplier_entity: "McK US Inc",
    supplier_status: "Active",
    supplier_country: "United States",
    supplier_address: "Detroit, USA",
    created_on: "2023-04-27",
  },
  {
    supplier_id: "KR_AK_0199",
    supplier_name: "Akme Technologies",
    supplier_entity: "AK US Inc",
    supplier_status: "Active",
    supplier_country: "United States",
    supplier_address: "LA, USA",
    created_on: "2023-04-28",
  },
  {
    supplier_id: "KR_WA_9887",
    supplier_name: "Wayne International",
    supplier_entity: "Wayne UK PLC",
    supplier_status: "Active",
    supplier_country: "United States",
    supplier_address: "Gotham, USA",
    created_on: "2023-04-28",
  },
  {
    supplier_id: "KR_ST_0098",
    supplier_name: "Stark Industries",
    supplier_entity: "Stark US Inc",
    supplier_status: "Dormant",
    supplier_country: "United States",
    supplier_address: "San Fransisco, USA",
    created_on: "2023-04-29",
  },
];

export const legalEntityTableData = [
  {
    id: "le_kr_001",
    le_name: "Krypton India LLP",
    le_country: "India",
    le_address: "Chennai, India",
    Status: "Active",
  },
  {
    id: "le_kr_002",
    le_name: "Krypton UK LLC",
    le_country: "UK",
    le_address: "London, UK",
    Status: "Dormant",
  },
];

export const costCenterTableData = [
  {
    id: "cc_kr_0001",
    cc_nbr: "CCAAP0909",
    cc_name: "India IT Services CC",
    le_id: "le_kr_001",
    le_name: "Krypton India LLP",
    status: "Active",
  },
  {
    id: "cc_kr_0002",
    cc_nbr: "CCBSS2321",
    cc_name: "India Direct Proc CC",
    le_id: "le_kr_001",
    le_name: "Krypton India LLP",
    status: "Active",
  },
];

export const WorkFlowTemplateSetupData = [
  {
    supplier_id: "KR_PS_0090",
    supplier_name: "PSL LLP",
    msa: "PSL Legal Services",
    framework_agreement: "RC_FW",
    supplement: "RC_SU",
    ccn: "RC_CCN",
    sow: "RC_SOW",
    cr: "RC_CR",
  },

  {
    supplier_id: "KR_ST_0098",
    supplier_name: "Stark Industries",
    msa: "Stark Legal Services",
    framework_agreement: "RC_FW",
    supplement: "RC_SU",
    ccn: "RC_CCN",
    sow: "RC_SOW",
    cr: "RC_CR",
  },
];

export const rateCardSetupTableData = [
  {
    supplier_id: "KR_PS_0090",
    supplier_name: "PSL LLP",
    msa: "PSL Legal Services",
    rate_card_name: "RC_PS",
    rate_cards: [
      {
        rate_card_type: "Logistics",
        delivery_location: "India",
        grade: "D",
        rate_card_eur_per_year: 52750,
        // rate_card_eur_per_year: {
        //   2023: 52750,
        //   2022: 20560,
        //   2021: 49050,
        //   2020: 38556,
        //   2019: 46321,
        //   2018: 35321,
        // },
      },
      {
        rate_card_type: "Assurance",
        delivery_location: "India",
        grade: "F",
        rate_card_eur_per_year: 28471,
        // rate_card_eur_per_year: {
        //   2023: 28471,
        //   2022: 35321,
        //   2021: 20560,
        //   2020: 46321,
        //   2019: 49050,
        //   2018: 52750,
        // },
      },
      {
        rate_card_type: "Logistics",
        delivery_location: "Brazil",
        grade: "I",
        rate_card_eur_per_year: 30254,
        // rate_card_eur_per_year: {
        //   2023: 30254,
        //   2022: 39321,
        //   2021: 49750,
        //   2020: 35321,
        //   2019: 20560,
        //   2018: 46321,
        // },
      },
    ],
  },
  {
    supplier_id: "KR_ST_0011",
    supplier_name: "Stark Industries",
    msa: "Stark Services",
    rate_card_name: "RC_ST",
    rate_cards: [
      {
        rate_card_type: "Logistics",
        delivery_location: "India",
        grade: "E",
        eur: 58471,
      },
      {
        rate_card_type: "Assurance",
        delivery_location: "Brazil",
        grade: "B",
        eur: 30254,
      },
      {
        rate_card_type: "Logistics",
        delivery_location: "India",
        grade: "D",
        eur: 52750,
      },
    ],
  },
];

export const contractTemplateSetupClauses = [
  {
    id: 1,
    title: "Introduction",
    checked: false,
  },
  {
    id: 2,
    title: "Scope",
    checked: false,
  },
  {
    id: 3,
    title: "Commercials",
    checked: false,
  },
];

export const fxSetupData = [
  {
    id: 1,
    supplier_id: "KR_PS_0090",
    supplier_name: "PSL-LLP",
    msa: "PSL Legal Services",
    fx_table_name: "FX_PS",
    contratual_fx: [
      {
        id: 1,
        billing_currency: "INR",
        year: "84.66",
      },
      {
        id: 2,
        billing_currency: "EUR",
        year: "56.88",
      },
    ],
    spot_rates: [
      {
        id: 1,
        billing_currency: "IND - India",
        Jan: "12",
        Feb: "12",
        March: "12",
        April: "12",
        May: "12",
        June: "12",
        July: "12",
        Aug: "12",
        Sept: "12",
        Oct: "12",
        Nov: "12",
        Dec: "12",
      },
      {
        id: 2,
        billing_currency: "EUR - Europe",
        Jan: "8",
        Feb: "8",
        March: "8",
        April: "8",
        May: "8",
        June: "8",
        July: "8",
        Aug: "8",
        Sept: "8",
        Oct: "8",
        Nov: "8",
        Dec: "8",
      },
    ],
  },
  {
    id: 2,
    supplier_id: "KR_ST_0098",
    supplier_name: "Stark Industry",
    msa: "Stark Legal Services",
    fx_table_name: "FX_ST",
    contratual_fx: [
      {
        id: 1,
        billing_currency: "USD",
        year: "84.66",
      },
      {
        id: 2,
        billing_currency: "EUR",
        year: "56.88",
      },
    ],
    spot_rates: [
      {
        id: 1,
        billing_currency: "USD - United States",
        Jan: "30",
        Feb: "30",
        March: "30",
        April: "30",
        May: "30",
        June: "30",
        July: "30",
        Aug: "30",
        Sept: "30",
        Oct: "30",
        Nov: "30",
        Dec: "30",
      },
      {
        id: 2,
        billing_currency: "EUR - Europe",
        Jan: "90",
        Feb: "90",
        March: "90",
        April: "90",
        May: "90",
        June: "90",
        July: "90",
        Aug: "90",
        Sept: "90",
        Oct: "90",
        Nov: "90",
        Dec: "90",
      },
    ],
  },
];

export const invoicingTableData = [
  {
    id: "1",
    profile: "LMNO",
    contract: "Fixed",
    status: "Progress",
    action: "Online",
    supplier: "Enron",
    supplier_entity_id: "Enron US Inc",
    allocation: "20%",
  },
  {
    id: "2",
    profile: "SPL",
    contract: "Agreed",
    status: "Progress",
    action: "Offline",
    supplier: "Stark Industries",
    supplier_entity_id: "Stark US Inc",
    allocation: "10%",
  },
  {
    id: "3",
    profile: "GHI",
    contract: "Variable",
    status: "Completed",
    action: "Online",
    supplier: "Fletcher Engines",
    supplier_entity_id: "Fletcher UK LLC",
    allocation: "15%",
  },
];

export const RoleDataTable = [
  {
    id: "1",
    roles: "Procurement Director",
    role_description: "Admin access",
    last_updated_on: "01 February 2023",
  },
  {
    id: "2",
    roles: "Procurement",
    role_description: "Admin",
    last_updated_on: "01 January 2023",
  },
  {
    id: "3",
    roles: "Finance",
    role_description: "Finance",
    last_updated_on: "01 March 2023",
  },
];

export const FlowDiagramData = {
  Level1: [
    {
      approver: "A",
      emailId: "aaaaaaaaaaaaaaaaaaaaa@gmail.com",
      approvalType: "Mandatory",
      from: ["START"],
      to: ["B", "C", "D", "E"],
      pairedWith: [],
      status: "Approved",
    },
  ],
  Level2: [
    {
      approver: "B",
      emailId: "b@gmail.com",
      approvalType: "Mandatory",
      from: ["A"],
      to: ["F"],
      pairedWith: [],
      status: "Approved",
    },
    {
      approver: "C",
      emailId: "c@gmail.com",
      approvalType: "Optional",
      from: ["A"],
      to: ["G"],
      pairedWith: ["D"],
      status: "Approved",
    },
    {
      approver: "D",
      emailId: "ddddddddddddddddddd@gmail.com",
      approvalType: "Optional",
      from: ["A"],
      to: ["G"],
      pairedWith: ["C"],
      status: "Rejected",
    },
    {
      approver: "E",
      emailId: "e@gmail.com",
      approvalType: "Mandatory",
      from: ["A"],
      to: ["H"],
      pairedWith: [],
      status: "Approved",
    },
  ],
  Level3: [
    {
      approver: "F",
      emailId: "f@gmail.com",
      approvalType: "Mandatory",
      from: ["B"],
      to: ["I"],
      pairedWith: [],
      status: "Approved",
    },
    {
      approver: "G",
      emailId: "g@gmail.com",
      approvalType: "Mandatory",
      from: ["C|D"],
      to: ["J"],
      pairedWith: [],
      status: "Approved",
    },
    {
      approver: "H",
      emailId: "h@gmail.com",
      approvalType: "Mandatory",
      from: ["E"],
      to: ["K"],
      pairedWith: [],
      status: "Approved",
    },
  ],
  Level4: [
    {
      approver: "I",
      emailId: "i@gmail.com",
      approvalType: "Mandatory",
      from: ["F"],
      to: ["L"],
      pairedWith: [],
      status: "Current Approver",
    },
    {
      approver: "J",
      emailId: "j@gmail.com",
      approvalType: "Mandatory",
      from: ["G"],
      to: ["L"],
      pairedWith: [],
      status: "Rejected",
    },
    {
      approver: "K",
      emailId: "k@gmail.com",
      approvalType: "Mandatory",
      from: ["H"],
      to: ["M"],
      pairedWith: [],
      status: "Approved",
    },
  ],
  Level5: [
    {
      approver: "L",
      emailId: "l@gmail.com",
      approvalType: "Mandatory",
      from: ["I", "J"],
      to: ["N"],
      pairedWith: [],
      status: "In Queue",
    },
    {
      approver: "M",
      emailId: "m@gmail.com",
      approvalType: "Mandatory",
      from: ["K"],
      to: ["N"],
      pairedWith: [],
      status: "Current Approver",
    },
  ],
  Level6: [
    {
      approver: "N",
      emailId: "n@gmail.com",
      approvalType: "Mandatory",
      from: ["L", "M"],
      to: ["O"],
      pairedWith: [],
      status: "In Queue",
    },
  ],
  Level7: [
    {
      approver: "O",
      emailId: "o@gmail.com",
      approvalType: "Mandatory",
      from: ["N"],
      to: ["END"],
      pairedWith: [],
      status: "In Queue",
    },
  ],
};

export const reportArrays = {
  pipeline: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Approval Levels",
    "Current Approval Level",
    "Current Approver",
    "Submitted Date",
    "Ageing",
  ],
  contractList: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Submitted Date",
    "Approved Date",
  ],
  rejected: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Approval Levels",
    "Rejection Level",
    "Current Approver",
    "Submitted Date",
    "Rejected Date",
    "Reason For Rejection",
    "Details Of Corrections Needed",
  ],
  executed: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Submitted Date",
    "Approved Date",
  ],
  cr_report: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Submitted Date",
    "Approved Date",
  ],
  msa_report: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Submitted Date",
    "Approved Date",
  ],
  commercial: [
    "Supplier",
    "Contract Type",
    "Parent Contract",
    "Contract Name",
    "Contract Description",
    "Start Date",
    "End Date",
    "ACV (EUR)",
    "TCV (EUR)",
    "Initiator",
    "Approval Levels",
    "Current Approval Level",
    "Current Approver",
    "Submitted Date",
    "Ageing",
    "Approved Date",
    "Rejection Level",
    "Rejected Date",
    "Reason for Rejection",
    "Details of Corrections Needed",
  ],
  supplier_setup: [
    "Supplier ID",
    "Supplier Name",
    "Supplier Name Short",
    "Supplier Status",
    "Supplier Entity",
    "Supplier Address",
    "Supplier Country",
  ],
  legal_setup: [
    "Legal Entity ID",
    "Legal Entity Name",
    "Legal Entity Status",
    "Legal Entity Address",
    "Legal Entity Country",
    "Created On",
    "Last Updated On",
  ],
  cost_setup: [
    "Cost Center ID",
    "Cost Center Name",
    "Cost Center Number",
    "Cost Center Status",
    "Legal Entity ID",
    "Legal Entity Name",
    "Created On",
    "Last Updated On",
  ],
  fx_setup: {
    contractual: ["Billing Currency", "Year"],
    spot: [
      "Billing Currency",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  pricing_setup: [
    "Pricing Profile ID",
    "Pricing Profile Name",
    "Pricing Profile Status",
    "Supplier ID",
    "Supplier Name",
    "Contract Pricing",
    "Contract Currency",
    "Delivery Currency",
    "FX Table",
    "Created On",
    "Last Updated On",
  ],
  invoicing_setup: [
    "From Entity",
    "To Entity",
    "Cost Center",
    "Allocation Percentage",
  ],
  workflow_setup: [
    "Step",
    "Step Fulfillment",
    "From",
    "To",
    "Approver",
    "Approval Type",
    "Email ID",
  ],
  aggregateOrder: ["contract", "original_value", "cr_value", "net_value"],
  aggregateExpandedOrder: [
    "contract",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
    "total",
  ],
  monthMapping: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
};

let totalSum = {
  mainData: {
    contract: "Infa Prod SC1",
    original_value: 490000,
    cr_value: 1617000,
    net_value: 2107000,
    children: [
      {
        contract: "CR1",
        original_value: 1277000,
        cr_value: 340000,
        net_value: 1617000,
        children: [
          {
            contract: "CR1-1",
            original_value: 340000,
            cr_value: "-",
            net_value: 340000,
          },
        ],
      },
    ],
  },
  monthData: {
    contract: "Infa Prod SC1",
    total: 490000,
    jan: 162000,
    feb: 50000,
    apr: 3000,
    may: 115000,
    aug: 100000,
    dec: 60000,
    children: [
      {
        contract: "CR1",
        total: 1277000,
        jan: 100000,
        feb: 102000,
        mar: 200000,
        apr: 20000,
        may: 3000,
        aug: 450000,
        sep: 200000,
        dec: 202000,
        children: [
          {
            contract: "CR1-1",
            total: 340000,
            jan: 55000,
            feb: 120000,
            mar: 50000,
            may: 100000,
            nov: 5000,
            dec: 60000,
          },
        ],
      },
    ],
  },
};

let yearlySum = {
  2016: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 5000,
      cr_value: "-",
      net_value: 5000,
      children: [
        {
          contract: "CR1",
          original_value: "-",
          cr_value: "-",
          net_value: "-",
          children: [
            {
              contract: "CR1-1",
              original_value: "-",
              cr_value: "-",
              net_value: "-",
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 5000,
      may: 5000,
      children: [
        {
          contract: "CR1",
          total: "-",
          children: [
            {
              contract: "CR1-1",
              total: "-",
            },
          ],
        },
      ],
    },
  },
  2017: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 22000,
      cr_value: "-",
      net_value: 22000,
      children: [
        {
          contract: "CR1",
          original_value: "-",
          cr_value: "-",
          net_value: "-",
          children: [
            {
              contract: "CR1-1",
              original_value: "-",
              cr_value: "-",
              net_value: "-",
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 22000,
      jan: 12000,
      dec: 10000,
      children: [
        {
          contract: "CR1",
          total: "-",
          children: [
            {
              contract: "CR1-1",
              total: "-",
            },
          ],
        },
      ],
    },
  },
  2018: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 3000,
      cr_value: "-",
      net_value: 3000,
      children: [
        {
          contract: "CR1",
          original_value: "-",
          cr_value: "-",
          net_value: "-",
          children: [
            {
              contract: "CR1-1",
              original_value: "-",
              cr_value: "-",
              net_value: "-",
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 3000,
      apr: 3000,
      children: [
        {
          contract: "CR1",
          total: "-",
          children: [
            {
              contract: "CR1-1",
              total: "-",
            },
          ],
        },
      ],
    },
  },
  2020: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: "-",
      cr_value: "-",
      net_value: "-",
      children: [
        {
          contract: "CR1",
          original_value: 450000,
          cr_value: "-",
          net_value: 450000,
          children: [
            {
              contract: "CR1-1",
              original_value: "-",
              cr_value: "-",
              net_value: "-",
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: "-",
      children: [
        {
          contract: "CR1",
          total: 450000,
          aug: 250000,
          sep: 200000,
          children: [
            {
              contract: "CR1-1",
              total: "-",
            },
          ],
        },
      ],
    },
  },
  2021: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 150000,
      cr_value: 622000,
      net_value: 772000,
      children: [
        {
          contract: "CR1",
          original_value: 607000,
          cr_value: 15000,
          net_value: 622000,
          children: [
            {
              contract: "CR1-1",
              original_value: 15000,
              cr_value: "-",
              net_value: 15000,
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 150000,
      aug: 100000,
      dec: 50000,
      children: [
        {
          contract: "CR1",
          total: 607000,
          feb: 2000,
          may: 3000,
          dec: 202000,
          mar: 200000,
          aug: 200000,
          children: [
            {
              contract: "CR1-1",
              total: 15000,
              nov: 5000,
              dec: 10000,
            },
          ],
        },
      ],
    },
  },
  2022: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 250000,
      cr_value: 295000,
      net_value: 545000,
      children: [
        {
          contract: "CR1",
          original_value: 120000,
          cr_value: 175000,
          net_value: 295000,
          children: [
            {
              contract: "CR1-1",
              original_value: 175000,
              cr_value: "-",
              net_value: 175000,
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 250000,
      jan: 150000,
      feb: 50000,
      may: 50000,
      children: [
        {
          contract: "CR1",
          total: 120000,
          apr: 20000,
          jan: 100000,
          feb: 100000,
          children: [
            {
              contract: "CR1-1",
              total: 175000,
              jan: 5000,
              feb: 120000,
              mar: 50000,
            },
          ],
        },
      ],
    },
  },
  2023: {
    mainData: {
      contract: "Infa Prod SC1",
      original_value: 60000,
      cr_value: 150000,
      net_value: 210000,
      children: [
        {
          contract: "CR1",
          original_value: "-",
          cr_value: 150000,
          net_value: 150000,
          children: [
            {
              contract: "CR1-1",
              original_value: 150000,
              cr_value: "-",
              net_value: 150000,
            },
          ],
        },
      ],
    },
    monthData: {
      contract: "Infa Prod SC1",
      total: 60000,
      may: 60000,
      children: [
        {
          contract: "CR1",
          total: "-",
          children: [
            {
              contract: "CR1-1",
              total: 150000,
              jan: 50000,
              may: 100000,
            },
          ],
        },
      ],
    },
  },
};
export const jsonString = [
  {
    h: "1. Compensation/Consideration",
    p: `Contractor shall provide all materials, supplies, labor, services, transportation, tools, equipment and parts to ______________________________ in a good and workmanlike manner to the satisfaction of the Owner for the estimated price of $___________, not to exceed $__________ ("Not to Exceed estimate"). The Not to Exceed estimate is in accordance with the Contractor's T&M proposal and rates, as included in the attached proposal, dated ___________. All labor charges shall be in accordance with the T&M rates provided therein. Invoiced hours shall be subject to Owner review and approval before payable.   If the performance of this Contract requires the services of subcontractors, equipment, supplies or materials, the Contractor agrees to pay for the same in full. At the time of payment by Owner, Contractor shall certify in writing that said payments have been made. The parties estimate that performance of this Contract will not exceed the Not to Exceed estimate. The Contractor shall notify the Owner in writing whenever it has reason to believe that the costs it expects to incur in the following 60 days, when added to all costs previously incurred, will exceed 75 percent of the Not to Exceed estimate; or the total cost for the performance of this Contract will be either greater or substantially less than had been previously estimated.  1`,
  },
  { h: "2. Term of Contract ", p: "" },
  { h: "3. Insurance ", p: "" },
  {
    h: "4. Changes  ",
    p: "The Contractor and the Owner agree that no change order or other form of order or directive will be considered valid unless it is delivered in writing by Owner to Contractor.  ",
  },
  {
    h: "5. Choice of Law",
    p: "This Contract is subject to and shall be interpreted under the law of _________________________________. ",
  },
  { h: "6. Termination  ", p: "" },
  {
    h: "7. Books of Account and Auditing  ",
    p: "The Contractor shall make available to Owner, if requested, true and complete records, which support billing statements, reports, performance indices and all other related documentation. Owner's authorized representatives shall have access during reasonable hours to all records deemed appropriate to auditing billing statements, reports, performance indices and all other related documentation. Contractor agrees that it will keep and preserve for at least seven years all documents related to the Contract which are routinely prepared, collected or compiled by the Contractor during the performance of this Contract.  ",
  },
  {
    h: "8. Labor  ",
    p: "The Contractor shall employ only competent and skilled workmen and foremen in the conduct of work on this Contract. The Contractor shall at all times enforce strict discipline and good order among Contractor's employees.   ",
  },
  { h: "9. Disputes  ", p: "" },
  {
    h: "10. Payments  ",
    p: "All invoices shall be sent to the Owner's Project Manager identified in this Contract.  Owner will make payments for services on a monthly basis for services performed during the previous month in accordance with this Contract. All labor Invoices shall include labor categories, rates, hours worked and total amounts per category. All labor categories and rates charged must be included in Appendix B, the Price Proposal sheet. No other categories or rates will be allowed or payable. Materials will be payable on a reimbursable basis plus the agreed-to additional fees for overhead, handling, and General and Administrative (G&A) costs.   23Owner will pay Contractor, upon submission of proper invoices, the prices stipulated in the Contract for services rendered and accepted, less any deductions provided in this Contract, within 30 days (Net 30).   ",
  },
  {
    h: " 11. Inspection of Services  ",
    p: "Contractor is responsible for performing or having performed all inspections and tests necessary to substantiate that the services furnished under this Contract conform to Contract requirements, including any applicable technical requirements for specified manufacturers' parts.   ",
  },
  {
    h: "12. Severability  ",
    p: "If any terms, conditions or provisions of this Contract shall be held unconstitutional, illegal or void, such finding shall not affect any other terms, conditions or provisions of this Contract.  ",
  },
];

export const contractExtractData = {
  PROJECT_TITLE: "Payment Gateway Application",
  SIGNATURE_AUTHORITIES_SUPPLIER_NAME: [
    "Zeptoh Solutions Private Limited,",
    "Venkatesan E",
  ],
  SIGNATURE_AUTHORITIES_SUPPLIER_ADDRESS: [
    "1/13, Reddy 3rd Street,\nEkkattuthangal,\nChennai 600032\n-",
    "Zeptoh Solutions Pvt Ltd\n5th floor, Gamma block, alpha city,\nNavalur, Chennai, India",
  ],
  PAYMENT_TERMS: [
    "Payment terms:",
    "All invoices shall be paid by Balantica in accordance with the payment conditions stated on the\ninvoice. In the absence of specific conditions, Mr. Balantica must make payment no later than\nten days after the invoice date.",
  ],
  SECURITY_AND_CONFIDENTIALITY: [
    "Security and confidentiality.",
    "Except as contemplated by the terms hereof or as required by applicable law or pursuant to an\norder of a court with competent jurisdiction, Zeptoh shall ensure and procure that each of its\nemployees, directors or representatives who provide a Service to Balantica shall keep\nconfidential all non-public information provided to it by Balantica ahrous and/or to which it has\naccess as a result of the Services provided hereunder and shall not disclose or otherwise make\navailable such information to any third party.",
  ],
  OVERVIEW: "Agreement Overview.",
  LEAVE_POLICY: [
    "Leave Policy.....",
    "The resource shall avail 1.5 days Casual leave per month and no other leaves are entertained.\nZeptoh will provide the holiday calendar. If the resources are not available in case of emergency\nor some other reasons, Zeptoh will take immediate steps to involve one or few resources as\nreplacement.",
  ],
  REVIEW_PERIOD: [
    "Periodic Review.......",
    "once in three months",
    "Quarterly (3 months)",
  ],
  SIGNATURE_CLAUSE: [
    "Signatures",
    "The following agree to the terms and conditions of this SLA:",
  ],
  SUPPLIER_ENTITY_NAME: "Zeptoh",
  GOALS_AND_OBJECTIVES: [
    "Goals & Objectives..",
    "The purpose of this Agreement is to ensure that the proper elements and commitments are in\nplace to provide consistent service support to Balantica by Zeptoh.\nZeptoh's focus is to help Balantica to meet their goals and objectives by delivering innovative\ntalent that enhances their technology landscape, shortens the product realization lifecycle and\nimproves their customer experience.\nThe goal of this Agreement is to obtain mutual agreement for service provision between\nBalantica and Zeptoh.\nThe objectives of this Agreement are to:\nProvide four resources, two full stack developers and two mid level developers initially\nto support, maintain and add new features.\nProvide costing of resources on monthly basis.\nProvide clear reference to service ownership, accountability, roles and/or\nresponsibilities.\nPresent a clear, concise and measurable description of service provision to Balantica.\nMatch perceptions of expected service provision with actual service support.",
  ],
  TERMINATION_AND_AMENDMENTS: [
    "Term, Termination AND Amendments",
    "Each party shall have the right to terminate this Agreement by written notice to the other if a\nparty has materially breached any obligation herein and such breach remains uncured for a\nperiod of 30 days after written notice of such breach is sent to the other party.",
  ],
  SCOPE: [
    "Service scope:",
    "Resources provided by Zeptoh will work for Balantica as per their requirements. first step, Resources will understand the application requirement functionally as well as\ntechnically, in-order to continue the software development, maintenance and adding\nnew features to Project Balantica application.\nIf the said resources could not solve or work on a particular issue, within the limits of\nthe position capabilities, then Zeptoh will involve other senior resources to help them\nor give them necessary trainings to perform the work without any additional cost.\nIn addition to that, Zeptoh will be in the background of these resources to share the\ntechnical expertise on enhancing the existing and new features of Project Balantica\napplication.",
  ],
  PROJECT_MANAGER_NAME: "Suresh Kumar",
  DIRECTOR_NAME: ["Abdelghafar Mahrous", "Mr. Venkatesan E"],
  SERVICE_MANAGER: ["Dinesh Kumar", "Venkatesan E"],
  VENDOR_MANAGER: "Venkatesan E",
  ROLES: [
    "VENDOR MANAGER: Venkatesan E\nSERVICE MANAGER: Dinesh Kumar",
    "Full Stack Developer Senior Analyst",
  ],
  SERVICE_REQUESTS: [
    "Balantica will be consecutively providing the detailed requirements to the Resources\nand they will be reporting to Balantica about the status of the work assigned to them.",
    "In support of services outlined in this Agreement, Zeptoh will respond to service related\nincidents and/or requests submitted by Balantica within the following time frames:\n0-8 hours (during business hours) for issues classified as High priority.\nWithin 48 hours for issues classified as Medium priority.\nWithin 5 working days for issues classified as Low priority.",
  ],
  DURATION: "Quarterly (3 months)",
  END_DATE: "01/04/2025",
  ASSUMPTIONS:
    "Assumptions related to in-scope services and/or components include:\nChanges to services will be communicated and documented to all stakeholders.",
  CONTRACT_ACV: "8000",
  SYSTEM_CURRENCY: "USD",
  CURRENCY: "USD",
  CONTRACT_CURRENCY: "USD",
  DELIVERY_CENTER: "Delivery Centre 1",
  CONTRACT_TCV: "8000",
  CONTRACT_TERMS: [
    "USD",
    "All invoices shall be paid by Balantica in accordance with the payment conditions stated on the\ninvoice. In the absence of specific conditions, Mr. Balantica must make payment no later than days after the invoice date.",
  ],
  SERVICE_AVAILABILITY:
    "Resources will be working 8 hours per day and five days a week.",
  START_DATE: "01/03/2025",
  PROJECT_TYPE: "T&M",
  COMMUNICATION_CLAUSE:
    "Names and contact details of authorized people to be contacted in case of issues or questions\nabout the Service.",
  CLIENT_DESIGNATED_CONTACTS: "Mr.Abdelghafar Mahrous",
  FOR_LEGAL_ENTITY: "Balantica",
  INFRASTRUCTURE:
    "Hardware and Software devices/licenses required for this contract will be provided by\nBalantica.",
  SIGNATURE_AUTHORITIES_CLIENT_DESIGNATION: "CEO and Founder",
  SIGNATURE_AUTHORITIES_CLIENT_NAME: "Mr.Abdelghafar Mahrous",
  SIGNATURE_AUTHORITIES_CLIENT_ADDRESS:
    "Balantica\n102, Lake boulevard, ST building,\nDublin, Ireland",
  SIGNATURE_AUTHORITIES_SUPPLIER_DESIGNATION: "CEO and Founder",
};

export const monthsShort = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
