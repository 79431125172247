import React, { useState, useEffect } from "react";
import { Row, Col, Table, Pagination } from "antd"; //antd components
import { useNavigate } from "react-router-dom";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  RateCardAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import {
  getRatecardPagination,
  deleteRCApi,
  getRatecardOnlyApi,
} from "../../../api/ratecardApi";
import { CreateButton } from "../../GlobalButton";
import moment from "moment";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import GlobalFilter from "../../GlobalFilter";
import ActionButton from "../../ActionButton";

function RateCardSetup() {
  const popupModalInstance = PopupModal();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [rateCardName, setRateCardName] = useRecoilState(RateCardAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold the editor true or false
  const [colEditor, openColEditor] = useState(false);
  // State variable to hold selected row key
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // state varaiable hold the show edit in table
  const [showEditOf, setShowEditOf] = useState(null);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    rateCardSetupData(
      paginateData.pageNo,
      paginateData.limit,
      searchVal,
      allFilterVal,
      sortOptions
    );
  };
  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Rate Card Name", "rate_card"),
      dataIndex: "rate_card",
      align: "left",
      width: 400,
      sorter: true,
      sortOrder: isColumnSorted("rate_card") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Name", "supplier_name"),
      dataIndex: "supplier_name",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("MSA", "msa"),
      dataIndex: "msa",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("msa") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.ratecard_setup.view ||
          roleUser[0].page_access.system_setup.ratecard_setup.create)
          ? true
          : false,
    },
  ];
  // State variabel to hold filter the all column datas
  const columns = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleViewRateCard = (item) => {
    let ssLog = {
      ss_id: item.id ? item.id : item._id,
      ss_name: item.ratetable_name,
      code: "rate_card",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Rate Card",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get ratecard setup data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const rateCardSetupData = (
    page,
    limit,
    searchVal,
    allFilterVal,
    sortColumn
  ) => {
    setLoader(true);
    getRatecardPagination(
      page,
      limit,
      searchVal,
      allFilterVal,
      sortColumn
    ).then((res) => {
      // console.log("ratecards", res);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      console.log("res: ", res.docs);
      const rateCardSetupData = res.docs.map((item, index) => {
        let tableRow = {
          key: item._id ? item._id : item.id,
          rate_card: (
            <span
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.ratecard_setup.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.ratecard_setup.view
                    ? "pointer"
                    : "default",
              }}
              onClick={() => {
                {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.ratecard_setup.view
                  ) {
                    handleViewRateCard(item);
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: item,
                    });

                    history(`/contract-setup/view/rate-card`, {
                      state: { data: item, flag: "view" },
                    });
                  }
                }
              }}
            >
              {item.ratetable_name}
            </span>
          ),
          supplier_id: item.supplier_id,
          supplier_name: item.supplier_name ? item.supplier_name : "-",
          msa: item.msa_name ? item.msa_name : "-",
          status: item.status,
          created_on: moment(item.createdAt).format("DD-MMM-YY"),
          created_by: item.created_user_name,
          updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
          updated_by: item.updated_user_name,

          action: (
            <ActionButton
              AccessCheck={
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.system_setup.ratecard_setup
              }
              data={item}
              dataStatus={item.status}
              itemName={item.ratetable_name}
              setHeaderChanger={setHeaderChanger}
              headerValue={{
                header: "systemSetup",
                headerItem: "2",
                headerItemName: "systemSetup",
              }}
              menuItem="contract-setup"
              subMenuItem="rate-card"
              deleteInfo={ModalMessage.RC_Delete_Info}
              handleDelete={handleDeleteRC}
              handleView={handleViewRateCard}
            />
          ),
        };
        return tableRow;
      });
      console.log("rateTableMaster: ", rateCardSetupData);
      setTableData(rateCardSetupData);
      setLoader(false);
    });
  };

  const handleDeleteRC = (rcId, rc_name, inputRCName) => {
    if (inputRCName === rc_name) {
      deleteRCApi(rcId).then((res) => {
        let umLog = {
          ss_id: rcId,
          ss_name: rc_name,
          code: "rate_card",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted Rate Card",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(rc_name, ModalMessage.RC_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              rateCardSetupData(1, 20, searchVal, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.RC_Delete_Error);
    }
  };

  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, [searchVal]);

  const onPageChange = (page, limit) => {
    rateCardSetupData(page, limit, searchVal, allFilterVal, sortColumnOptions);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log("searchValue", searchValue);
    if (searchValue === "") {
      setSearchVal("null");
    } else {
      setSearchVal(searchValue);
    }
  };

  // store ratecard name in global state
  const getAllRateCard = async () => {
    try {
      const res = await getRatecardOnlyApi();
      setRateCardName(res.map((i) => i.ratetable_name));
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished fetching ratecards");
    }
  };

  useEffect(() => {
    getAllRateCard();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      rateCardSetupData(1, 20, searchVal, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        rateCardSetupData(1, 20, searchVal, allFilterVal, sortColumnOptions);
      } else {
        rateCardSetupData(1, 20, searchVal, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Rate Card Setup | Judo";
  }, []);

  const onCreateRateCardSetup = () => {
    history(`/contract-setup/create/rate-card`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.ratecard_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.ratecard_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.ratecard_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"rateCard"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.ratecard_setup.create && (
            <Col xl={2} md={4} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateRateCardSetup} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          loading={loader}
          // rowSelection={rowSelection}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columns}
          onChange={handleTableChange}
          bordered
          size="large "
          scroll={{ y: "calc(100vh - 255px)" }}
          pagination={false}
          className="system-setup-table rate-card-table"
          onRow={(record, rowIndex) => {
            return {
              // onClick: () => { rowClick(record.key) },
              onMouseOver: () => {
                setShowEditOf(record.key);
                // console.log(record.key);
              },
              onMouseOut: () => {
                setShowEditOf(null);
              },
            };
          }}
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            // simple
            // pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default RateCardSetup;
