import React, { useState, useEffect } from "react";
import { Table, Row, Col, Pagination } from "antd"; //antd components
import { useNavigate } from "react-router-dom";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  InvoicingProfileAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import {
  getAllInvoicingProfilePaginationApi,
  getAllInvoicingProfileApi,
} from "../../../api/invoicingProfileApi";
import { CreateButton } from "../../GlobalButton";
import moment from "moment";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { deleteIPApi } from "../../../api/invoicingProfileApi";
import ActionButton from "../../ActionButton";
import GlobalFilter from "../../GlobalFilter";

function InvoiceProfile() {
  const popupModalInstance = PopupModal();
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom); //global state
  // Variable to use navigate function
  const history = useNavigate();
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [ipName, setIPName] = useRecoilState(InvoicingProfileAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    invoicingProfileData(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle(
        "Invoicing Profile Name",
        "invoicing_profile_name"
      ),
      dataIndex: "invoicing_profile_name",
      align: "left",
      width: 400,
      sorter: true,
      sortOrder: isColumnSorted("invoicing_profile_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Name", "supplier_id"),
      dataIndex: "supplier_id",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("supplier_id") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.invoicing_profile.view ||
          roleUser[0].page_access.system_setup.invoicing_profile.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columns = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleViewInvoiceProfile = (item) => {
    let ssLog = {
      ss_id: item._id ? item._id : item.id,
      ss_name: item.invoice_profile_name,
      code: "invoicing_profile",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Invoicing Profile",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get Fx table data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const invoicingProfileData = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getAllInvoicingProfilePaginationApi(
      page,
      limit,
      allFilterVal,
      sortColumn
    ).then((res) => {
      console.log("getAllInvoicingProfilePaginationApi", res);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const invoicingData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          invoicing_profile_name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.invoicing_profile.view
                ) {
                  handleViewInvoiceProfile(item);
                  setHeaderChanger({
                    header: "systemSetup",
                    headerItem: "2",
                    headerItemName: "systemSetup",
                    stateSetter: item,
                  });
                  history(`/contract-setup/view/invoicing-profile`, {
                    state: { data: item, flag: "view" },
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.invoicing_profile.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.invoicing_profile.view
                    ? "pointer"
                    : "default",
              }}
            >
              {item.invoice_profile_name}
            </span>
          ),
          supplier_id:
            item.supplier.length !== 0 ? item.supplier[0]?.supplier_name : "-",
          created_on: moment(item.createdAt).format("DD-MMM-YY"),
          created_by: item.created_user_name,
          updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
          updated_by: item.updated_user_name,
          status: (
            <span
              style={{
                // color: "var(--color-solid-orange)",
                fontWeight: "500",
              }}
            >
              {item.status}
            </span>
          ),
          // action: item.action,
          action: (
            <ActionButton
              AccessCheck={
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.system_setup.invoicing_profile
              }
              data={item}
              dataStatus={item.status}
              itemName={item.invoice_profile_name}
              setHeaderChanger={setHeaderChanger}
              headerValue={{
                header: "systemSetup",
                headerItem: "2",
                headerItemName: "systemSetup",
              }}
              menuItem="contract-setup"
              subMenuItem="invoicing-profile"
              deleteInfo={ModalMessage.IP_Delete_Info}
              handleDelete={handleDeleteIP}
              handleView={handleViewInvoiceProfile}
            />
          ),
        };
        return tableRow;
      });
      setTableData(invoicingData);
      setLoader(false);
    });
  };

  const handleDeleteIP = (ipId, ip_name, inputIPName) => {
    if (inputIPName === ip_name) {
      deleteIPApi(ipId).then((res) => {
        let umLog = {
          ss_id: ipId,
          ss_name: ip_name,
          code: "delete",
          author: userData[0].id,
          action: "delete-ip",
          notes: "Deleted Invoicing Profile",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(ip_name, ModalMessage.IP_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              invoicingProfileData(1, 20, allFilterVal, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.IP_Delete_Error);
    }
  };

  const onPageChange = (page, limit) => {
    invoicingProfileData(page, limit, allFilterVal, sortColumnOptions);
  };

  // store invoicingprofile name in global state
  const getAllIP = async () => {
    try {
      const res = await getAllInvoicingProfileApi();
      setIPName(res.map((i) => i.invoice_profile_name));
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished fetching IP");
    }
  };

  useEffect(() => {
    getAllIP();
  }, []);

  // Function to handle get all Invoice table data
  useEffect(() => {
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      invoicingProfileData(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        invoicingProfileData(1, 20, allFilterVal, sortColumnOptions);
      } else {
        invoicingProfileData(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Invoicing Profile | Judo";
  }, []);

  const onCreateIP = () => {
    history(`/contract-setup/create/invoicing-profile`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.invoicing_profile.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.invoicing_profile.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.invoicing_profile.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"invoicingProfile"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.invoicing_profile.create && (
            <Col xl={2} md={4} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateIP} />
            </Col>
          )}
      </Row>
      <div className="mt-10">
        <Table
          loading={loader}
          dataSource={tableData}
          columns={columns}
          onChange={handleTableChange}
          showSorterTooltip={false}
          bordered
          size="large"
          scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }}
          pagination={false}
          className="system-setup-table"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default InvoiceProfile;
