import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  message,
  Layout,
  Upload,
  Table,
  Modal,
  Popconfirm,
  InputNumber,
  DatePicker,
  Tabs,
  Divider,
} from "antd"; //antd components
import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import { useRecoilState } from "recoil";
import TopHeader from "../../Header";
import add from "../../../assets/images/icons/Add_round.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import { suppliersTreeApi } from "../../../api/suppliersApi";
import { getPricingProfileByRatecard } from "../../../api/pricingProfileApi";
import { getAllMsaApi } from "../../../api/msaApi";
import {
  createRatecardAPi,
  editRateTableDataAPi,
} from "../../../api/ratecardApi";
import { createActivityLogApi } from "../../../api/logApi";
import { ContractCurrency } from "../../../utils/data";
import rateCardTemplate from "../../../template/Rate_Card_Template.xlsx";
import dayjs from "dayjs";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  NextButton,
  ExcelButton,
  TableButton,
} from "../../GlobalButton";
import NotAuthorized from "../../NotAuthorized";
import {
  HeaderChangerAtom,
  RoleAccessAtom,
  RateCardAtom,
  userDataAtom,
} from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import HeaderCard from "../../HeaderCard";
import DataTable from "../../Tables/DataTable";
import { dataTable, setupDataTableForm } from "../../../utils/common";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

const RateCardController = () => {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let { state } = useLocation(); //global state variable hold the corresponding rate card details using location
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  const popupModalInstance = PopupModal(); // global state variable hold the popup modal
  const [rcId, setRcId] = useState("");
  const userData = useRecoilState(userDataAtom); //global state varaiable hold the dashboard user data to display
  const roleUser = useRecoilState(RoleAccessAtom);
  let header = headerChanger.header; //global state variable hold the header
  const history = useNavigate(); //global state variable hold the navigating different routes
  const menuKey = "5"; // global state variable hold the key while switch header name

  const [rcDates, setRcDates] = useState({
    startDate: state.data && state.data.start_date,
    endDate: state.data && state.data.end_date,
  });
  let staticColumns = [
    {
      key: 0,
      column_name: "Role",
      column_type: "free_text",
    },
    {
      key: 1,
      column_name: "Location",
      column_type: "free_text",
    },
    {
      key: 2,
      column_name: "Grade",
      column_type: "free_text",
    },
    {
      key: 3,
      column_name: "Price",
      column_type: "number",
    },
    {
      key: 4,
      column_name: "Currency",
      column_type: "currency",
    },
    {
      key: 5,
      column_name: "Rate Basis",
      column_type: "dropdown",
    },
    {
      key: 6,
      column_name: "Rate Period",
      column_type: "dropdown",
    },
  ];

  const [rateState, setRateState] = useState({
    rateBasis: null,
    ratePeriod: null,
  }); // state variable hold the intial null for rate basic and periods
  const [rateCardField, setRateCardField] = useState({
    supplierName: state.data.supplier_name,
    msa: state.data && state.data.msa,
    msaName: state.data && state.data.msa_name,
    rateCardName: state.data.ratetable_name,
    supplier_id: state.data && state.data.supplier_id,
  }); // state variable hold the inital rate card field
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  const [uploadRule, setUploadRule] = useState(false); // state variable hold the  upload validation required true or false
  const [uploadRuleText, setUploadRuleText] = useState(); // state variable hold the  upload validation error text
  const [supplierData, setSupplierData] = useState([]); // state variable hold the supplier data from fetch API
  const [msaData, setMsaData] = useState([]); // state variable hold the MSA data from fetch API
  const [loader, setLoader] = useState(false); // state variable hold the loader on api integration
  const [saveTableLoader, setSaveTableLoader] = useState(false); // state variable hold the loading while create the data and send to API
  const [rateTableFile, setRateTableFile] = useState([]); // state variable hold the file information
  const [formLevel, setFormLevel] = useState(""); // state varaiable hold the column define in upload
  // state variable hold the column type and name as well as key
  const [ratecardData, setRatecardData] = useState({});
  //state variable hold the count value while clone data is performe
  // state variable hold the column data source
  // state variable hold the count no of column
  // state variable hold the button-disable
  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);
  const [buttonState, setButtonState] = useState(0);
  // state variable hold the true or false for table and upload
  const [changed, setChanged] = useState(false);
  // state variable hold the wheather table or uploadd
  const [createMethod, setCreateMethod] = useState("upload");

  //*********************************************************************************************************** */
  const [tabKey, setTabKey] = useState("1");
  const [selectRatePeriod, setSelectRatePeriod] = useState();
  const [associationData, setAssociationData] = useState([]);
  const [supplierListLoader, setSupplierListLoader] = useState(false);

  const [columnDefDataSource, setColumnDefDataSource] = useState(staticColumns);
  const columnDefDataSourceRef = useRef(columnDefDataSource);

  const [rateTableDataSource, setRateTableDataSource] = useState([]);
  const rateTableDataSourceRef = useRef(rateTableDataSource);

  const [countColumn, setCountColumn] = useState(staticColumns.length);
  const countColumnRef = useRef(countColumn);

  const [rateTableCount, setRateTableCount] = useState(0);
  const rateTableCountRef = useRef(rateTableCount);

  const [mainForm] = Form.useForm();
  const [subForm] = Form.useForm();

  const mainFormRef = useRef(null);
  const subFormRef = useRef(null);

  const [datasourceObj, setDatasourceObj] = useState({});

  columnDefDataSourceRef.current = columnDefDataSource;
  rateTableDataSourceRef.current = rateTableDataSource;
  countColumnRef.current = countColumn;
  rateTableCountRef.current = rateTableCount;
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [rcFile, setRcFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  //*********************************************************************************************************** */

  // function handle rate table card data
  const rateCardTableData = () => {
    console.log("state: ", state);
    let ratecardData = [];
    let columnData = [];
    for (let i = 0; i < state.data.rt_items_detail.length; i++) {
      let detail = state.data.rt_items_detail[i];
      let dataObj = {};
      for (let j = 0; j < state.data.rt_items_desc.length; j++) {
        dataObj[state.data.rt_items_desc[j]] = detail[j];
      }
      ratecardData.push(dataObj);
    }
    for (let i = 0; i < state.data.rt_items_desc.length; i++) {
      columnData.push([
        state.data.rt_items_desc[i],
        state.data.rt_items_type[i],
      ]);
    }
    ratecardData.forEach((item) => {
      if ("Item Id" in item) {
        delete item["Item Id"];
        delete item["undefined"];
      } else if ("Item ID" in item) {
        delete item["Item ID"];
        delete item["undefined"];
      } else if ("Item id" in item) {
        delete item["Item id"];
        delete item["undefined"];
      }
    });
    columnData.shift();
    columnData.forEach((data) => {
      setDatasourceObj((prevObj) => ({
        ...prevObj,
        [data[0]]: undefined,
      }));
    });

    let columnDefinitions =
      columnData && columnData.length > 0
        ? columnData
            .map((data, index) => ({
              column_name: data[0],
              column_type: data[1],
              key: index,
            }))
            .filter(Boolean)
        : staticColumns;

    setRateTableDataSource(
      ratecardData.map((data, index) => ({ ...data, key: index }))
    );
    setColumnDefDataSource(columnDefinitions);
    setRateTableCount(ratecardData.length);
    setCountColumn(
      columnData && columnData.length > 0
        ? columnData.length
        : staticColumns.length
    );
  };

  // use effect handle supplier data FroM  fetch API
  useEffect(() => {
    setSupplierListLoader(true);
    suppliersTreeApi(userData[0].id).then((res) => {
      setSupplierListLoader(false);
      let supplierMaster = res.map((data) => {
        delete data.children;
        return data;
      });
      setSupplierData(supplierMaster);
    });
    if (Object.keys(state.data).length !== 0 && state.data !== "create") {
      rateCardTableData();
      getPricingProfileByRatecard(state && state._id).then((res) => {
        console.log("pp res", res);
        let association = res.map((data) => {
          let tableRow = {
            associated_item: "Pricing Profile",
            name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                  ) {
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: data,
                    });
                    history(`/contract-setup/view/pricing-profile`, {
                      state: data,
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "bold",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.price_profile_name}
              </span>
            ),
            association_date: moment(data.createdAt).format("DD-MMM-YY"),
          };
          return tableRow;
        });
        setAssociationData(association);
      });
    }
  }, []);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
    getAllMsaApi().then((res) => {
      console.log("res", res);
      let filteredMsa = res.filter(
        (data) => data.supplier_id === rateCardField.supplier_id
      );
      setMsaData(
        filteredMsa
          ? filteredMsa
          : res.docs.filter(
              (data) => data.supplier_id === rateCardField.supplier_id
            )
      );
    });
  }, []);

  /*
   * Function to check the excel format and indicate file success or unsuccess
   *
   */
  const excelUploadProps = {
    name: "file",
    listType: "picture",
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length !== 0) {
          setRateTableFile(info.fileList);
        } else {
          setRateTableFile([]);
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setRateTableFile(info.fileList);
        } else {
          setRateTableFile([]);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = ["xls", "xlsx"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload the excel files.");
        popupModalInstance.errorModal(ModalMessage.RC_Excel_Upload_Error);
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };

  /* Function to uplaod the file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString, field) => {
    // for start-date datepicker
    if (field === "start_date") {
      setRcDates({
        ...rcDates,
        startDate: dateString,
      });
      dateString !== "" &&
      state.data &&
      state.data.start_date &&
      moment(state.data.start_date).format("DD MMM YYYY") !== dateString
        ? setDisable("")
        : setDisable(disableVal);
    }

    // for end-date datepicker
    if (field === "end_date") {
      setRcDates({
        ...rcDates,
        endDate: dateString,
      });
      dateString !== "" &&
      state.data &&
      state.data.end_date &&
      moment(state.data.end_date).format("DD MMM YYYY") !== dateString
        ? setDisable("")
        : setDisable(disableVal);
    }
  };

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    const currentDate = moment().startOf("day");
    if (field === "start_date") {
      if (!rcDates.endDate) {
        return dateValue.isBefore(currentDate, "day");
      }
      return dateValue.isAfter(rcDates.endDate, "day");
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!rcDates.startDate) {
        return dateValue.isBefore(currentDate, "day");
      }
      return dateValue.isBefore(rcDates.startDate, "day");
    }
  };

  /*function to handle save column
   * @param row - indicate column row to save
   */
  const handleColSave = (row) => {
    console.log("row: ", row);
    const newData = [...columnDefDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setColumnDefDataSource(newData);
  };

  const updateRateBasedColumns = (column_name, rowKey, value) => {
    let newData = [...rateTableDataSourceRef.current];
    let selectedRow = rateTableDataSourceRef.current.find(
      (obj) => obj.key === rowKey
    );
    selectedRow[column_name] = value;
    setRateTableDataSource([...newData]);
  };

  /*function to handle rate basic  input
   *@param recordIndex - correspondind index
   * @param value - corresponding selected value
   */
  const handleRateBasis = (value, rowKey) => {
    console.log("rowkey", typeof rowKey);
    setSelectRatePeriod(value);
    setRateState((prevState) => ({
      ...prevState,
      rateBasis: value,
    }));

    const form = state.flag === "create" ? subForm : mainForm;
    const rateTableValues = form.getFieldValue("rate_table") || [];

    const updatedRateTableValues = rateTableValues.map((item, index) =>
      index === rowKey ? { ...item, "Rate Period": null } : item
    );

    form.setFieldsValue({
      rate_table: updatedRateTableValues,
    });

    let updatedrateTable = rateTableDataSource.map((data) => {
      if (data.key === rowKey) {
        data["Rate Period"] = undefined;
        return data;
      }
      return data;
    });
    rateTableValues[rowKey]["Rate Basis"] === value
      ? setDisable("")
      : setDisable(disableVal);
    setRateTableDataSource(updatedrateTable);

    updateRateBasedColumns("Rate Basis", rowKey, value);
  };

  /*function to handle rate period  input
   * @param value - corresponding selected value
   */
  const handleRatePeriod = (value, rowKey) => {
    setRateState({
      ...rateState,
      ratePeriod: value,
    });
    const form = state.flag === "create" ? subForm : mainForm;
    const rateTableValues = form.getFieldValue("rate_table") || [];
    rateTableValues[rowKey]["Rate Period"] === value
      ? setDisable("")
      : setDisable(disableVal);
    updateRateBasedColumns("Rate Period", rowKey, value);
  };

  /*function to handle save data after given enter  in table
   *@param value - corresponding select value
   *@param id - corresponding selected id
   *@parm columnname - selected columnname
   * @param columntype - seleccted column type
   *
   */
  const handleSaveRateTableCell = (value, rowKey, columnName, columnType) => {
    let newData = [...rateTableDataSourceRef.current];
    let selectedRow = rateTableDataSourceRef.current.find(
      (obj) => obj.key === rowKey
    );
    const form = state.flag === "create" ? subForm : mainForm;
    const rateTableValues = form.getFieldValue("rate_table") || [];
    rateTableValues[rowKey][columnName] === value
      ? setDisable("")
      : setDisable(disableVal);

    selectedRow[columnName] =
      columnType === "number"
        ? value.replace(/[^0-9]/g, "")
        : value.trim().length === 0
        ? undefined
        : value.trim();

    if (value.trim().length === 0) {
      form.setFieldValue(["rate_table", rowKey, columnName], undefined);
    } else {
      form.setFieldValue(["rate_table", rowKey, columnName], value.trim());
    }

    console.log("newData", newData);
    const isValid = newData.every((item) =>
      Object.values(item).every((value) => value !== undefined && value !== "")
    );
    isValid ? setDisable("") : setDisable(disableVal);

    setRateTableDataSource([...newData]);
  };

  const handleCloneRateTableRow = (rowKey) => {
    const originalRow = rateTableDataSourceRef.current.find(
      (obj) => obj.key === rowKey
    );

    if (originalRow) {
      const newClone = { ...originalRow, key: rateTableCountRef.current };
      setRateTableDataSource((prevColumnDefDataSource) => {
        const newColumnDefDataSource = [...prevColumnDefDataSource, newClone];
        return newColumnDefDataSource;
      });

      setRateTableCount((prevCountColumn) => {
        const newCountColumn = prevCountColumn + 1;
        return newCountColumn;
      });
    }
    setDisable("");
  };

  /* Function to handle the data records
   * @param id- corresponding row id
   *
   */
  const handleDeleteRateTableRow = (rowKey, index) => {
    console.log("rowKey: ", rowKey, "index: ", index);
    const dataSource = [...rateTableDataSource];
    console.log("dataSource: ", dataSource);
    const filteredData = dataSource.filter((data) => data.key !== rowKey);
    console.log("filteredData: ", filteredData);
    setRateTableDataSource([...filteredData]);
    setRateTableCount(rateTableCount + 1);
    setDisable("");
  };

  const handleAddRateTableRow = () => {
    const newData = {
      ...datasourceObj,
      key: rateTableCount,
    };
    setRateTableDataSource((prevDataSource) => [...prevDataSource, newData]);
    setRateTableCount(rateTableCount + 1);
    setDisable("");
  };

  const handleCloneColumnDefinition = (rowKey) => {
    const originalRow = columnDefDataSourceRef.current.find(
      (obj) => obj.key === rowKey
    );

    if (originalRow) {
      const newClone = { ...originalRow, key: countColumnRef.current };
      setColumnDefDataSource((prevColumnDefDataSource) => {
        const newColumnDefDataSource = [...prevColumnDefDataSource, newClone];
        return newColumnDefDataSource;
      });

      setCountColumn((prevCountColumn) => {
        const newCountColumn = prevCountColumn + 1;
        return newCountColumn;
      });
    }
    setDisable("");
  };

  const handleDeleteColumnDefinition = (rowKey) => {
    setColumnDefDataSource((prevColumnDefDataSource) => {
      const filteredData = prevColumnDefDataSource.filter(
        (item) => item.key !== rowKey
      );
      return filteredData;
    });
    setCountColumn((prevCountColumn) => {
      const newCountColumn = prevCountColumn + 1;
      return newCountColumn;
    });
    setDisable("");
  };

  const handleAddColumnDefinition = () => {
    setColumnDefDataSource((prevColumnDefDataSource) => {
      const newData = {
        key: countColumnRef.current,
        column_name: "",
        column_type: "",
      };
      const newColumnDefDataSource = [...prevColumnDefDataSource, newData];
      return newColumnDefDataSource;
    });

    setCountColumn((prevCountColumn) => {
      const newCountColumn = prevCountColumn + 1;
      return newCountColumn;
    });
    // setDisable("");
  };

  const updateRateTableColumnDefinition = (columnName, updateType) => {
    if (rateTableDataSourceRef.current.length !== 0) {
      if (updateType === "column_type") {
        setRateTableDataSource((prevDataSource) => {
          const updatedDataSource = prevDataSource.map((row) => {
            row[columnName] = undefined;

            const form = state.flag === "create" ? subForm : mainForm;

            const rateTableValues = form.getFieldValue("rate_table") || [];
            const updatedRateTableValues = rateTableValues.map((item) => {
              item[columnName] = undefined;
              return item;
            });
            form.setFieldsValue({
              rate_table: updatedRateTableValues,
            });

            return row;
          });
          return updatedDataSource;
        });
      } else if (updateType === "column_name") {
        setRateTableDataSource((prevDataSource) => {
          const updatedDataSource = prevDataSource.map((row) => {
            const oldNameValue = row[columnName.old_name];
            row[columnName.new_name] = oldNameValue;
            return row;
          });
          return updatedDataSource;
        });
        setDatasourceObj((prevObj) => {
          const { [columnName.old_name]: removed, ...newState } = prevObj;
          return newState;
        });
      }
    }
  };

  useEffect(() => {
    console.log("columnDefDataSource: ", columnDefDataSource);
    if (state.flag !== "create") {
      const nonEmptyColumns = columnDefDataSource.filter(
        (column) =>
          column.column_name.trim().length > 0 &&
          column.column_type.trim().length > 0
      );

      const seenColumnNames = new Set();
      const uniqueColumns = nonEmptyColumns.filter((column) => {
        if (seenColumnNames.has(column.column_name)) {
          return false;
        }
        seenColumnNames.add(column.column_name);
        return true;
      });
      console.log("filteredDS: ", uniqueColumns);
      setRatecardData({
        ratecard_columns: uniqueColumns,
      });
    }
  }, [columnDefDataSource]);

  /*function to handle  the  clone columnname while entering
   *@param e - updated value while on change
   *@param key - corresponding row id
   */
  const handleColumnName = (e, key) => {
    console.log("columnKey: ", key);
    const newName = e.target.value.trim();
    console.log("newName: ", newName);
    if (newName.length !== 0) {
      setColumnDefDataSource((prev) => {
        const updatedArray = prev.map((item) => {
          if (item.key === key) {
            const selectedRow = JSON.parse(JSON.stringify(item));
            updateRateTableColumnDefinition(
              { old_name: selectedRow.column_name, new_name: newName },
              "column_name"
            );
          }
          return item.key === key ? { ...item, column_name: newName } : item;
        });
        columnDefDataSourceRef.current = updatedArray;
        return updatedArray;
      });
    }
  };

  /*function to handle  the  update column details while entering
   *@param value - updated selected value
   *@param key - corresponding row id
   */
  const handleColumnType = (value, key) => {
    const updatedArray = columnDefDataSource.map((obj) => {
      if (obj.key === key) {
        updateRateTableColumnDefinition(obj.column_name, "column_type");
        return { ...obj, column_type: value };
      }
      return obj;
    });
    columnDefDataSourceRef.current = updatedArray;
    setColumnDefDataSource(updatedArray);
  };

  // function handle the column details
  const defaultRateTableColumns = [
    {
      title: "Column Name",
      dataIndex: "column_name",
      align: "left",
      width: "19em",
      hidden: false,
      render: (index, record) => {
        const isDuplicate = columnDefDataSource.some(
          (item) =>
            item.column_name === record.column_name && item.key !== record.key
        );
        const isReadOnly = staticColumns.some(
          (item) =>
            item.column_name === record.column_name &&
            item.column_type === record.column_type
        );

        return (
          <>
            <Form.Item
              name={[record.key, "column_name"]}
              rules={[
                {
                  required: true,
                  message: "Enter column name",
                },
                () => ({
                  validator(_, value) {
                    const duplicates = columnDefDataSource.filter(
                      (item) =>
                        item.column_name === value && item.key !== record.key
                    );
                    if (duplicates.length > 0) {
                      return Promise.reject(
                        new Error("Ratecard column names must be unique")
                      );
                    } else if (
                      value.length === 0 ||
                      value.trim().length === 0
                    ) {
                      return Promise.reject(
                        new Error("Ratecard column name cannot be empty")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              //   initialValue={
              //     columnDefDataSource.find((obj) => obj.key === record.key)
              //       .column_name
              //   }
              initialValue={record.column_name}
              validateStatus={
                isDuplicate || record.column_name.trim().length === 0
                  ? "error"
                  : ""
              }
              help={
                isDuplicate
                  ? "Ratecard column names must be unique"
                  : record.column_name.trim().length === 0
                  ? "Ratecard column name cannot be empty"
                  : ""
              }
            >
              <Input
                readOnly={isReadOnly}
                onBlur={(e) => handleColumnName(e, record.key)}
                placeholder="Enter Column Name"
                style={
                  state.flag === "view" || isReadOnly
                    ? { backgroundColor: "var(--color-solid-whitegrey)" }
                    : {}
                }
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Column Type",
      dataIndex: "column_type",
      align: "left",
      width: "19em",
      hidden: false,
      render: (index, record) => {
        const isReadOnly = staticColumns.some(
          (item) =>
            item.column_name === record.column_name &&
            item.column_type === record.column_type
        );

        return (
          <Form.Item
            name={[record.key, "column_type"]}
            rules={[
              {
                required: true,
                message: "Select column type",
              },
            ]}
            // initialValue={
            //   columnDefDataSource.find((obj) => obj.key === record.key)
            //     .column_type
            // }
            initialValue={record.column_type}
            style={
              isReadOnly
                ? { backgroundColor: "var(--color-solid-whitegrey)" }
                : {}
            }
          >
            <Select
              disabled={isReadOnly}
              onChange={(value) => handleColumnType(value, record.key)}
              placeholder="Select Column Type"
            >
              <Select.Option value="free_text">Free Text</Select.Option>
              <Select.Option value="date_picker">Date Picker</Select.Option>
              <Select.Option value="currency">Currency</Select.Option>
              <Select.Option value="number">Number</Select.Option>
              {(record.column_name === "Rate Basis" ||
                record.column_name === "Rate Period") && (
                <Select.Option value="dropdown">Dropdown</Select.Option>
              )}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: <Image src={add} className="hide-add" preview={false} />,
      dataIndex: "action",
      align: "middle",
      width: 50,
      hidden: state.flag === "view",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // padding: "0px 10px 25px 0px",
          }}
        >
          {record.key > 6 && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteColumnDefinition(record.key)}
            >
              <a>
                <DeleteOutlined
                  style={{ fontSize: 16, marginTop: 2, marginRight: 10 }}
                />
              </a>
            </Popconfirm>
          )}
          {record.key > 6 && (
            <a>
              <Image
                title="Clone"
                src={duplicate}
                style={{ width: "20px", marginBottom: 2 }}
                preview={false}
                className="cursor-pointer"
                onClick={() => {
                  handleCloneColumnDefinition(record.key);
                }}
              />
            </a>
          )}
        </div>
      ),
    },
  ].filter((column) => !column.hidden);

  /* Function to handle edit value in column while existing is present
   */
  const columnDefinitionColumns = defaultRateTableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleColSave,
      }),
    };
  });

  /*function to handle supplier name input
   *@param key - coreesponding selected value id
   * param value- corresponding selcted value
   */
  const onChangeSupplier = (key, value) => {
    if (typeof value == "undefined") {
      mainForm.setFieldsValue({ msa: null });
      setRateCardField({
        ...rateCardField,
        supplier_id: null,
        msa: null,
        msaName: null,
      });
      setMsaData([]);
    } else {
      state.data && state.data.supplier_id !== value.key
        ? setDisable("")
        : setDisable(disableVal);
      setRateCardField({
        ...rateCardField,
        supplierName: value.value,
        supplier_id: value.key,
      });
      if (state.data.supplier_id !== value.key) {
        mainForm.setFieldsValue({ msa: null });
      }
      getAllMsaApi().then((res) => {
        let filteredMsa = res.filter((data) => data.supplier_id === value.key);
        setMsaData(
          filteredMsa
            ? filteredMsa
            : res.docs.filter((data) => data.supplier_id === value.key)
        );
      });
    }
  };

  /*function to handle MSA NAME input
   *@param key - coreesponding selected value id
   * param value- corresponding selcted value
   */
  const onChangeMsa = (key, value) => {
    if (typeof value == "undefined") {
      mainForm.setFieldsValue({ msa: null, msaName: null });
    } else {
      setRateCardField({
        ...rateCardField,
        msaName: value.label,
        msa: value.key,
      });
    }
    key !== undefined && state.data && state.data.msa !== key
      ? setDisable("")
      : setDisable(disableVal);
  };

  let rateTableColumnDescription =
    ratecardData &&
    ratecardData.ratecard_columns &&
    ratecardData.ratecard_columns.map((data) => {
      return {
        title: data.column_name,
        dataIndex: data.column_name,
        align: "left",
        ellipsis: true,
        render: (index, record) => {
          return (
            <>
              {data.column_type === "free_text" && (
                <Form.Item
                  name={[record.key, data.column_name]}
                  rules={
                    buttonState === 0 || buttonState === 2
                      ? [
                          {
                            required: true,
                            message: `${data.column_name} is required`,
                          },
                        ]
                      : []
                  }
                  initialValue={record[data.column_name]}
                >
                  <Input
                    readOnly={state.flag === "view"}
                    onBlur={(e) =>
                      handleSaveRateTableCell(
                        e.target.value,
                        record.key,
                        data.column_name,
                        data.column_type
                      )
                    }
                    placeholder={`Enter ${data.column_name}`}
                  />
                </Form.Item>
              )}

              {data.column_type === "date_picker" && (
                <Form.Item
                  name={[record.key, data.column_name]}
                  rules={
                    buttonState === 0 || buttonState === 2
                      ? [
                          {
                            required: true,
                            message: `${data.column_name} is required`,
                          },
                        ]
                      : []
                  }
                  initialValue={
                    record[data.column_name]
                      ? dayjs(record[data.column_name])
                      : undefined
                  }
                >
                  <DatePicker
                    disabled={state.flag === "view"}
                    onChange={(value) =>
                      handleSaveRateTableCell(
                        value ? dayjs(value, "DD MMM YYYY") : undefined,
                        record.key,
                        data.column_name,
                        data.column_type
                      )
                    }
                    className="basic-input"
                    placeholder={`Select ${data.column_name}`}
                    format={"DD MMM YYYY"}
                  />
                </Form.Item>
              )}

              {data.column_type === "dropdown" &&
                data.column_name === "Rate Basis" && (
                  <Form.Item
                    name={[record.key, data.column_name]}
                    rules={
                      buttonState === 0 || buttonState === 2
                        ? [
                            {
                              required: true,
                              message: `${data.column_name} is required`,
                            },
                          ]
                        : []
                    }
                    initialValue={record[data.column_name]}
                  >
                    <Select
                      disabled={state.flag === "view"}
                      onChange={(value) => handleRateBasis(value, record.key)}
                      placeholder="Select Rate Basis"
                      allowClear
                    >
                      <Select.Option value="Days Based">
                        Days Based
                      </Select.Option>
                      <Select.Option value="FTE Based">FTE Based</Select.Option>
                    </Select>
                  </Form.Item>
                )}

              {data.column_type === "dropdown" &&
                data.column_name === "Rate Period" && (
                  <Form.Item
                    name={[record.key, data.column_name]}
                    rules={
                      buttonState === 0 || buttonState === 2
                        ? [
                            {
                              required: true,
                              message: `${data.column_name} is required`,
                            },
                          ]
                        : []
                    }
                    initialValue={record[data.column_name]}
                  >
                    <Select
                      disabled={state.flag === "view"}
                      onChange={(value) => handleRatePeriod(value, record.key)}
                      placeholder="Select Rate Period"
                      allowClear
                      options={
                        selectRatePeriod === "Days Based"
                          ? [
                              {
                                value: "Day Rate",
                                label: "Day Rate",
                              },
                            ]
                          : selectRatePeriod === "FTE Based"
                          ? [
                              {
                                value: "Monthly Rate",
                                label: "Monthly Rate",
                              },
                              {
                                value: "Yearly Rate",
                                label: "Yearly Rate",
                              },
                            ]
                          : ""
                      }
                    />
                  </Form.Item>
                )}

              {data.column_type === "currency" && (
                <Form.Item
                  name={[record.key, data.column_name]}
                  rules={
                    buttonState === 0 || buttonState === 2
                      ? [
                          {
                            required: true,
                            message: `${data.column_name} is required`,
                          },
                        ]
                      : []
                  }
                  initialValue={record[data.column_name]}
                >
                  <Select
                    disabled={state.flag === "view"}
                    placeholder={`Select ${data.column_name}`}
                    optionLabelProp="label"
                    allowClear
                    showSearch={true}
                    onSelect={(value) => {
                      handleSaveRateTableCell(
                        value,
                        record.key,
                        data.column_name,
                        data.column_type
                      );
                    }}
                    style={{ width: "100%" }}
                    options={ContractCurrency.map((item) => ({
                      label: `${item.country} - ${item.currency_code}`,
                      value: `${item.country} - ${item.currency_code}`,
                    }))}
                  ></Select>
                </Form.Item>
              )}

              {data.column_type === "number" && (
                <Form.Item
                  name={[record.key, data.column_name]}
                  rules={
                    buttonState === 0 || buttonState === 2
                      ? [
                          {
                            required: true,
                            message: `Enter ${data.column_name}`,
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: `Enter correct value`,
                          },
                        ]
                      : []
                  }
                  initialValue={record[data.column_name]}
                >
                  <InputNumber
                    readOnly={state.flag === "view"}
                    controls={false}
                    style={{ width: "100%" }}
                    formatter={(value) => {
                      return isNaN(value) ? "" : `${value}`;
                    }}
                    parser={(value) => value.replace(/[^0-9]/g, "")}
                    onBlur={(e) =>
                      handleSaveRateTableCell(
                        e.target.value,
                        record.key,
                        data.column_name,
                        data.column_type
                      )
                    }
                    placeholder={`Enter ${data.column_name}`}
                  />
                </Form.Item>
              )}
            </>
          );
        },
      };
    });

  if (state.flag !== "view") {
    rateTableColumnDescription &&
      rateTableColumnDescription.push({
        title: <Image src={add} className="hide-add" preview={false} />,
        dataIndex: "action",
        align: "middle",
        width: 70,
        render: (_, record, index) => (
          <div style={{ textAlign: "center" }}>
            {rateTableDataSource.length > 1 && (
              <Popconfirm
                disabled={rateTableDataSource.length === 1}
                title="Sure to delete?"
                onConfirm={() => handleDeleteRateTableRow(record.key, index)}
              >
                <a>
                  <DeleteOutlined
                    style={{ fontSize: 16, marginTop: 2, marginRight: 10 }}
                  />
                </a>
              </Popconfirm>
            )}
            <a>
              <Image
                title="Clone"
                src={duplicate}
                style={{ width: "20px", marginBottom: 2 }}
                preview={false}
                className="cursor-pointer"
                onClick={() => {
                  handleCloneRateTableRow(record.key);
                }}
              />
            </a>
          </div>
        ),
      });
  }

  useEffect(() => {
    console.log("rateTableDataSource: ", rateTableDataSource);
  }, [rateTableDataSource]);

  useEffect(() => {
    console.log("datasourceObj: ", datasourceObj);
    if (
      rateTableDataSource.length === 0 &&
      Object.keys(datasourceObj).length !== 0
    ) {
      setRateTableDataSource([
        ...rateTableDataSource,
        { ...datasourceObj, key: 0 },
      ]);
      setRateTableCount(rateTableCount + 1);
    }
  }, [datasourceObj]);

  const processRateTableData = () => {
    let colKeys = columnDefDataSourceRef.current.map(
      (data) => data.column_name
    );

    return rateTableDataSourceRef.current.map((item) => {
      let orderedItem = {};
      colKeys.forEach((key) => {
        if (item) {
          if (!item.hasOwnProperty(key)) {
            orderedItem[key] = "";
          } else if (item[key] === undefined) {
            orderedItem[key] = "";
          } else {
            orderedItem[key] = item[key];
          }
        }
      });

      return orderedItem;
    });
  };

  const handleScrollToErrorField = (
    tabKey,
    errorSelector,
    retries = 10,
    delay = 150
  ) => {
    const activeTabContent = document.querySelector(
      `[id^="rc-tabs-"][id$="-panel-${tabKey}"]`
    );
    if (activeTabContent) {
      const tableElement = activeTabContent.querySelector(
        "#setup-data-table-form"
      );
      if (tableElement) {
        const errorItems = [...tableElement.querySelectorAll(errorSelector)];

        if (errorItems.length > 0) {
          const firstErrorItem = errorItems[0];

          setTimeout(() => {
            firstErrorItem.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }, 250);
        }
      } else {
        console.error("Table element not found for scrolling to error field");
      }
    } else if (retries > 0) {
      console.log("retries: ", retries);
      setTimeout(() => {
        handleScrollToErrorField(tabKey, errorSelector, retries - 1, delay);
      }, delay);
    }
  };

  /* Function to handle next form
   * @paran value - collection of component data in value
   */
  const onFinish = async (values) => {
    if (state.flag === "create") {
      console.log("values: ", values);
      values["author"] =
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "";
      values["author_name"] =
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "";
      values["comments"] = comments.length !== 0 ? comments : [];

      const uploadForm = new FormData();
      if (rcFile && rcFile.length > 0) {
        for (let i = 0; i < rcFile.length; i++) {
          uploadForm.append("notesCommentsFiles", rcFile[i].originFileObj);
        }
      }
      if (rateTableFile && rateTableFile.length > 0) {
        for (let i = 0; i < rateTableFile.length; i++) {
          uploadForm.append("rateTableFile", rateTableFile[i].originFileObj);
        }
      }

      if (buttonState === 1) {
        //Executes when Save button is pressed
        if (rateTableFile.length === 0) {
          setSaveTableLoader(true);

          values["status"] = "In Draft";
          values["supplier_id"] = rateCardField.supplier_id;
          values["updated_by"] = userData[0].id;
          values["rate_table"] = processRateTableData();

          console.log("Processed rate_table: ", values.rate_table);

          if (values.hasOwnProperty("ratecard_columns")) {
            values.ratetable_columns = columnDefDataSourceRef.current;
            delete values.ratecard_columns;
          } else {
            values.ratetable_columns = columnDefDataSourceRef.current;
          }
          if (fileKeysToDelete.length !== 0) {
            fileKeysToDelete.map(async (fileKey) => {
              await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
            });
          }
          if (rcId === "") {
            values["created_by"] = userData[0].id;
            console.log("newValues freshSave: ", values);
            uploadForm.append("bodyData", JSON.stringify(values));
            createRatecardAPi(uploadForm).then((res) => {
              if (res.status === 304) {
                popupModalInstance.errorModal(
                  ModalMessage.RateCard_Create_Error
                );
                setSaveTableLoader(false);
              } else {
                setRcId(res.data.id ? res.data.id : res.data._id);
                let ssLog = {
                  ss_id: res.data.id ? res.data.id : res.data._id,
                  ss_name: values["ratetable_name"],
                  code: "rate_card",
                  author: userData[0].id,
                  action: "view",
                  notes: "Saved Rate Card",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                setSaveTableLoader(false);
                popupModalInstance.successModal(
                  res.data.ratetable_name,
                  ModalMessage.RC_Create_Save,
                  ``
                );
              }
            });
          } else {
            console.log("newValues alreadySaved: ", values);
            uploadForm.append("bodyData", JSON.stringify(values));
            editRateTableDataAPi(rcId, uploadForm).then((res) => {
              console.log("resss", res);
              setRcId(res.data.id ? res.data.id : res.data._id);
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["ratetable_name"],
                code: "rate_card",
                author: userData[0].id,
                action: "edit",
                notes: "Saved Rate Card",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                res.data.ratetable_name,
                ModalMessage.RC_Create_Save,
                ``
              );
            });
          }
        } else {
          setSaveTableLoader(true);
          values["ratetable_file"] =
            rateTableFile &&
            rateTableFile.length > 0 &&
            rateTableFile[0].originFileObj;
          values["status"] = "In Draft";
          values["supplier_id"] = rateCardField.supplier_id;
          values["updated_by"] = userData[0].id;

          if (fileKeysToDelete.length !== 0) {
            fileKeysToDelete.map(async (fileKey) => {
              await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
            });
          }

          if (rcId === "") {
            values["created_by"] = userData[0].id;
            uploadForm.append("bodyData", JSON.stringify(values));
            createRatecardAPi(uploadForm).then((res) => {
              console.log("resss", res);

              if (res.status === 304) {
                popupModalInstance.errorModal(
                  ModalMessage.RateCard_Create_Error
                );
                setSaveTableLoader(false);
              } else {
                if (res.status === 201) {
                  setRcId(res.data._id);
                  let ssLog = {
                    ss_id: res.data.id ? res.data.id : res.data._id,
                    ss_name: values["ratetable_name"],
                    code: "rate_card",
                    author: userData[0].id,
                    action: "view",
                    notes: "Created Rate Card",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog);
                  setSaveTableLoader(false);
                  popupModalInstance.successModal(
                    res.data.ratetable_name,
                    ModalMessage.RC_Create_Save,
                    ``
                  );
                } else {
                  popupModalInstance
                    .errorModal(res.message, "")
                    .then(async (userChoice) => {
                      if (userChoice === "ok") {
                        setSaveTableLoader(false);
                      }
                    });
                }
              }
            });
          } else {
            uploadForm.append("bodyData", JSON.stringify(values));
            editRateTableDataAPi(rcId, uploadForm).then((res) => {
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["ratetable_name"],
                code: "rate_card",
                author: userData[0].id,
                action: "edit",
                notes: "Edited Rate Card",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              if (res) {
                setSaveTableLoader(false);
                popupModalInstance.successModal(
                  res.data.ratetable_name,
                  ModalMessage.RC_Create_Save,
                  ``
                );
              } else {
                setSaveTableLoader(false);
                Modal.error({
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: <div>Can't Update. Please try again later.</div>,
                  onOk() {
                    setSaveTableLoader(false);
                    return;
                  },
                });
              }
            });
          }
        }
        return;
      } //Exits after saving
      if (!rateTableFile.length) {
        //Executes when Next button is pressed
        let customColumnDescription;
        if (createMethod === "table") {
          customColumnDescription = columnDefDataSource.map(
            ({ key, ...column }) => column
          );
        }
        setFormLevel(formLevel === "ColumnDefine" ? "Submit" : "ColumnDefine");
        customColumnDescription.forEach((data) =>
          setDatasourceObj((datasourceObj) => ({
            ...datasourceObj,
            [data.column_name]: undefined,
          }))
        );
        setRatecardData((data) => ({
          ...data,
          msa: rateCardField.msa,
          ratecard_columns: customColumnDescription,
          ratetable_name: values.ratetable_name,
          supplier_name: rateCardField.supplierName,
          supplier_id: rateCardField.supplier_id,
          start_date: values.start_date,
          end_date: values.end_date,
        }));
      } else {
        setLoader(true);
        // values["msa_name"] = msaData[0] ? msaData[0].contract_name : undefined;
        values["ratetable_file"] =
          rateTableFile &&
          rateTableFile.length > 0 &&
          rateTableFile[0].originFileObj;
        values["status"] = "Active";
        values["supplier_id"] = rateCardField.supplier_id;
        values["created_by"] = userData[0].id;
        values["updated_by"] = userData[0].id;
        uploadForm.append("bodyData", JSON.stringify(values));
        popupModalInstance
          .infoModal(ModalMessage.RC_Create_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              createRatecardAPi(uploadForm).then((res) => {
                if (res.status === 304) {
                  popupModalInstance.errorModal(
                    ModalMessage.RateCard_Create_Error
                  );
                  setLoader(false);
                } else {
                  if (res.status === 201) {
                    let ssLog = {
                      ss_id: res.data.id ? res.data.id : res.data._id,
                      ss_name: values["ratetable_name"],
                      code: "rate_card",
                      author: userData[0].id,
                      action: "view",
                      notes: "Created Rate Card",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog);
                    setLoader(false);
                    history("/system-setup/?back=con-rcs");
                  } else {
                    popupModalInstance
                      .errorModal(res.message, "")
                      .then(async (userChoice) => {
                        if (userChoice === "ok") {
                          setLoader(false);
                        }
                      });
                  }
                }
              });
            }
            if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      }
    } else if (state.flag === "edit") {
      console.log("firstValues: ", values);
      let newValues = {
        msa:
          rateCardField.msa === null || rateCardField.msa === undefined
            ? "N/A"
            : rateCardField.msa,
        msa_name:
          rateCardField.msaName === undefined || rateCardField.msaName === null
            ? "N/A"
            : rateCardField.msaName,
        ratetable_name: values.ratetable_name,
        start_date: values.start_date,
        end_date: values.end_date,
        status:
          state.data && state.data.status === "In Draft"
            ? buttonState === 1
              ? "In Draft"
              : "Active"
            : buttonState === 1
            ? "In Draft"
            : state.data.status,
        supplier_id: rateCardField.supplier_id,
        supplier_name: values.supplier_name,
        rate_table: processRateTableData(),
        ratetable_columns: columnDefDataSource,
        updated_by: userData[0].id,
      };
      newValues["author"] =
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "";
      newValues["author_name"] =
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "";
      newValues["comments"] = comments.length !== 0 ? comments : [];

      const uploadForm = new FormData();
      if (rcFile && rcFile.length > 0) {
        for (let i = 0; i < rcFile.length; i++) {
          uploadForm.append("notesCommentsFiles", rcFile[i].originFileObj);
        }
      }
      if (rateTableFile && rateTableFile.length > 0) {
        for (let i = 0; i < rateTableFile.length; i++) {
          uploadForm.append("rateTableFile", rateTableFile[i].originFileObj);
        }
      }

      if (buttonState === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        setSaveTableLoader(true);
        console.log("newValues alreadySaved: ", values);
        uploadForm.append("bodyData", JSON.stringify(newValues));
        editRateTableDataAPi(state.data && state.data._id, uploadForm).then(
          (res) => {
            console.log("resss", res);
            let ssLog = {
              ss_id: state.data && state.data._id,
              ss_name: values["ratetable_name"],
              code: "rate_card",
              author: userData[0].id,
              action: "edit",
              notes: "Saved Rate Card",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              values["ratetable_name"],
              ModalMessage.RC_Create_Save,
              ``
            );
          }
        );
      }
      if (buttonState === 2) {
        setLoader(true);
        uploadForm.append("bodyData", JSON.stringify(newValues));
        popupModalInstance
          .infoModal(ModalMessage.RC_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              editRateTableDataAPi(
                state.data && state.data._id,
                uploadForm
              ).then((res) => {
                let ssLog = {
                  ss_id: state.data && state.data._id,
                  ss_name: values["ratetable_name"],
                  code: "rate_card",
                  author: userData[0].id,
                  action: "edit",
                  notes: "Edited Rate Card",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                if (res) {
                  setLoader(false);
                  setSaveTableLoader(false);
                  history(`/system-setup/?back=con-rcs`);
                } else {
                  setLoader(false);
                  setSaveTableLoader(false);
                  Modal.error({
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: <div>Can't Update. Please try again later.</div>,
                    onOk() {
                      history(`/system-setup/?back=con-rcs`);
                    },
                  });
                }
              });
            }
            if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      }
    } else {
      values["author"] =
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "";
      values["author_name"] =
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "";
      values["comments"] = comments.length !== 0 ? comments : [];

      let data = {
        ratetable_name: values.ratetable_name,
        supplier_id: rateCardField.supplier_id,
        supplier_name: rateCardField.supplierName,
        msa: rateCardField.msa,
        start_date: values.start_date,
        end_date: values.end_date,
        msa_name: rateCardField.msaName,
        status: buttonState === 1 ? "In Draft" : "Active",
        rate_table: processRateTableData(),
        ratetable_columns: columnDefDataSource,
        updated_by: userData[0].id,
        comments: values.comments,
      };

      const uploadForm = new FormData();
      if (rcFile && rcFile.length > 0) {
        for (let i = 0; i < rcFile.length; i++) {
          uploadForm.append("notesCommentsFiles", rcFile[i].originFileObj);
        }
      }
      if (rateTableFile && rateTableFile.length > 0) {
        for (let i = 0; i < rateTableFile.length; i++) {
          uploadForm.append("rateTableFile", rateTableFile[i].originFileObj);
        }
      }

      console.log("data: ", data);
      if (buttonState === 1) {
        setSaveTableLoader(true);
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        if (rcId === "") {
          data["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(data));
          console.log("newValues freshSave: ", values);
          createRatecardAPi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(ModalMessage.RateCard_Create_Error);
              setSaveTableLoader(false);
            } else {
              console.log("resss data", res);
              setRcId(res.data.id ? res.data.id : res.data._id);
              let ssLog = {
                ss_id: res.data.id ? res.data.id : res.data._id,
                ss_name: values["ratetable_name"],
                code: "rate_card",
                author: userData[0].id,
                action: "view",
                notes: "Created Rate Card",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                res.data.ratetable_name,
                ModalMessage.RC_Create_Save,
                ``
              );
            }
          });
        } else {
          console.log("newValues alreadySaved: ", values);
          uploadForm.append("bodyData", JSON.stringify(data));
          editRateTableDataAPi(rcId, uploadForm).then((res) => {
            console.log("resss", res);
            setRcId(res.data.id ? res.data.id : res.data._id);
            let ssLog = {
              ss_id: rcId,
              ss_name: values["ratetable_name"],
              code: "rate_card",
              author: userData[0].id,
              action: "edit",
              notes: "Saved Rate Card",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              res.data.ratetable_name,
              ModalMessage.RC_Create_Save,
              ``
            );
          });
        }
      }
      if (buttonState === 2) {
        setLoader(true);
        if (rcId === "") {
          data["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(data));
          popupModalInstance
            .infoModal(ModalMessage.RC_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                createRatecardAPi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    popupModalInstance.errorModal(
                      ModalMessage.RateCard_Create_Error
                    );
                    setLoader(false);
                  } else {
                    console.log("ress data", res);
                    setRcId(res.data.id ? res.data.id : res.data._id);
                    let ssLog = {
                      ss_id: res.data.id ? res.data.id : res.data._id,
                      ss_name: data["ratetable_name"],
                      code: "rate_card",
                      author: userData[0].id,
                      action: "view",
                      notes: "Created Rate Card",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog);
                    console.log("res", res);
                    setLoader(false);
                    setSaveTableLoader(false);
                    history(`/system-setup/?back=con-rcs`);
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(data));
          popupModalInstance
            .infoModal(ModalMessage.RC_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                editRateTableDataAPi(rcId, uploadForm).then((res) => {
                  let ssLog = {
                    ss_id: rcId,
                    ss_name: values["ratetable_name"],
                    code: "rate_card",
                    author: userData[0].id,
                    action: "edit",
                    notes: "Edited Rate Card",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog);
                  if (res) {
                    setLoader(false);
                    setSaveTableLoader(false);
                    history(`/system-setup/?back=con-rcs`);
                  } else {
                    setLoader(false);
                    setSaveTableLoader(false);
                    Modal.error({
                      className: "popup-model-success",
                      centered: true,
                      icon: false,
                      content: <div>Can't Update. Please try again later.</div>,
                      onOk() {
                        history(`/system-setup/?back=con-rcs`);
                      },
                    });
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        }
      }
    }
  };

  /* Function to handle submit form failed
   *@ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    if (state.flag === "create") {
      console.log("Failed:", errorInfo);
      const tableElement = document.querySelector(".data-table");
      if (tableElement) {
        const errorItems = tableElement.querySelectorAll(
          ".ant-form-item-has-error"
        );
        if (errorItems.length > 0) {
          errorItems[0].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    } else if (state.flag === "edit" || state.flag === "clone") {
      const { errorFields } = errorInfo;
      console.log("errorFields: ", errorFields);
      const rateColumnsErrorFields = errorFields.find(
        (field) => field.name[0] === "ratetable_columns"
      );
      const rateTableErrorFields = errorFields.find(
        (field) => field.name[0] === "rate_table"
      );
      if (rateColumnsErrorFields) {
        setTabKey("2");
        handleScrollToErrorField(
          "2",
          ".ant-input-status-error, .ant-select-status-error"
        );
      } else if (rateTableErrorFields) {
        setTabKey("3");
        handleScrollToErrorField(
          "3",
          ".ant-input-status-error, .ant-input-number-status-error, .ant-select-status-error, .ant-picker-status-error"
        );
      }
    }
  };

  const onSubmit = (values) => {
    console.log("values onSubmit: ", values);
    console.log("dataSourceObj onSubmit: ", datasourceObj);

    values.rate_table = processRateTableData();

    console.log("Processed rate_table: ", values.rate_table);

    if (buttonState === 1) {
      setSaveTableLoader(true);
    } else {
      setLoader(true);
    }
    setRatecardData((data) => ({
      ...data,
      ratecard_data: values,
    }));

    console.log("ratecardData", ratecardData);

    let data = {
      ratetable_name: ratecardData.ratetable_name,
      supplier_id: rateCardField.supplier_id,
      supplier_name: ratecardData.supplier_name,
      msa: ratecardData.msa,
      start_date: ratecardData.start_date,
      end_date: ratecardData.end_date,
      msa_name: msaData[0] ? msaData[0].contract_name : "N/A",
      status: buttonState === 1 ? "In Draft" : "Active",
      rate_table: values.rate_table.filter((item) => item !== undefined),
      ratetable_columns: ratecardData.ratecard_columns,
      // created_by: userData[0].id,
      updated_by: userData[0].id,
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      comments: comments.length !== 0 ? comments : [],
    };
    const uploadForm = new FormData();
    if (rcFile && rcFile.length > 0) {
      for (let i = 0; i < rcFile.length; i++) {
        uploadForm.append("notesCommentsFiles", rcFile[i].originFileObj);
      }
    }
    if (rateTableFile && rateTableFile.length > 0) {
      for (let i = 0; i < rateTableFile.length; i++) {
        uploadForm.append("rateTableFile", rateTableFile[i].originFileObj);
      }
    }

    console.log("onSubmit data: ", data);

    if (rcId === "") {
      data["created_by"] = userData[0].id;
      uploadForm.append("bodyData", JSON.stringify(data));
      popupModalInstance
        .infoModal(ModalMessage.RC_Create_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            if (fileKeysToDelete.length !== 0) {
              fileKeysToDelete.map(async (fileKey) => {
                await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
              });
            }
            createRatecardAPi(uploadForm).then((res) => {
              if (res.status === 304) {
                popupModalInstance.errorModal(
                  ModalMessage.RateCard_Create_Error
                );
                setLoader(false);
              } else {
                setRcId(res.data.id ? res.data.id : res.data._id);
                let ssLog = {
                  ss_id: res.data.id ? res.data.id : res.data._id,
                  ss_name: data["ratetable_name"],
                  code: "rate_card",
                  author: userData[0].id,
                  action: "view",
                  notes: "Created Rate Card",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                console.log("res", res);
                setLoader(false);
                setSaveTableLoader(false);
                if (buttonState !== 1) {
                  history(`/system-setup/?back=con-rcs`);
                } else {
                  popupModalInstance.successModal(
                    res.data.ratetable_name,
                    ModalMessage.RC_Create_Save,
                    ``
                  );
                }
              }
            });
          }
          if (userChoice === "cancel") {
            setLoader(false);
          }
        });
    } else {
      uploadForm.append("bodyData", JSON.stringify(data));
      popupModalInstance
        .infoModal(ModalMessage.RC_Edit_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            if (fileKeysToDelete.length !== 0) {
              fileKeysToDelete.map(async (fileKey) => {
                await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
              });
            }
            editRateTableDataAPi(rcId, uploadForm).then((res) => {
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["ratetable_name"],
                code: "rate_card",
                author: userData[0].id,
                action: "edit",
                notes: "Edited Rate Card",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              if (res) {
                setLoader(false);
                setSaveTableLoader(false);
                if (buttonState !== 1) history(`/system-setup/?back=con-rcs`);
                else {
                  popupModalInstance.successModal(
                    res.data.ratetable_name,
                    ModalMessage.RC_Create_Save,
                    ``
                  );
                }
              } else {
                setLoader(false);
                setSaveTableLoader(false);
                Modal.error({
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: <div>Can't Update. Please try again later.</div>,
                  onOk() {
                    if (buttonState !== 1)
                      history(`/system-setup/?back=con-rcs`);
                  },
                });
              }
            });
          }
          if (userChoice === "cancel") {
            setLoader(false);
            setSaveTableLoader(false);
          }
        });
    }
  };

  /* Function to handle submit form failed
  @ param errorInfo- getting error information
   */
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formSubmitHandler = () => {
    if (formLevel === "") {
      mainFormRef.current.submit();
    }
    if (formLevel === "ColumnDefine") {
      subFormRef.current.submit();
    }
  };

  // function to handle the compunt design in rate card
  const ratecardCover = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <>
        <div className="org-view-div">
          {formLevel === "" && (
            <>
              <Row align="middle" type="flex" className="m-20">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="ratetable_name"
                        label="RATE TABLE NAME"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "Rate Table Name is required",
                            pattern: /^(?!\s*$).+/,
                          },
                        ]}
                      >
                        <Input
                          disabled={state.flag === "view"}
                          placeholder="Enter your title"
                          onChange={(e) => {
                            setRateCardField({
                              ...rateCardField,
                              rateCardName: e.target.value,
                            });
                            e.target.value !== "" &&
                            state.data &&
                            state.data.ratetable_name !== e.target.value
                              ? setDisable("")
                              : setDisable(disableVal);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="supplier_name"
                        label="SUPPLIER NAME"
                        colon={false}
                        rules={[
                          {
                            message: "Supplier Name is required",
                            pattern: /^(?!\s*$).+/,
                          },
                        ]}
                      >
                        {state.flag === "view" ? (
                          <Input
                            disabled={state.flag === "view"}
                            placeholder="Choose Supplier"
                          ></Input>
                        ) : (
                          <Select
                            disabled={state.flag === "view"}
                            allowClear
                            onChange={(key, value) =>
                              onChangeSupplier(key, value)
                            }
                            notFoundContent={
                              supplierListLoader && <LoadingOutlined />
                            }
                            showSearch={true}
                            placeholder="Choose Supplier"
                            options={
                              supplierData &&
                              supplierData.map((data) => ({
                                key: data._id,
                                label: data.supplier_name,
                                value: data.supplier_name,
                              }))
                            }
                          ></Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="msa"
                        label="MSA"
                        colon={false}
                      >
                        {state.flag === "view" ? (
                          <Input
                            disabled={state.flag === "view"}
                            placeholder="Choose MSA"
                          ></Input>
                        ) : (
                          <Select
                            disabled={state.flag === "view"}
                            allowClear
                            onChange={onChangeMsa}
                            showSearch={true}
                            placeholder="Choose MSA"
                            options={
                              msaData &&
                              msaData.map((data) => ({
                                key: data.id,
                                label: data.contract_name,
                                value: data.id,
                              }))
                            }
                          ></Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="start_date"
                        label="START DATE"
                        colon={false}
                        // rules={[
                        //   {
                        //     required:true,
                        //     message: "Start Date is required",
                        //   },
                        // ]}
                      >
                        {state.flag === "view" ? (
                          <div className="formlayout-div">
                            {state.data && state.data.start_date
                              ? dayjs(state.data.start_date).format(
                                  "DD MMM YYYY"
                                )
                              : "Not Selected"}
                          </div>
                        ) : (
                          <DatePicker
                            onChange={(date, dateString) =>
                              onDateChange(date, dateString, "start_date")
                            }
                            format={"DD MMM YYYY"}
                            className="report-input"
                            placeholder="Select Start Date"
                            disabledDate={(dateValue) =>
                              disabledDate(dateValue, "start_date")
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="end_date"
                        label="END DATE"
                        colon={false}
                      >
                        {state.flag === "view" ? (
                          <div className="formlayout-div">
                            {state.data && state.data.end_date
                              ? dayjs(state.data.end_date).format("DD MMM YYYY")
                              : "Not Selected"}
                          </div>
                        ) : (
                          <DatePicker
                            onChange={(date, dateString) =>
                              onDateChange(date, dateString, "end_date")
                            }
                            format={"DD MMM YYYY"}
                            className="report-input"
                            placeholder="Select End Date"
                            disabledDate={(dateValue) =>
                              disabledDate(dateValue, "end_date")
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  {state.flag === "create" && (
                    <>
                      {createMethod === "upload" && (
                        <>
                          <div className="create-upload-entity-div">
                            <Row
                              align="middle"
                              type="flex"
                              style={{ padding: "10px 30px 0px" }}
                            >
                              <Col
                                span={24}
                                align="middle"
                                className="vertical-align"
                              >
                                <h4 className="entity-text">
                                  Enter Rate Card Details
                                </h4>
                              </Col>
                            </Row>
                            <hr className="mt-10 view-supplier-hr" />
                            <Row
                              align="middle"
                              gutter={[40, 16]}
                              justify="center"
                              className="mt-25"
                            >
                              <Col
                                span={8}
                                align="right"
                                className="vertical-align"
                              >
                                <div className="choose-input-div">
                                  <Form.Item
                                    rules={[
                                      {
                                        required: uploadRule,
                                        message: uploadRuleText,
                                      },
                                    ]}
                                  >
                                    <Row type="flex" gutter={[64, 56]}>
                                      <Col
                                        span={24}
                                        align="middle"
                                        className="vertical-align"
                                      >
                                        <span className="upload-note">
                                          Click below to enter Rate Card details
                                          through Excel
                                        </span>
                                      </Col>
                                      <Col span={24} align="middle">
                                        <Upload
                                          {...excelUploadProps}
                                          customRequest={dummyRequest}
                                        >
                                          <Col
                                            span={10}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <ExcelButton />
                                          </Col>
                                        </Upload>
                                      </Col>
                                      <Col
                                        span={24}
                                        className="vertical-align"
                                        align="middle"
                                      >
                                        <div>
                                          <p className="upload-note">
                                            Download &nbsp;&nbsp;
                                            <Link
                                              to={rateCardTemplate}
                                              download="Rate_Card_Template"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Rate Card Details Excel Template
                                            </Link>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col
                                span={8}
                                align="left"
                                className="vertical-align"
                              >
                                <div className="choose-input-div">
                                  <Row
                                    type="flex"
                                    gutter={
                                      rateTableFile.length > 0
                                        ? [64, 92]
                                        : [64, 56]
                                    }
                                  >
                                    <Col
                                      span={24}
                                      align="middle"
                                      className="vertical-align"
                                    >
                                      <span className="upload-note">
                                        Click below to enter Rate Card details
                                        through Online Mode
                                      </span>
                                    </Col>
                                    <Col span={24} align="middle">
                                      <Col
                                        span={10}
                                        align="middle"
                                        className="vertical-align"
                                      >
                                        <TableButton
                                          onClick={() => {
                                            setRateTableFile([]);
                                            setChanged(true);
                                            setCreateMethod("table");
                                          }}
                                        />
                                      </Col>
                                    </Col>
                                    <Col span={24}>
                                      <div style={{ height: 46 }}></div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                      {createMethod === "table" && (
                        <>
                          <div className="create-entity-div">
                            <Row
                              align="middle"
                              type="flex"
                              style={{ padding: "10px 30px 0px" }}
                            >
                              <Col span={19} className="vertical-align">
                                <h4 className="entity-text">
                                  Rate Card Details
                                </h4>
                              </Col>
                            </Row>
                            <hr className="mt-10 view-supplier-hr" />
                            <Row
                              style={{
                                padding: "19px 0px 29px 15px",
                              }}
                            >
                              <p className="upload-note">
                                If you want to enter Rate Card Details by
                                uploading your file, then&nbsp;&nbsp;
                                <a
                                  onClick={() => {
                                    setChanged(false);
                                    setCreateMethod("upload");
                                  }}
                                >
                                  click here
                                </a>
                              </p>
                            </Row>
                          </div>
                          <div>
                            <Row
                              align="middle"
                              type="flex"
                              gutter={[8, 16]}
                              className="m-0"
                            >
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row
                                  align="middle"
                                  type="flex"
                                  style={{
                                    padding: "25px 25px 25px",
                                  }}
                                >
                                  <Col span={24} className="vertical-align">
                                    <h4 className="entity-text">
                                      Create Rate Card Columns
                                    </h4>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={12} offset={6}>
                                    <Form.List name="ratetable_columns">
                                      {() => (
                                        <Table
                                          bordered
                                          dataSource={columnDefDataSource}
                                          columns={columnDefinitionColumns}
                                          scroll={{
                                            x: "calc(300px + 50%)",
                                            y: 400,
                                          }}
                                          pagination={false}
                                          id={`${setupDataTableForm}`}
                                          className={`${dataTable} mt-10`}
                                        />
                                      )}
                                    </Form.List>
                                  </Col>
                                </Row>
                                <div className="text-center mt-10">
                                  <Button
                                    onClick={handleAddColumnDefinition}
                                    shape="round"
                                    className="add-btn mb-10"
                                    size="medium"
                                    style={{
                                      width: 90,
                                    }}
                                  >
                                    <PlusOutlined
                                      style={{
                                        color: "#9b9b9b",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          {(formLevel === "ColumnDefine" || formLevel === "Submit") && (
            <>
              <Form
                ref={subFormRef}
                form={subForm}
                name="create-ratecard-2"
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
              >
                <Row>
                  <Col span={18} offset={3}>
                    <Form.List name="rate_table">
                      {() => (
                        <Table
                          dataSource={rateTableDataSource}
                          columns={rateTableColumnDescription}
                          scroll={{
                            x: "calc(300px + 50%)",
                            y: 400,
                          }}
                          pagination={false}
                          id={`${setupDataTableForm}`}
                          className={`${dataTable} mt-20`}
                        />
                      )}
                    </Form.List>
                  </Col>
                </Row>
                <div className="text-center mt-10">
                  <Button
                    onClick={handleAddRateTableRow}
                    shape="round"
                    className="add-btn mt-10 mb-10"
                    size="medium"
                    style={{
                      width: 90,
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
      </>
    );
  };

  // function to handle the table data and design
  const columnDetails = () => {
    return (
      <>
        <div className="org-view-div">
          <Row>
            <Col span={12} offset={6}>
              <Form.List name="ratetable_columns">
                {() => (
                  <Table
                    dataSource={columnDefDataSource}
                    columns={columnDefinitionColumns}
                    scroll={{
                      x: "calc(300px + 50%)",
                      y: 400,
                    }}
                    pagination={false}
                    id={`${setupDataTableForm}`}
                    className={`${dataTable} mt-20`}
                  />
                )}
              </Form.List>
            </Col>
          </Row>
          {state.flag !== "view" && (
            <div className="text-center mt-20">
              <Button
                onClick={handleAddColumnDefinition}
                shape="round"
                className="add-btn mb-10"
                size="medium"
                style={{
                  width: 90,
                }}
              >
                <PlusOutlined
                  style={{
                    color: "#9b9b9b",
                  }}
                />
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  // function to handle the rate card table data and design
  const rateCardDetails = () => {
    return (
      <>
        <div className="org-view-div">
          <Row>
            <Col span={18} offset={3}>
              <Form.List name="rate_table">
                {() => (
                  <Table
                    dataSource={rateTableDataSource}
                    columns={rateTableColumnDescription}
                    scroll={{
                      x: "calc(300px + 50%)",
                      y: 400,
                    }}
                    pagination={false}
                    id={`${setupDataTableForm}`}
                    className={`${dataTable} mt-20`}
                  />
                )}
              </Form.List>
            </Col>
          </Row>
          {state.flag !== "view" && (
            <div className="text-center mt-10" style={{ width: "100%" }}>
              <Button
                onClick={handleAddRateTableRow}
                shape="round"
                className="add-btn"
                size="medium"
                style={{
                  width: 90,
                }}
              >
                <PlusOutlined />
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  useEffect(() => {
    document.title = "Rate Card Setup | Judo";
  }, []);

  const { TabPane } = Tabs;

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (rcFile && rcFile.length > 0) {
      for (let i = 0; i < rcFile.length; i++) {
        uploadForm.append("file", rcFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "rc"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            ref={mainFormRef}
            form={mainForm}
            name="create-ratecard"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ["supplier_name"]: state.data.supplier_name,
              ["msa"]: state.data.msa_name,
              ["ratetable_name"]: state.data.ratetable_name,
              ["start_date"]:
                state.data && state.data.start_date
                  ? dayjs(state.data.start_date)
                  : undefined,
              ["end_date"]:
                state.data && state.data.end_date
                  ? dayjs(state.data.end_date)
                  : undefined,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={state.flag === "view" ? 24 : 20}
                md={state.flag === "view" ? 24 : 21}
                lg={state.flag === "view" ? 24 : 21}
                xl={state.flag === "view" ? 24 : 18}
              >
                <HeaderCard
                  mainMenu="Contract Setup"
                  subMenu="Rate Card Setup"
                  itemName={
                    state.flag === "create"
                      ? "New Rate Card"
                      : state.flag === "clone"
                      ? "Clone Rate Card"
                      : state.flag === "edit"
                      ? "Edit Rate Card"
                      : state.flag === "view"
                      ? "View Rate Card"
                      : ""
                  }
                  itemStatus={state.data.status}
                  goBackLink={
                    formLevel === "ColumnDefine"
                      ? { func: setFormLevel, params: "" }
                      : "/system-setup/?back=org-rcs"
                  }
                  state={state.data}
                  showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              {state.flag !== "view" && (
                <>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <CancelButton
                      onClick={() => history(`/system-setup/?back=con-rcs`)}
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SaveButton
                      type="primary"
                      htmlType="submit"
                      onClick={(event) => {
                        setButtonState(1);
                        formSubmitHandler();
                        event.preventDefault();
                      }}
                      loading={saveTableLoader}
                      disabled={
                        state.flag === "create"
                          ? rateCardField.rateCardName
                            ? false
                            : true
                          : state.flag === "edit"
                          ? state.data && state.data.status !== "In Draft"
                            ? true
                            : rateCardField.rateCardName
                            ? false
                            : true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    {state.flag === "create" && formLevel === "" ? (
                      rateTableFile.length > 0 ? (
                        <SubmitButton
                          onClick={(event) => {
                            setButtonState(2);
                            formSubmitHandler();
                            event.preventDefault();
                          }}
                          type="primary"
                          htmlType="submit"
                          name="submit-btn"
                          loading={loader}
                          disabled={
                            rateCardField.rateCardName &&
                            rateCardField.supplier_id &&
                            rcDates.startDate
                              ? false
                              : true
                          }
                        />
                      ) : (
                        <NextButton
                          type="primary"
                          htmlType="submit"
                          name="next-btn"
                          loading={loader}
                          onClick={(event) => {
                            setButtonState(0);
                            formSubmitHandler();
                            event.preventDefault();
                          }}
                          disabled={
                            changed &&
                            rateCardField.rateCardName &&
                            rateCardField.supplier_id &&
                            rcDates.startDate
                              ? false
                              : true
                          }
                        />
                      )
                    ) : state.flag === "clone" ? (
                      <SubmitButton
                        onClick={(event) => {
                          setButtonState(2);
                          formSubmitHandler();
                          event.preventDefault();
                        }}
                        type="primary"
                        htmlType="submit"
                        name="submit-btn"
                        loading={loader}
                        disabled={
                          rateCardField.rateCardName &&
                          rateCardField.supplier_id &&
                          rcDates.startDate &&
                          rateTableDataSource.length > 0
                            ? false
                            : true
                        }
                      />
                    ) : (
                      <SubmitButton
                        onClick={(event) => {
                          setButtonState(2);
                          formSubmitHandler();
                          event.preventDefault();
                        }}
                        type="primary"
                        htmlType="submit"
                        name="submit-btn"
                        loading={loader}
                        disabled={
                          // rateCardField.rateCardName &&
                          // rateCardField.supplier_id &&
                          // rcDates.startDate
                          //   ? false
                          //   : true

                          state.data && state.data.status === "In Draft"
                            ? rateCardField.rateCardName &&
                              rateCardField.supplier_id &&
                              rcDates.startDate &&
                              rateTableDataSource.length > 0
                              ? false
                              : true
                            : disable
                        }
                      />
                    )}
                  </Col>
                </>
              )}
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs
                    activeKey={tabKey}
                    onChange={(key) => {
                      setTabKey(key);
                    }}
                    tabBarExtraContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: -6,
                        }}
                      >
                        {state.flag === "view" && tabKey === "6" && (
                          <SaveButton
                            loading={notesSaveLoader}
                            onClick={() => {
                              saveNotesCommentsData();
                            }}
                            style={{ width: 140 }}
                          />
                        )}
                      </div>
                    }
                  >
                    <TabPane forceRender tab="Rate Card Cover" key="1">
                      {ratecardCover()}
                    </TabPane>
                    {state.flag !== "create" && (
                      <>
                        <TabPane
                          forceRender
                          tab={
                            <Divider className="tab-divider" type="vertical" />
                          }
                          key="divider1"
                          disabled
                        />
                        <TabPane tab="Column Details" key="2">
                          {columnDetails()}
                        </TabPane>
                        <TabPane
                          forceRender
                          tab={
                            <Divider className="tab-divider" type="vertical" />
                          }
                          key="divider2"
                          disabled
                        />
                        <TabPane forceRender tab="Rate Card Details" key="3">
                          {rateCardDetails()}
                        </TabPane>
                        <TabPane
                          forceRender
                          tab={
                            <Divider className="tab-divider" type="vertical" />
                          }
                          key="divider3"
                          disabled
                        />
                        <TabPane forceRender tab="Associations" key="4">
                          {Associations()}
                        </TabPane>
                      </>
                    )}
                    {state.flag === "view" && (
                      <>
                        <TabPane
                          forceRender
                          tab={
                            <Divider className="tab-divider" type="vertical" />
                          }
                          key="divider4"
                          disabled
                        />
                        <TabPane forceRender tab="Entity Log" key="5">
                          {EntityLog()}
                        </TabPane>
                      </>
                    )}
                    <TabPane
                      forceRender
                      tab={<Divider className="tab-divider" type="vertical" />}
                      key="divider5"
                      disabled
                    />
                    <TabPane forceRender tab="Notes And Comments" key="6">
                      <NotesAndComments
                        detailData={state.data}
                        viewedFile={viewedFile}
                        comments={comments}
                        url={url}
                        setUrl={setUrl}
                        setFile={setRcFile}
                        setFileKeysToDelete={setFileKeysToDelete}
                        setViewedFile={setViewedFile}
                        setComments={setComments}
                        flag="setup"
                        setDisable={setDisable}
                      />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-rcs`} />
      )}
    </Layout>
  );
};

export default RateCardController;
