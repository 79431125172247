import { axiosInstance } from "../utils/common";

export const createClauseLibrary = async (bodyData) => {
  return axiosInstance
    .post(`/v1/clauseLibrary`, bodyData)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const clauseLibraryOnlyApi = async () => {
  console.log(
    "axiosclause",
    axiosInstance.get(`/v1/clauseLibrary`).then((res) => res.data)
  );
  return axiosInstance
    .get(`/v1/clauseLibrary`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const clauseLibraryByIdApi = async (clId) => {
  return axiosInstance
    .get(`/v1/clauseLibrary/${clId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateClauseLibrary = async (clId, updateBody) => {
  const res = await axiosInstance.patch(
    `/v1/clauseLibrary/${clId}`,
    updateBody
  );
  return res.data;
};

export const deleteclauseLibrary = async (clId) => {
  const res = await axiosInstance.delete(`/v1/clauseLibrary/${clId}`);
  return res.data;
};

export const clauseLibraryPaginationApi = (
  searchVal,
  page,
  limit,
  allFilterVal,
  sortColumn
) => {
  return axiosInstance
    .post(
      `v1/clauseLibrary/pagination/${searchVal}?limit=${limit}&page=${page}`,
      { allFilterVal: allFilterVal, sortColumn }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllClauseLibraryFiltersApi = () => {
  return axiosInstance
    .get(`v1/clauseLibrary/filters/clause-library/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

//Template API's
export const saveTemplateApi = async (bodyData) => {
  return axiosInstance
    .post(`/v1/contractTemplates`, bodyData)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const contracttemplateByIdApi = async (contractTemplateId) => {
  return axiosInstance
    .get(`/v1/contractTemplates/${contractTemplateId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateTemplateApi = async (templateId, updateBody) => {
  const res = await axiosInstance.patch(
    `/v1/contractTemplates/${templateId}`,
    updateBody
  );
};
export const getallContractTemplate = async () => {
  return axiosInstance
    .get(`/v1/contractTemplates`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const templatePaginationApi = (
  searchVal,
  page,
  limit,
  allFilterVal,
  sortColumn
) => {
  return axiosInstance
    .post(
      `v1/contractTemplates/pagination/${searchVal}?limit=${limit}&page=${page}`,
      { allFilterVal: allFilterVal, sortColumn }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllTemplate = async () => {
  console.log(
    "axiosclause",
    axiosInstance.get(`/v1/contractTemplates/only`).then((res) => res.data)
  );
  return axiosInstance
    .get(`/v1/contractTemplates/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteTemplate = async (templateId) => {
  const res = await axiosInstance.delete(`/v1/contractTemplates/${templateId}`);
  return res.data;
};

export const getAllTemplateFiltersApi = () => {
  return axiosInstance
    .get(`v1/contractTemplates/filters/template/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
