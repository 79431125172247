import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Button,
  Input,
  Upload,
  message,
  Modal,
  Form,
  Layout,
} from "antd"; //antd components
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../Header";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import { useRecoilState } from "recoil";
import {
  HeaderChangerAtom,
  userDataAtom,
  WorkflowNameAtom,
  RoleAccessAtom,
} from "../../../store/store";
import "../../../assets/style/setup.css";
import { createCustomWorkflowAPi } from "../../../api/workflowApi";
import { createActivityLogApi } from "../../../api/logApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import NotAuthorized from "../../NotAuthorized";

const CustomWorkFlow = () => {
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  //global state varaiable hold the worl flow name using useRecoilState
  const getWorkFlowName = useRecoilState(WorkflowNameAtom);
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  //global state variable hold the header
  let header = headerChanger.header;
  // state variable hold the define value 1 means draft and value 2 means submit
  const state = {
    button: 1,
  };
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  // state variable hold the work flow name from input field
  const [WorkFlowName, setWorkFlowName] = useState("");
  // state variable hold the table data
  const [tableData, setTableData] = useState([]);
  // state variable hold the  upload validation required true or false
  const [uploadRule, setUploadRule] = useState(false);
  // state variable hold the upload validation error text
  const [uploadRuleText, setUploadRuleText] = useState();
  // state variable hold the loader on api integration
  const [loader, setLoader] = useState(false);
  // state variable hold the work flow File
  const [workflowFile, setWorkFlowFile] = useState();
  // state variable hold the dag File
  const [dagFile, setDagFile] = useState();
  // state variable hold the upload string for check the condition
  const [createMethod, setCreateMethod] = useState("upload");
  // state variable hold the loading while create the data and send to API
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  // state variable hold the chech value
  const [checked, setChecked] = useState();
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);
  /* Function to handle work flow name from input field
   * @paran e - selcted value in input field
   */
  const onChangeWorkFlowName = (e) => {
    setWorkFlowName(e.target.value);
  };

  /*
   * Function to check the excel format and indicate file success or unsuccess
   *
   */
  const jsonProps = {
    name: "file",
    listType: "picture",
    maxCount: 1,
    defaultFileList: workflowFile ? [workflowFile] : [],
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          setWorkFlowFile(info.file);
        } else {
          setWorkFlowFile();
        }
      }
      if (info.file) {
        if (info.file.status === "done") {
          setWorkFlowFile(info.file);
        } else {
          setWorkFlowFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = ["json"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload json file");
        Modal.error({
          className: "popup-model-success",
          centered: true,
          icon: false,
          content: <div>You can only upload json file</div>,
        });
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  /*
   * Function to check the format and indicate dag file success or unsuccess
   *
   */
  const dagProps = {
    name: "file",
    listType: "picture",
    maxCount: 1,
    defaultFileList: dagFile ? [dagFile] : [],
    onRemove() {
      setDagFile(null);
      setTableData([]);
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          setDagFile(info.file);
        } else {
          setDagFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setDagFile(info.file);
        } else {
          setDagFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = ["py"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload python files");
        Modal.error({
          className: "popup-model-success",
          centered: true,
          icon: false,
          content: <div>You can only upload python files</div>,
        });
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };

  /* Function to uplaod the file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  /* Function to uplaod the dag file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */
  const dummyDagRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  /* Function to handle component label box for input field
   */
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  /* Function to handle submit form
   * @paran value - collection of component data in value
   */
  const onFinish = async (values) => {
    if (getWorkFlowName && getWorkFlowName[0].includes(values.workflow_name)) {
    } else {
      let formData = new FormData();
      formData.append("workflow_name", values.workflow_name);
      formData.append("status", state.button === 2 ? "Active" : "In Draft");
      formData.append("created_by", userData[0].id);
      formData.append("updated_by", userData[0].id);
      formData.append("created_on", new Date());
      formData.append("updated_on", new Date());

      // Append files
      if (workflowFile) {
        formData.append("workflow_file", workflowFile.originFileObj);
      }

      if (dagFile) {
        formData.append("dag_file", dagFile.originFileObj);
      }
      if (state.button === 1) {
        await createCustomWorkflowAPi(formData).then((res) => {
          console.log("res: ", res);
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values["workflow_name"],
            code: "workflow",
            author: userData[0].id,
            action: "view",
            notes: "Created Custom Workflow",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog).then((res) => {
            console.log("LogRes: ", res);
          });
          setSaveTableLoader(false);

          popupModalInstance.successModal(
            values.workflow_name,
            ModalMessage.Workflow_Save,
            `/system-setup/?back=con-wf`
          );
        });
      }
      if (state.button === 2) {
        popupModalInstance
          .infoModal(ModalMessage.Workflow_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              await createCustomWorkflowAPi(formData).then((res) => {
                console.log("res: ", res);
                let ssLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: values["workflow_name"],
                  code: "workflow",
                  author: userData[0].id,
                  action: "view",
                  notes: "Created Custom Workflow",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                setSaveTableLoader(false);
                history(`/system-setup/?back=con-wf`);
              });
            }
          });
      }
    }
  };

  /* Function to handle submit form failed
   *@ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="create-pricingprofile"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={16}
                md={18}
                lg={18}
                xl={20}
              >
                <Card className="setup-header-card">
                  <Row type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              createMethod === "upload"
                                ? history(`/system-setup/?back=con-wf`)
                                : setCreateMethod("upload");
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={22}
                    >
                      <span>Contract Setup</span> &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span
                        className="cursor-pointer"
                        onClick={() => history(`/system-setup/?back=con-wf`)}
                      >
                        WorkFlow
                      </span>
                      &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span>Create Custom WorkFlow</span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <Button
                  onClick={() => (state.button = 1)}
                  type="primary"
                  htmlType="submit"
                  name="submit-btn"
                  loading={saveTableLoader}
                  className={`${
                    WorkFlowName ? "primaryBtn save-btn " : "disable-btn"
                  }`}
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <Button
                  type="primary"
                  onClick={() => (state.button = 2)}
                  htmlType="submit"
                  name="submit-btn"
                  loading={loader}
                  className={`primaryBtn save-btn ${
                    !workflowFile ? "disable-btn" : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            <div className="price-profile-content m-10">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <Col span={24}>
                      <div className="create-supplier-div">
                        <div className="m-20">
                          <Row align="middle" type="flex">
                            <Col span={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name="workflow_name"
                                label="NAME OF THE WORKFLOW"
                                colon={false}
                              >
                                <Input
                                  placeholder="Enter your WorkFlow Name"
                                  onChange={onChangeWorkFlowName}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          {createMethod === "upload" && (
                            <>
                              <div className="create-entity-div">
                                <Row
                                  align="middle"
                                  type="flex"
                                  style={{ padding: "10px 30px 0px" }}
                                >
                                  <Col span={16} className="vertical-align">
                                    <h4 className="entity-text">
                                      Work Flow Details
                                    </h4>
                                  </Col>
                                  <Col
                                    span={8}
                                    className="vertical-align"
                                    align="right"
                                  ></Col>
                                </Row>
                                <hr className="mt-10 view-supplier-hr" />
                                <Row
                                  align="middle"
                                  type="flex"
                                  style={{ padding: "10px 17px 0px" }}
                                  gutter={[8, 16]}
                                >
                                  <Col span={12} className="vertical-align">
                                    <Form.Item
                                      rules={[
                                        {
                                          required: uploadRule,
                                          message: uploadRuleText,
                                        },
                                      ]}
                                    >
                                      <span className="upload-note">
                                        Upload your WorkFlow MetaData Details
                                      </span>
                                      <Upload
                                        {...jsonProps}
                                        customRequest={dummyRequest}
                                      >
                                        <Button
                                          className="primaryBtn save-btn"
                                          style={{
                                            width: "100%",
                                            padding: "0px 35px",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                  <Col span={12} className="vertical-align">
                                    <Form.Item
                                      rules={[
                                        {
                                          required: uploadRule,
                                          message: uploadRuleText,
                                        },
                                      ]}
                                    >
                                      <span className="upload-note">
                                        Upload your WorkFlow Dag File
                                      </span>
                                      <Upload
                                        {...dagProps}
                                        customRequest={dummyDagRequest}
                                      >
                                        <Button
                                          className="primaryBtn save-btn"
                                          style={{
                                            width: "100%",
                                            padding: "0px 35px",
                                          }}
                                        >
                                          Upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-wf`} />
      )}
    </Layout>
  );
};

export default CustomWorkFlow;
